import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";
const loginUser = async (email, password) => {
    try {
        const response = await fetch(HttpUrls.login, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'POST',
            body: JSON.stringify({
                'email': email,
                'password': password,
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            cookiesGlobal.set('auth', responseData['access_token'], { path: '/' });
            cookiesGlobal.set('email', email, { path: '/' });
            return {
                statusCode: response.status,
                response: responseData['access_token'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }

    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const createUser = async (email, phone, password, rePassword) => {
    try {
        const response = await fetch(HttpUrls.createAccount, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'POST',
            body: JSON.stringify(
                {
                    'email': email,
                    'phone_number': phone,
                    'password': password,
                    'password_confirmation': rePassword,
                }
            )
        });
        const responseData = await response.json();
        if (response.status === 200) {
            cookiesGlobal.set('auth', responseData['access_token'], { path: '/' });
            cookiesGlobal.set('email', email, { path: '/' });
            return {
                statusCode: response.status,
                response: responseData['access_token'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const sendCodeToEmail = async (email) => {
    try {
        const response = await fetch(HttpUrls.sendEmailConformation, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'POST',
            body: JSON.stringify({ 'email': email, })
        });
        const responseData = await response.json();
        if (response.status <= 300) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const resendVerificationCode = async (email) => {
    try {
        const response = await fetch(HttpUrls.reResendforgetPassword, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'POST',
            body: JSON.stringify({ 'email': email, })
        });
        const responseData = await response.json();
        if (response.status <= 300) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const confrimCode = async (code, email) => {
    try {
        const response = await fetch(HttpUrls.confrimCode, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'POST',
            body: JSON.stringify({ 'code': code, 'email': email })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const newPasswordChange = async (password, email) => {
    try {
        const response = await fetch(HttpUrls.newPassword, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'POST',
            body: JSON.stringify({ 'email': email, 'password': password, })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
export { createUser, loginUser, sendCodeToEmail, confrimCode, newPasswordChange, resendVerificationCode };
