import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import './linear-widget.css';
import { projectType } from '../../../enum/enum';

export default function LinearBoxWiget({ linearData, SetEditLinear, DeleteLinear, type, SetCreateLinear }) {
    let mergedList = linearData.word.flatMap((obj, index) => [obj, linearData.relation[index]]);
    let StringType;
    if (type === projectType.paragrapthType) {
        StringType = 'Sentence'
    } else {
        StringType = 'Words'
    }
    return (
        <div className='linear-box-element'>
            <div className="linear-blox-header">
                <h1>{StringType}</h1>
                <DeleteIcon onClick={() => { DeleteLinear(linearData.id) }} className="delete" />
            </div>
            <div onClick={(e) => { if (e.target) { SetEditLinear(linearData); SetCreateLinear(true) } }} className="linear-content">
                {mergedList.map((e, index) => {
                    const isList = Array.isArray(e);
                    if (isList) {
                        let i = 0;
                        return e.map((e, index) => {
                            i = i + 1;
                            return <>Principle {i} </>
                        });
                    } else {
                        return <>{typeof e === 'object' ? ` ${e.value} ` : <span className={`flow-words ${e === 'Not Related to' ? 'not-active' : 'active'}`}>{` ${e ?? ''} `}</span>}</>
                    }
                })}
            </div>
        </div>
    )
}
