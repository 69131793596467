import React from "react"
import { getSentenceString } from "../../global/utils"
import EntityWidgetForAllType from "../entity_widget/entity_widget"

export default function CreationContent({ type, content, sentence, principle, }) {
    const sentenceText = getSentenceString(sentence);
    if (type === 'Word') {
        if (content) {
            return <>{content.value}</>
        } else {
            return <></>
        }
    } else if (type === 'Entity') {
        return <EntityWidgetForAllType entity={content} />
    } else if (type === 'Sentence') {
        return <p dangerouslySetInnerHTML={{ __html: sentenceText }}></p>
    } else if (type === 'Principle') {
        let i = 0;
        let containsValue = principle.principle.some(obj => obj.type === 'derived' && obj.value === true);
        if (containsValue) {
            return <div>{getSentenceString(principle.explanation)} </div>
        } else {
            return principle.principle.map((e, index) => {
                if (e.value === true) {
                    i = i + 1;
                    return <div key={index}>Principle {i}</div>
                } else {
                    return <div key={index}></div>
                }
            })
        }
    } else if (type === 'Principle-relation') {
        let containsValue = principle.principle.some(obj => obj.type === 'derived' && obj.value === true);
        if (containsValue) {
            return <div>{getSentenceString(principle.explanation)} </div>
        } else {
            return <>{content}</>
        }
    }
    else {
        return <></>
    }
}