import React from 'react'
import { projectType } from '../../../enum/enum'
import './sentence_title.css'
export default function SentenceTitle({ type }) {
    return (
        <div className='sentence_title'>
            <h1 className='sentence-title-text'>{type === projectType.sentenceType ? 'Sentence' : 'Paragraph'}</h1>
        </div>
    )
}
