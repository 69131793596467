import { createSlice } from '@reduxjs/toolkit'
import { showAlert } from '../../functions/show_alert';
import { requestState } from '../../global/http_enum'
export const projectSlice = createSlice({
    name: 'projects',

    initialState: {
        requestState: requestState.initial,
        gridview: true,
        project: [],
        projectSelected: null,
    },
    reducers: {
        changeGridView: (state, actions) => {
            if (state.gridview) {
                state.gridview = false;
            } else {
                state.gridview = true;
            }
        },
        setProjectData: (state, actions) => {
            const { statusCode, response } = actions.payload;
            if (statusCode === 200) {
                state.project = response;
                state.requestState = requestState.haveData;
            } else if (statusCode === 401) {
                state.requestState = requestState.invalidTokne;
            }
            else if (statusCode > 500) {
                showAlert(false, response);
                state.requestState = requestState.serverError;
            } else {
                showAlert(false, response);
                state.requestState = requestState.internetConnection;
            }
        },
        deleteProjectByID: (state, actions) => {

            if (state.projectSelected != null) {
                if (state.projectSelected.id === actions.payload) {
                    state.projectSelected = null
                }
            }
            state.project = state.project.filter(obj => obj.id !== actions.payload)
        },
        editProjectByID: (state, actions) => {
            const { id, projectName, sentence, word } = actions.payload;
            const updatedList = state.project.map(obj =>
                obj.id === id ? { ...obj, name: projectName, sentence: sentence, word: word } : obj
            );

            state.project = updatedList;
            if (state.projectSelected !== null && id === state.projectSelected.id) {
                state.projectSelected = { ...state.projectSelected, name: projectName, sentence: sentence, word: word }
            }
        }
        ,
        selectProject: (state, actions) => {
            state.projectSelected = actions.payload;
        },
        AddProjectEntity: (state, actions) => {
            const { projectID, entity } = actions.payload;
            var projectTarget = state.project.findIndex((elem) => elem.id === projectID);
            if (projectTarget === -1) {
                return;
            }
            let entityNewList = [];
            state.projectSelected.entity.map((e) => entityNewList.push(e));
            entityNewList.push(entity);
            state.project[projectTarget].entity = entityNewList;
            state.projectSelected.entity = entityNewList;
        },
        AddProject: (state, actions) => {
            const { id, sentence, entity, word, name, updated_at, project_type } = actions.payload;
            state.project = [...state.project, { id, sentence, entity, word, name, updated_at, project_type }];
        },
        requestStatusChanged: (state, action) => {
            state.requestState = requestState.initial;
            state.projectSelected = null
        },
        deleteEntityFromLogic: (state, actions) => {
            const { projectID, id } = actions.payload;
            var projectTarget = state.project.findIndex((elem) => elem.id === projectID);
            if (projectTarget === -1) {
                return;
            }
            const entity = state.projectSelected.entity.filter(elm => elm.id !== id)

            state.projectSelected.entity = entity;
            state.project[projectTarget].entity = entity;
        }
    }
});

export const { changeGridView, setProjectData, deleteProjectByID, editProjectByID, selectProject, AddProjectEntity, AddProject, requestStatusChanged, deleteEntityFromLogic } = projectSlice.actions

export default projectSlice.reducer