import React from 'react'
import SwitchButton from '../../../widgets/switch_button/switch_button'
import './title.css'
export default function TitleProject({ gridView, CreateProject }) {
  return (
    <div className="main-project-title-holder">
      <div id='desktop' className='main-project-title'>
        <h1 className='project-welcome'>Welcome to The Speak Logic<br></br><span className='color'> Sentence Flow</span>
        </h1>
        <div className="grid-view-button">
          <h1 className='grid-view-text'>List of Projects</h1>
          <h1 className='grid-view-text-small'>Grid View</h1>
          <SwitchButton value={gridView} />
        </div>
      </div>
      <div id='mobile' className='main-project-title color-b'>
        <div className="projects-titles">
          <h1 className='project-welcome'>Project</h1>
          <h1 onClick={() => CreateProject(true)} className='project-create-now'>Create new</h1>
        </div>
        <div className="grid-view-button">
          <h1 className='grid-view-text'>Grid View</h1>
          <SwitchButton value={gridView} />
        </div>
      </div>
    </div>

  )
}
