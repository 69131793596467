import React, { useEffect } from 'react';
import './set_relationship.css';
import RelationshipHeader from '../relationship_header/relationship_header';
import { useState } from 'react';
import { relationshipType, SentenceRelationShips } from '../../../enum/relationship';
import CreateRelationElement from '../create_relation_element/create_relation_element';
import RelationButtons from '../buttons/button_relation';
import RelationsView from '../relations_view/relations_view';
import { projectType } from '../../../enum/enum';
import { useLocation } from 'react-router-dom';
export default function SetRelationship({ relationShip, SetRelationShip, principle, typeProject }) {
    const [type, SetType] = useState(typeProject === projectType.sentenceType ? relationshipType.wordWord : SentenceRelationShips.SentenceSentence);
    const [createRelationship, SetCreateRelationship] = useState(false);
    const [showExplanation, SetShowExplanation] = useState(-1);
    const location = useLocation();
    useEffect(() => {
        const data = location.state;
        if(data){
            SetType(data.type)
        }
    }, [])
    return (
        <>
            <RelationshipHeader SetShowExplanation={SetShowExplanation} title={'Relationship'} CreateButton={SetCreateRelationship} />
            <RelationButtons SetCreateRelationship={SetCreateRelationship} relationShip={relationShip} typeProject={typeProject} principle={principle} createRelationship={createRelationship} type={type} SetType={SetType} />
            {createRelationship ?
                <CreateRelationElement typeProject={typeProject} SetRelationShip={SetRelationShip} relationShip={relationShip} SetCreateRelationship={SetCreateRelationship} principle={principle} type={type} />
                : <RelationsView SetCreateRelationship={SetCreateRelationship} typeProject={typeProject} showExplanation={showExplanation} SetShowExplanation={SetShowExplanation} principle={principle} relations={relationShip} type={type} />
            }
        </>
    )
}



