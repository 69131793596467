import React from 'react';
import './verification_element.css';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import VerificationBox from '../verification_box/verification_box';
import { getVerificationCountOfType, verificationTypeEnum } from '../../../enum/verification';
import { projectType } from '../../../enum/enum';
export default function VerificationElements({ verification, verificationType, SetPickedVerification, SetCreateVerifications, typeProject, SetVerificationType }) {
    return (
        <div id='verification-element'>
            <div className="desktop">
                <DesktopVerificationView typeProject={typeProject} verification={verification} type={verificationType} />
            </div>
            <div className="mobile">
                <MobileVerificationView verification={verification} SetVerificationType={SetVerificationType} typeProject={typeProject} SetPickedVerification={SetPickedVerification} SetCreateVerifications={SetCreateVerifications} />
            </div>
        </div>
    )
}


const DesktopVerificationView = ({ verification, type, typeProject }) => {
    return verification.map((e, index) => {
        if (type === e.type || e.type === `Not${type}`) {
            return <VerificationBox verification={e} typeProject={typeProject} key={index} width={'633px'} height={'376px'} />
        } else {
            return <></>
        }
    })
}


const MobileVerificationView = ({ SetCreateVerifications, SetPickedVerification, typeProject, SetVerificationType, verification }) => {
    if (typeProject === projectType.sentenceType) {
        return <>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.pointto); SetVerificationType(verificationTypeEnum.pointto) }} className='verification-element-mobile'>
                <div className="first-flex">1 &nbsp; Word point to entity ({getVerificationCountOfType(verificationTypeEnum.pointto,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.defined); SetVerificationType(verificationTypeEnum.defined) }} className='verification-element-mobile'>
                <div className="first-flex">2 &nbsp; Word defined by entity ({getVerificationCountOfType(verificationTypeEnum.defined,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.identify); SetVerificationType(verificationTypeEnum.identify) }} className='verification-element-mobile'>
                <div className="first-flex">3 &nbsp; Word identify entity ({getVerificationCountOfType(verificationTypeEnum.identify,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.visuallyidentified); SetVerificationType(verificationTypeEnum.visuallyidentified) }} className='verification-element-mobile'>
                <div className="first-flex">4 &nbsp; Word visually identified entity ({getVerificationCountOfType(verificationTypeEnum.visuallyidentified,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.naturallyidentified); SetVerificationType(verificationTypeEnum.naturallyidentified) }} className='verification-element-mobile'>
                <div className="first-flex">5 &nbsp; Word naturally identified entity ({getVerificationCountOfType(verificationTypeEnum.naturallyidentified,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
        </>
    } else {
        return <>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.sentencepointto); SetVerificationType(verificationTypeEnum.sentencepointto) }} className='verification-element-mobile'>
                <div className="first-flex">1 &nbsp; Sentence point to entities ({getVerificationCountOfType(verificationTypeEnum.sentencepointto,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.parapointto); SetVerificationType(verificationTypeEnum.parapointto) }} className='verification-element-mobile'>
                <div className="first-flex">2 &nbsp; Paragraph point to entities ({getVerificationCountOfType(verificationTypeEnum.parapointto,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.sentenceVI); SetVerificationType(verificationTypeEnum.sentenceVI) }} className='verification-element-mobile'>
                <div className="first-flex">3 &nbsp; Sentence visually identified  entities ({getVerificationCountOfType(verificationTypeEnum.sentenceVI,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.paraVI); SetVerificationType(verificationTypeEnum.paraVI) }} className='verification-element-mobile'>
                <div className="first-flex">4 &nbsp; Paragraph visually identified entities ({getVerificationCountOfType(verificationTypeEnum.paraVI,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.sentenceNI); SetVerificationType(verificationTypeEnum.sentenceNI) }} className='verification-element-mobile'>
                <div className="first-flex">5 &nbsp; Sentence naturally identified entities ({getVerificationCountOfType(verificationTypeEnum.sentenceNI,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.paraNI); SetVerificationType(verificationTypeEnum.paraNI) }} className='verification-element-mobile'>
                <div className="first-flex">6 &nbsp; Paragraph naturally identified entities ({getVerificationCountOfType(verificationTypeEnum.paraNI,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
            <div onClick={() => { SetCreateVerifications(2); SetPickedVerification(verificationTypeEnum.wordpointToentity); SetVerificationType(verificationTypeEnum.wordpointToentity) }} className='verification-element-mobile'>
                <div className="first-flex">7 &nbsp; Word Point to entities ({getVerificationCountOfType(verificationTypeEnum.wordpointToentity,verification)})</div>
                <ArrowForwardIosSharpIcon />
            </div>
        </>
    }

}


