import React from 'react'
import { urlForFiles } from '../../global/http_url'
import './entity_widget.css'
function isYouTubeLink(link) {
    var link1 = link
    link1.replace('https://api.slpsolver.net/', '')
    const youtubeShortRegex = /^(https?:\/\/)?youtu\.be\/([A-Za-z0-9_-]+)/;
    return youtubeShortRegex.test(link1);
}
function linkYoutube(link) {
    var link1 = link;
    const newLink = link1.replace('https://youtu.be/', 'https://www.youtube.com/embed/')
    return newLink;
}
function isImageLink(link) {
    var image = link.toLowerCase();
    return image.endsWith('.png') ||
        image.endsWith('.jpg') ||
        image.endsWith('.jpeg') ||
        image.endsWith('.gif');
}
export default function EntityWidgetForAllType({ entity }) {
    if(entity == null){
        return <></>;
    }
    return (
        <div id='entity-widget'>
            {entity.type === 'link' ? isYouTubeLink(entity.value) ? <iframe width="420" height="345" src={linkYoutube(entity.value)}></iframe> : isImageLink(entity.value) ? <img src={entity.value} alt={' '} /> : <div className="link-not-found">Link unreachable</div> : <></>}
            {entity.type === 'video' ? <video muted src={urlForFiles(entity.value)} autoPlay={true} loop={true} className='entity-video'></video> : <></>}
            {entity.type === 'image' ? <img alt=' ' className='entity-video' src={urlForFiles(entity.value)} /> : <></>}
            {entity.type === 'audio' ? <audio controls autoplay className='entity-audio' >
                <source type="audio/mpeg" src={urlForFiles(entity.value)} />
            </audio> : <></>}
        </div>
    )
}
export { linkYoutube, isYouTubeLink };