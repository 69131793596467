import React from 'react'
import './project_element.css'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { convertDay } from '../../../functions/date_converter';
import { useNavigate } from 'react-router-dom';
import { projectType } from '../../../enum/enum';
import { getSentenceString, sentenceCount } from '../../../global/utils';

export default function ProjectElement({ words, name, sentence, updated_at, deleteProject, id, selected, type, entity, rename, SetProjectName }) {
    const naviagte = useNavigate();
    var sentenceText = getSentenceString(sentence);
    return (
        <div className={`project-element-holder ${selected ? 'active-project' : ''}`}>
            <div className='project-name-menu'>
                <div className='project-name'>{name}</div>
                <div className='project-menu'><MoreVertIcon />
                    <div className="buttons-holder">
                        <h1 onClick={() => { rename(id); SetProjectName(name) }} className='buttons-text'>Rename</h1>
                        <h1 onClick={() => naviagte('/sentence')} className='buttons-text'>Open</h1>
                        <h1 onClick={() => deleteProject(id)} className='buttons-text'>Delete</h1>
                    </div>
                </div>
            </div>
            <div className='sentence-box'>{sentenceText}</div>
            <div className="count">{type === projectType.sentenceType ? ' Words Count' : ' Sentences Count'} : <span>{sentenceText === '' ? 0 : type === projectType.sentenceType ? sentenceText.split(' ').length : sentenceCount(sentenceText)}</span> </div>
            <div className="identifyed">{type === projectType.sentenceType ? 'Words' : 'Sentences'} Identified: <span>{words.length}</span></div>
            <div className="entity">Entities Identified: <span>{entity.length}</span></div>
            <div className='time-created'>
                {convertDay(updated_at)}
            </div>
        </div>
    )
}
