import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { setPrincipleAPI } from '../../../api/relationship';
import { showAlert } from '../../../functions/show_alert';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import './set_principle.css'
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
export default function SetPrinciple({ selectedProject, showIdent, relationShip }) {
    const nagigate = useNavigate();
    useEffect(() => {
        if (relationShip.principle.length !== 0) {
            SetSelectedPrinciple(relationShip.principle.principle)
        }
    }, [relationShip]);
    const [selectedPrinciple, SetSelectedPrinciple] = useState([
        {
            index: 1,
            type: 'identify',
            name: 'The Given Set of Communication',
            value: false,
        },


        {
            index: 2,
            type: 'identify',
            name: 'The Given Set of Information',
            value: false,
        },
        {
            index: 3,
            type: 'identify',
            name: 'The Given Set of Instrumentation',
            value: false,
        },
        {
            index: 4,
            type: 'identify',
            name: 'The Given Set of Education',
            value: false,
        },
        {
            index: 5,
            type: 'identify',
            name: 'The Given Set of Power',
            value: false,
        },
        {
            index: 6,
            type: 'identify',
            name: 'The Given Set of Marketing',
            value: false,
        },
        {
            index: 7,
            type: 'identify',
            name: 'The Given Set of Exchange',
            value: false,
        },
        {
            index: 8,
            type: 'identify',
            name: 'The Given Set of Gaming',
            value: false,
        },
        {
            index: 9,
            type: 'identify',
            name: 'The Given Set of Work',
            value: false,
        },
        {
            index: 10,
            type: 'identify',
            name: 'The Given Set of Reproduction',
            value: false,
        },

        {
            index: 1,
            type: 'derived',
            name: 'The Given Set of Communication',
            value: false,
        },


        {
            index: 2,
            type: 'derived',
            name: 'The Given Set of Information',
            value: false,
        },
        {
            index: 3,
            type: 'derived',
            name: 'The Given Set of Instrumentation',
            value: false,
        },
        {
            index: 6,
            type: 'derived',
            name: 'The Given Set of Marketing',
            value: false,
        },
        {
            index: 7,
            type: 'derived',
            name: 'The Given Set of Exchange',
            value: false,
        },
        {
            index: 8,
            type: 'derived',
            name: 'The Given Set of Gaming',
            value: false,
        },
        {
            index: 9,
            type: 'derived',
            name: 'The Given Set of Work',
            value: false,
        },
    ]);
    const [explanations, SetExplanations] = useState('');
    const [identifyStep, SetStep] = useState(true);
    const hundleSelectPrincipleClicked = (index, type) => {
        if (relationShip.principle.length !== 0) {
            return;
        }
        const data = selectedPrinciple;
        const currentIndex = data.findIndex(elem => elem.index === index && elem.type === type);
        if (currentIndex === -1) {
            return;
        }
        data[currentIndex].value = data[currentIndex].value === true ? false : true;
        SetSelectedPrinciple(data);
    }
    const hundleNextStepPressed = async () => {
        const currentIndex = selectedPrinciple.findIndex(elem => elem.value === true && elem.type === 'derived');
        if (identifyStep === true) {
            if (currentIndex !== -1) {
                SetStep(false);
                return;
            }
        }

        if (explanations === '<p><br></p>') {
            showAlert(false, 'write an explanation');
            return;
        }
        showProgressAlert();
        let response = await setPrincipleAPI(selectedProject.id, selectedPrinciple, explanations);
        HideProgressAlert();
        if (response.statusCode === 200) {
            showAlert(true, 'principal saved successfully');
            nagigate('/relationship')

        } else {
            showAlert(false, response.response);
        }
    }
    return (
        <div id='set-principle'>
            {identifyStep ?
                <IdentDrivedPrinciple isSelected={relationShip.principle.length !== 0} hundleSelectPrincipleClicked={hundleSelectPrincipleClicked} showIdent={showIdent} selectedPrinciple={selectedPrinciple} /> :
                <PickedDirivedPrinciple explanations={explanations} SetExplanations={SetExplanations} selectedPrinciple={selectedPrinciple} />
            }
            {relationShip.principle.length === 0 ? <NextButton identifyStep={identifyStep} SetStep={hundleNextStepPressed} /> : <>
                <br></br>
                <br></br>
            </>}
        </div>
    )
}

const IdentDrivedPrinciple = ({ showIdent, selectedPrinciple, hundleSelectPrincipleClicked, isSelected }) => {
    return <div>
        <div className="principle-element-top">
            <div className="title">Principle Count</div>
            <div className="body">Actual Principle </div>
            <div className="content">Applicable</div>
        </div>
        {selectedPrinciple.map((e, index) => {
            if (showIdent) {
                if (e.type === 'derived') {
                    return <></>;
                }
                return <PrincipleElement isSelected={isSelected} key={index} type={e.type} hundleSelectPrincipleClicked={hundleSelectPrincipleClicked} index={e.index} name={e.name} value={e.value} />
            } else {
                if (e.type === 'identify') {
                    return <></>;
                }
                return <PrincipleElement isSelected={isSelected} key={index} type={e.type} hundleSelectPrincipleClicked={hundleSelectPrincipleClicked} index={e.index} name={e.name} value={e.value} />
            }
        })}
    </div>

};
const PickedDirivedPrinciple = ({ selectedPrinciple, explanations, SetExplanations }) => {
    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];
    var count = 0;
    return (
        <div className="drived-prinicple-holder">
            <div className="drived-principle-items">
                {selectedPrinciple.map((e, index) => {
                    if (e.value === true && e.type === 'derived') {
                        count = count + 1;
                        return <div className="items-drived">
                            <div className="index">{count}</div>
                            <div className="value">{e.name} <span>Principle</span></div>
                            <CheckIcon className='icon' />
                        </div>
                    }
                    return <></>
                })}
            </div>
            <div className="explane">
                <ReactQuill formats={formats} onChange={(e) => {
                    SetExplanations(e);
                }} value={explanations} className='text-area' theme="snow" />
            </div>
        </div>
    )
}
const PrincipleElement = ({ index, name, value, hundleSelectPrincipleClicked, type, isSelected }) => {
    return <div className="principle-content">
        <div className="title"><h1>{index}</h1></div>
        <div className="body">{name} <span>Principle</span></div>
        {isSelected ? <input onChange={() => hundleSelectPrincipleClicked(index, type)} checked={value} defaultValue={value} value={value} defaultChecked={value} className='content' type="checkbox" />
            : <input onChange={() => hundleSelectPrincipleClicked(index, type)} value={value} defaultChecked={value} className='content' type="checkbox" />
        }
    </div>
}
const NextButton = ({ SetStep }) => {
    return <div onClick={() => SetStep()} className='button'>Save Principle</div>
}
export { PrincipleElement, IdentDrivedPrinciple }

