import React, { useEffect, useState } from 'react';
import './create_flow.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { showAlert } from '../../../functions/show_alert';
import { CreateFlowAPI, EditeFlowAPI } from '../../../api/flow';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { cookiesGlobal } from '../../../App';
import EntityWidgetForAllType from '../../../widgets/entity_widget/entity_widget';
import AlertWithEntity from '../../../widgets/alert_with_entity/alert_with_entity';
import { settingsSlider } from '../../../global/utils';
import Slider from 'react-slick';
import { projectType } from '../../../enum/enum';
export default function CreateFlowScreen({ SetFlow, SetCreateFlow, editing, flowID, entity, string, flows }) {


  const [entityList, SetEntityList] = useState([]);
  const [stringList, SetStringList] = useState([]);
  useEffect(() => {
    if (editing) {
      SetStringList(string)
      SetEntityList(entity)
    }
  }, [])

  const projectSelected = useSelector(state => state.projectSlice.projectSelected);
  const [showPicker, SetShowPicker] = useState('none');
  const navigate = useNavigate();
  const CreateOrEditeFlow = async () => {
    const entityEmty = entityList.findIndex(elem => elem.length === 0);
    const StringEmty = stringList.findIndex(elem => elem === '');
    if (StringEmty !== -1 || entityEmty !== -1) {
      showAlert(false, 'All Entity and Flow need to be full first');
      return;
    }
    showProgressAlert()
    const response = await CreateFlowAPI(projectSelected.id, entityList, stringList);
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'Flow Created');
      SetFlow(state => [...state, {
        id: response.response.id,
        entity: entityList,
        string: stringList,
      }]);
      SetCreateFlow(false);
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }
  }
  const editeFlow = async () => {
    const entityEmty = entityList.findIndex(elem => elem.length === 0);
    const StringEmty = stringList.findIndex(elem => elem === '');
    if (StringEmty !== -1 || entityEmty !== -1) {
      showAlert(false, 'All Entity and Flow need to be full first');
      return;
    }
    showProgressAlert()
    const response = await EditeFlowAPI(projectSelected.id, flowID, entityList, stringList);
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'Flow Updated');
      const newFlowList = [...flows];
      const index = newFlowList.findIndex((obj) => obj.id === flowID);
      if (index !== -1) {
        newFlowList.splice(index, 1);
      }
      newFlowList.push({
        id: response.response.id,
        entity: entityList,
        string: stringList,
      });
      SetFlow(newFlowList);
      SetCreateFlow(false);
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }
  }
  const HundleChangeEntityOfFlow = (entity) => {
    if (showPicker === 'none') {
      return;
    }
    if (projectSelected.project_type === projectType.sentenceType) {
      var newList = entityList;
      newList[showPicker] = [entity];
      SetEntityList(newList);
      SetShowPicker('none');
    } else {
      SetEntityList(state => {
        var newList = [...state];
        newList[showPicker] = [...newList[showPicker], entity];
        return newList;
      })
    }


  }
  const HundleChangeOfString = (index, value) => {
    const newList = [...stringList];
    newList[index] = value;
    SetStringList(newList);
  }
  const HundleAddMoreBoxes = () => {
    if (entityList.length === 0) {
      SetEntityList(state => [...state, []])
      return;
    } else if (entityList[entityList.length - 1].length === 0) {
      showAlert(false, 'box should be filled with Entity First');
      return;
    } else if (stringList[(stringList.length - 1)] === '') {
      showAlert(false, 'Type Flow First');
      return;
    } else {
      SetEntityList(state => [...state, []])
      SetStringList(state => [...state, ''])
    }
  }
  return (
    <>
      <div onClick={() => HundleAddMoreBoxes()} className="add-more-flow">add more Entity</div>
      <div id='create-flow'>
        {entityList.map((e, index) => {
          if (index === entityList.length - 1) {
            return <div key={index} className="box">
              <div className="box-header">Entity {index + 1}</div>
              <div onClick={(e) => { if (e.target.tagName.toLowerCase() !== 'button') { SetShowPicker(index); } }} className="entity">
                <Slider  {...settingsSlider}>
                  {e.map((e, index) => <EntityWidgetForAllType key={index} entity={e} />)}
                </Slider>
              </div>
            </div>
          }
          var showArrow = entityList[index + 1].length === 0 ? false : true;
          return <>
            <div className="box">
              <div className="box-header">Entity {index + 1}</div>
              <div onClick={(e) => { if (e.target.tagName.toLowerCase() !== 'button') { SetShowPicker(index); } }} className="entity">
                <Slider  {...settingsSlider}>
                  {e.map((e, index) => <EntityWidgetForAllType key={index} entity={e} />)}
                </Slider>
              </div>
            </div>
            <div className={`arrow ${showArrow ? '' : 'hide-for-now'}`}>
              <ArrowForwardIosIcon className='icon' />
              <input onChange={(even) => HundleChangeOfString(index, even.target.value)} value={stringList[index]} placeholder='type flow here' className='input-flow' />
            </div>
          </>

        })}
      </div>
      <div onClick={() => { editing ? editeFlow() : CreateOrEditeFlow() }} className="save-button">Save Flow</div>
      {showPicker === 'none' ? <></> : <AlertWithEntity type={'Entity'} EntitysSelected={[]} closeAlert={SetShowPicker} setData={HundleChangeEntityOfFlow} typeProject={projectSelected.type} />}
    </>

  )
}




