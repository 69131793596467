import React, { useEffect, useState } from 'react'
import { AnswerTheQuestion, getFirstTypeOfVerification, typeIsNotRelated, verificationToMapMoile, verificationTypeEnum, VerificationtypeToText } from '../../../enum/verification';
import './verification_create.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { showAlert } from '../../../functions/show_alert';
import { CreateVerificationAPI } from '../../../api/verifications';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { cookiesGlobal } from '../../../App';
import AlertWithEntity from '../../../widgets/alert_with_entity/alert_with_entity';
import { projectType } from '../../../enum/enum';
import CloseIcon from '@mui/icons-material/Close';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EntityWidgetForAllType from '../../../widgets/entity_widget/entity_widget';
import { getSentenceString, settingsSlider } from '../../../global/utils';
export default function VerificationCreate({ verificationType, SetVerifications, SetCreateVerifications, SetVerificationType }) {
  const [type, SetType] = useState(verificationType);
  const selectedProject = useSelector(state => state.projectSlice.projectSelected)
  const [showSelection, SetSelection] = useState('none');
  const [word, SetWord] = useState(null);
  const [entity, SetEntity] = useState([]);
  const [showQuestion, SetShowQuestion] = useState(false);
  const [answred, SetAnswered] = useState(false);
  const navigate = useNavigate();
  const hundleCreateFlow = async () => {
    if (entity === null || entity.length === 0) {
      showAlert(false, 'pick an Entity to Your verifications');
      return;
    }
    if (word === null) {
      showAlert(false, 'pick an Word to Your verifications');
      return;
    }
    var listOfEntityID = [];
    entity.map((e) => listOfEntityID.push(e.id));

    showProgressAlert();
    const response = await CreateVerificationAPI(selectedProject.id, type, type.includes('para') ? selectedProject.sentence : word, listOfEntityID,);
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'Verification Created');
      SetVerifications(state => [...state, {
        id: response.response.id,
        type: response.response.type,
        word: response.response.word,
        entity: entity,
      }]);
      SetCreateVerifications(0);
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
    } else {
      showAlert(false, response.response);
    }
  }
  const hundlePickData = (e) => {
    if (showSelection === 'Word') {
      SetWord(e);
      SetSelection('none');
    }
    if (entity.length === 0) {

    }
    if (showSelection === 'Entity') {
      if (selectedProject.project_type === projectType.sentenceType) {
        SetEntity([e]);
        SetSelection('none');
      } else {
        SetEntity(state => {
          var exist = false;
          for (var i = 0; i < state.length; i++) {
            if (state[i].value === e.value) {
              exist = true;
            }
          }
          if (exist) {
            const newList = state.filter((item) => item.value !== e.value);
            return newList;
          } else {
            return [...state, e];
          }
        });
      }
      SetSelection('none');
      if (entity.length === 0 && answred === false) {
        SetShowQuestion(true);
      }
    }
  }

  useEffect(() => {
    if (verificationType.includes('para')) {
      var sentenceText = getSentenceString(selectedProject.sentence);
      SetWord(sentenceText);
    } else {
      SetWord(null);
    }

  }, [verificationType])
  return (
    <div id='create-verification'>
      <select onChange={(e) => SetVerificationType(e.target.value)}>
        {selectedProject.project_type === projectType.sentenceType ?
          <>
            <option value={verificationTypeEnum.pointto}>{VerificationtypeToText(verificationTypeEnum.pointto, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.naturallyidentified}>{VerificationtypeToText(verificationTypeEnum.naturallyidentified, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.visuallyidentified}>{VerificationtypeToText(verificationTypeEnum.visuallyidentified, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.identify}>{VerificationtypeToText(verificationTypeEnum.identify, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.defined}>{VerificationtypeToText(verificationTypeEnum.defined, selectedProject.project_type)}</option>
          </> :
          <>
            <option value={verificationTypeEnum.sentencepointto}>{VerificationtypeToText(verificationTypeEnum.sentencepointto, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.parapointto}>{VerificationtypeToText(verificationTypeEnum.parapointto, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.sentenceVI}>{VerificationtypeToText(verificationTypeEnum.sentenceVI, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.paraVI}>{VerificationtypeToText(verificationTypeEnum.paraVI, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.sentenceNI}>{VerificationtypeToText(verificationTypeEnum.sentenceNI, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.paraNI}>{VerificationtypeToText(verificationTypeEnum.paraNI, selectedProject.project_type)}</option>
            <option value={verificationTypeEnum.wordpointToentity}>{VerificationtypeToText(verificationTypeEnum.wordpointToentity, selectedProject.project_type)}</option>
          </>}

      </select>
      <div className="create-verification-holder">
        <div className="top-verification-create">
          <div className="word-time">
            <div className="word">{selectedProject.project_type === projectType.sentenceType ? 'Word' : getFirstTypeOfVerification(verificationType)}</div>
            <div className="time"></div>
          </div>
          <div className="entity">{selectedProject.project_type === projectType.sentenceType ? 'Entity' : 'Entities'} </div>
        </div>
        <div className="create-verification-content">
          <div onClick={() => SetSelection('Word')} className="word">
            {word !== null ? verificationType.includes('para') ? word : word.value : '....'}
          </div>
          {entity.length === 0 || word === null ? <div className="type"></div> : <div className="type">
            <h1>{verificationToMapMoile(type, selectedProject.project_type)}</h1>
            <div className='line-arrow'>
              <div className="line"></div>
              <ArrowForwardIosIcon className="arrow" />
              {typeIsNotRelated(type) ? <CloseIcon className='not-related' /> : <></>}
            </div>
          </div>
          }
          {entity.length !== 1 ? <>
            <div onClick={(e) => { if (e.target.tagName.toLowerCase() !== 'button') { SetSelection('Entity') } }} className={"entity"}>
              <Slider  {...settingsSlider}>
                {entity.map((e, index) => <EntityWidgetForAllType entity={e} />)}
              </Slider>
            </div>
          </> : <>
            <div onClick={() => SetSelection('Entity')} className={"one-entity"}>
              <EntityWidgetForAllType entity={entity[0]} />
            </div>
          </>}
        </div>
      </div>
      {showSelection !== 'none' ? <AlertWithEntity EntitysSelected={entity} closeAlert={SetSelection} setData={hundlePickData} type={showSelection} typeProject={selectedProject.project_type} /> : <></>}
      <div className="buttons-action-verification">
        <div onClick={() => SetCreateVerifications(0)} className="save-button exit">back</div>
        <div onClick={() => hundleCreateFlow()} className="save-button">Save</div>
      </div>
      {showQuestion ?
        <div className="quetion-verification">
          <div className="questions">
            {type === verificationTypeEnum.pointto ? <>Does the word point to the entity?</> : <></>}
            {type === verificationTypeEnum.visuallyidentified ? <>Does the word visually identify the entity?</> : <></>}
            {type === verificationTypeEnum.naturallyidentified ? <>Does the word naturally identify the entity?</> : <></>}
            {type === verificationTypeEnum.identify ? <>Does the word identify the entity?</> : <></>}
            {type === verificationTypeEnum.defined ? <>Is the word defined by the entity?</> : <></>}


            {type === verificationTypeEnum.sentencepointto ? <>Does the sentence point to the entities?</> : <></>}
            {type === verificationTypeEnum.parapointto ? <>Does the paragraph point to the entities?</> : <></>}
            {type === verificationTypeEnum.sentenceVI ? <>Does the sentence visually identify the entities?</> : <></>}
            {type === verificationTypeEnum.paraVI ? <>Does the paragraph visually identify the entities?</> : <></>}
            {type === verificationTypeEnum.sentenceNI ? <>Does the sentence naturally identify the entities?</> : <></>}
            {type === verificationTypeEnum.paraNI ? <>Does the paragraph naturally identify the entities?</> : <></>}
            {type === verificationTypeEnum.wordpointToentity ? <>Does the Word Point to the entities?</> : <></>}
            <div className="buttonhulder">
              <div onClick={() => { SetType(AnswerTheQuestion(type, true)); SetShowQuestion(false); SetAnswered(true) }} className="save">Yes</div>
              <div onClick={() => { SetType(AnswerTheQuestion(type, false)); SetShowQuestion(false); SetAnswered(true) }} className="save">No</div>
            </div>
          </div>
        </div>
        : <></>
      }
    </div >
  )
}
