import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";

const GetProjectData = async (token) => {
    try {
        let response = await fetch(HttpUrls.getProjects, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }

}
const createProjectToDB = async (projectName, sentence, updated_at, projectType) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(HttpUrls.createProject, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'name': projectName,
                'sentence': sentence,
                'updated_at': updated_at,
                'project_type': projectType,
                'entity': [],
                'word': []
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }

    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }

}
const deleteProjectAPI = async (id) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.deleteOrEditeProject}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
        });
        const responseData = await response.json();
        return {
            statusCode: response.status,
            response: responseData,
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }

}
const editeProjectAPI = async (projectName, sentence,  updated_at, word, id) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.deleteOrEditeProject}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'name': projectName,
                'sentence': sentence,
                'updated_at': updated_at,
                'word': word
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }

    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }

}

export { GetProjectData, createProjectToDB, deleteProjectAPI, editeProjectAPI };