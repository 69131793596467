import React, { useEffect, useState } from 'react'
import './create-linear.css';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { cookiesGlobal } from '../../../App';
import { showAlert } from '../../../functions/show_alert';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { CreateLinearRelationShip, EditLinearRelationShip } from '../../../api/ligner-relationship';
import AlertWithEntity from '../../../widgets/alert_with_entity/alert_with_entity';
import { projectType } from '../../../enum/enum';
import CreationContent from '../../../widgets/content/content_hundler';
import CloseIcon from '@mui/icons-material/Close';
export default function CreateLinear({ SetCreateLinear, SetLinearData, type, projectId, pickedLinear, SetEditLinear, linearData, principle }) {
  const [wordList, SetWordList] = useState([]);
  const [relationList, SetRelationList] = useState([]);
  const [showPicker, SetShowPicker] = useState('none');
  const navigate = useNavigate();
  useEffect(() => {
    if (pickedLinear != null) {
      SetRelationList(pickedLinear.relation);
      SetWordList(pickedLinear.word);
    }
  }, []);
  let StringType;
  if (type === projectType.paragrapthType) {
    StringType = 'Sentence'
  } else {
    StringType = 'Words'
  }

  const CreateOrEditeFlow = async () => {
    const entityEmty = wordList.findIndex(elem => elem.length === 0);
    const StringEmty = relationList.findIndex(elem => elem === '');
    if (StringEmty !== -1 || entityEmty !== -1) {
      showAlert(false, 'All ' + StringType + ' and linear Relation need to be full first');
      return;
    }
    if (relationList.length === 0) {
      showAlert(false, 'at least 2 ' + StringType + ' must be created');
      return;
    }
    if (wordList.length === 0) {
      showAlert(false, 'at least 2 ' + StringType + ' must be created');
      return;
    }

    showProgressAlert()
    const response = await CreateLinearRelationShip(projectId, wordList, relationList);
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'linear Relation Created');
      SetLinearData(state => [...state, {
        id: response.response.id,
        word: wordList,
        relation: relationList,
      }]);
      SetCreateLinear(false);
      SetEditLinear(null)
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }
  }
  const editeFlow = async () => {
    const entityEmty = wordList.findIndex(elem => elem.length === 0);
    const StringEmty = relationList.findIndex(elem => elem === '');
    if (StringEmty !== -1 || entityEmty !== -1) {
      showAlert(false, 'All Entity and Flow need to be full first');
      return;
    }
    if (relationList.length === 0) {
      showAlert(false, 'at least 2 ' + StringType + ' must be created');
      return;
    }
    if (wordList.length === 0) {
      showAlert(false, 'at least 2 ' + StringType + ' must be created');
      return;
    }
    showProgressAlert()
    const response = await EditLinearRelationShip(projectId, pickedLinear.id, wordList, relationList);
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'Linear Relation Updated');
      const newFlowList = [...linearData];
      const index = newFlowList.findIndex((obj) => obj.id === pickedLinear.id);
      if (index !== -1) {
        newFlowList.splice(index, 1);
      }
      newFlowList.push({
        id: response.response.id,
        word: wordList,
        relation: relationList,
      });
      SetEditLinear(null);
      SetLinearData(newFlowList);
      SetCreateLinear(false);
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }
  }
  const HundleChangeEntityOfFlow = (entity) => {
    if (showPicker === 'none') {
      return;
    }
    var newList = wordList;
    newList[showPicker] = entity;
    SetWordList(newList);
    SetShowPicker('none');

  }
  const HundleChangeOfString = (index, value) => {
    const newList = [...relationList];
    newList[index] = value;
    SetRelationList(newList);
  }
  const HundleAddMoreBoxes = () => {
    if (wordList.length === 0) {
      SetWordList(state => [...state, {}])
      return;
    } else if (wordList[wordList.length - 1].length === 0) {
      showAlert(false, 'box should be filled with Entity First');
      return;
    } else if (relationList[(relationList.length - 1)] === '') {
      showAlert(false, 'Add Relations');
      return;
    } else {
      SetWordList(state => [...state, {}])
      SetRelationList(state => [...state, ''])
    }
  }
  const HundleAddPrinciples = () => {
    const filteredObjects = principle.principle.filter(obj => obj.value === true);
    if (wordList.length === 0) {
      if (type === projectType.sentenceType) {
        showAlert(false, 'In a linear relationship, it is good to identify the word first to show that the word is related to the principle.')
      } else {
        showAlert(false, 'In a linear relationship, it is good to identify the sentence first to show that the sentence is related to the principle.')

      }
      return;
    } else if (wordList[wordList.length - 1].length === 0) {
      showAlert(false, 'box should be filled with ' + StringType + ' First');
      return;
    } else if (relationList[(relationList.length - 1)] === '') {
      showAlert(false, 'Add Relations');
      return;
    } else {
      SetWordList(state => [...state, [...filteredObjects]])
      SetRelationList(state => [...state, ''])
    }
  }
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  return (
    <>
      <div onClick={() => HundleAddMoreBoxes()} className="add-more-flow">Add more {StringType}</div>
      {principle.length === 0 ? <></> : <div style={{ marginTop: '-10px' }} onClick={() => HundleAddPrinciples()} className="add-more-flow">Add Principle </div>}
      <div id='create-linear'>
        {wordList.map((e, index) => {
          const isList = Array.isArray(e);
          if (index === wordList.length - 1) {
            return <div key={index} className="box">
              <div className="box-header"> {isList ? 'Principle' : `${StringType} ${index + 1}`}    </div>
              <div onClick={(e) => { if (e.target.tagName.toLowerCase() !== 'button') { if (isList === false) { SetShowPicker(index); } } }} className="entity">
                {isList ? <Principles Principles={principle.principle} /> : <CreationContent type={'Word'} content={e} />}
              </div>
            </div>
          }
          const list = Array.isArray(wordList[index + 1]);
          var showArrow
          if (list) {
            showArrow = true
          } else {
            showArrow = isEmptyObject(wordList[index + 1]) ? false : true;
          }
          return <>
            <div className="box">
              <div className="box-header"> {isList ? 'Principle' : `${StringType} ${index + 1}`}    </div>
              <div onClick={(e) => { if (e.target.tagName.toLowerCase() !== 'button') {  if (isList === false) { SetShowPicker(index); } } }} className="entity">
                {isList ? <Principles Principles={principle.principle} /> : <CreationContent type={'Word'} content={e} />}
              </div>
            </div>
            {showArrow ?
              relationList[index] === '' ? <div className='buttons'>
                <div className='green' onClick={() => HundleChangeOfString(index, 'Related to')}>Related to</div>
                <div className='red' onClick={() => HundleChangeOfString(index, 'Not Related to')}>Not Related to</div>
              </div> : <div className={`arrow ${showArrow ? '' : 'hide-for-now'}`}>
                <ArrowForwardIosIcon className='icon' />
                <div className='input-flow'>{relationList[index]}</div>
                {relationList[index] === 'Not Related to' ? <CloseIcon className='close-button-arrow' /> : <></>}
              </div> : <div className='space'></div>
            }
          </>
        })}
      </div>
      <div onClick={() => { if (pickedLinear === null) { CreateOrEditeFlow() } else { editeFlow() } }} className="save-button">Save Flow</div>
      {showPicker === 'none' ? <></> : <AlertWithEntity type={'Word'} EntitysSelected={[]} closeAlert={SetShowPicker} setData={HundleChangeEntityOfFlow} typeProject={type} />}
    </>

  )
}

const Principles = ({ Principles }) => {
  let i = 0;
  return Principles.map((e, index) => {
    if (e.value === true) {
      i = i + 1;
      return <div key={index}>Principle {i}</div>
    } else {
      return <></>
    }

  });
}


