import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { projectType } from '../../enum/enum';
import EntityWidgetForAllType from '../entity_widget/entity_widget';
import './alert_with_entity.css'
export default function AlertWithEntity({ type, setData, closeAlert, typeProject, EntitysSelected, filter }) {
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    const navigate = useNavigate();
    return (
        <div id='alert-with-entity'>
            <div className="alert-picking-elements">
                <h1 className='select-title'>Select {type}</h1>
                <div className="box-element">
                    {type === 'Word' ? projectSelected.word.map((e, index) => {
                        return <h1 onClick={() => { setData(e); if (typeProject === projectType.sentenceType) { closeAlert('none') } }} key={index}>{e.value}</h1>
                    })
                        : <></>}
                    {type === 'Entity' ? projectSelected.entity.map((e, index) => {
                        const isInList = EntitysSelected.some(item => item.value === e.value);
                        if (filter) {
                            if (e.type !== filter && e.type !== 'link') {
                                return <></>
                            }
                        }
                        return <div className={`${isInList ? 'selected' : ''}`} onClick={() => { setData(e); if (typeProject === projectType.sentenceType) { closeAlert('none') } }} key={index}> <EntityWidgetForAllType entity={e} /> </div>
                    }) : <></>
                    }
                </div>
                <div className="buttons-holder">
                    <div onClick={() => closeAlert('none')} className='close-button'>Close</div>
                    <div onClick={() => {
                        if (type === 'Word') {
                            navigate('/');
                        } else {
                            navigate('/identify-entity');
                        }
                    }} className='identify-more'>Identify More {type}</div>
                </div>
            </div>
        </div>
    )
}
