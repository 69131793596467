import React, { useEffect } from 'react';
import ProjectElement from './project_element/project_element';
import TitleProject from './title/title';
import './project_screen.css';
import CreateProject from './create_project/create_project';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import ProjectElementList from './project_element_list/project_element_list';
import { useDispatch, useSelector } from 'react-redux';
import { AddProject, deleteProjectByID, editProjectByID, selectProject, setProjectData } from '../../redux/project/projects';
import { createProjectToDB, deleteProjectAPI, editeProjectAPI, GetProjectData } from '../../api/project';
import { requestState } from '../../global/http_enum';
import ResponseHttp from '../../widgets/response_http/response_http';
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import LoginInputWidget from '../../widgets/input/login_input';
import { showAlert } from '../../functions/show_alert';
import { cookiesGlobal } from '../../App';
export default function ProjectScreen() {
  const navigate = useNavigate();
  const state = useSelector(state => state.projectSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.requestState !== requestState.haveData) {
      const token = cookiesGlobal.get('auth');
      GetProjectData(token).then((e) => {
        dispatch(setProjectData(e));
      })
    } else {

    }
  }, [])
  const [projectName, SetProjectName] = useState('');
  const [projectType, SetProjectType] = useState('Sentence flow');
  const [showCreate, SetShowAlert] = useState();
  const [editProject, SetEditeProject] = useState();
  const [picked, SetPicked] = useState(-1);
  const deleteProject = async (id) => {
    showProgressAlert();
    const response = await deleteProjectAPI(id);
    HideProgressAlert();
    if (response.statusCode === 200) {
      dispatch(deleteProjectByID(id))
      SetPicked(-1);
      return;
    }
    if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }


  }
  const CreateProject = async () => {
    if (projectName === '') {
      showAlert(false, 'Enter Project Name');
      return;
    }
    if (projectType !== 'Sentence flow' && projectType !== 'Paragraph flow') {
      showAlert(false, 'unexpected error');
      return;
    }
    showProgressAlert();
    const response = await createProjectToDB(projectName, '', Date.now(), projectType);
    HideProgressAlert();
    if (response.statusCode === 200) {
      dispatch(AddProject(response.response))
      SetShowAlert(false)
      SetProjectName('')
      return;
    }
    if (response === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    }
    showAlert(false, response.response);
  }
  const RenameFunction = async () => {
    if (projectName === '') {
      showAlert(false, "Project Name Cannot be Empty");
      return;
    }
    showProgressAlert();
    SetEditeProject();
    const response = await editeProjectAPI(projectName, state.projectSelected.sentence, Date.now(), state.projectSelected.word, state.projectSelected.id);
    HideProgressAlert();
    if (response.statusCode === 200) {
      dispatch(editProjectByID({ projectName, word: response.response.word, sentence: state.projectSelected.sentence, id: response.response.id }))
      showAlert(true, 'Project Name Updated');
      SetProjectName('')
      return;
    }
    if (response === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    }
    showAlert(false, response.response);
  }
  return (
    <section>
      <AppBarWidget />
      <div id='project'>
        <TitleProject CreateProject={SetShowAlert} gridView={state.gridview} />
        <ProjectView SetProjectName={SetProjectName} SetEditeProject={SetEditeProject} SetShowAlert={SetShowAlert} dispatch={dispatch} httpResponse={state.requestState} deleteProject={SetPicked} gridView={state.gridview} projects={state.project} selectedProject={state.projectSelected} />
        {showCreate ?
          <div className="alert-create-project">
            <LoginInputWidget height={'50px'} maxLength={20} placeholder={'Project Name'} setValue={SetProjectName} type={'text'} value={projectName} width={'260px'} />
            <select onChange={(e) => SetProjectType(e.target.value)} value={projectType} name="type" className='project-type'>
              <option value="Sentence flow">Sentence flow</option>
              <option value="Paragraph flow">Paragraph flow</option>
            </select>
            <div className="buttons-holder-create-project">
              <div onClick={() => { SetShowAlert(false); SetProjectName('') }} className="cancel-create-project">cancel</div>
              <div onClick={() => CreateProject()} className="create-project">Create Project</div>
            </div>
          </div>
          : <></>}
        {editProject ?
          <div className="alert-create-project">
            <LoginInputWidget height={'50px'} maxLength={20} placeholder={'Project Name'} setValue={SetProjectName} type={'text'} value={projectName} width={'260px'} />
            <div className="buttons-holder-create-project">
              <div onClick={() => { SetEditeProject(); SetProjectName('') }} className="cancel-create-project">cancel</div>
              <div onClick={() => RenameFunction()} className="create-project">Edite Project</div>
            </div>
          </div>
          : <></>}
      </div>
      {picked === -1 ? <></> : <div onClick={() => SetPicked(-1)} id="delete-item">
        <div className="alert-mesg">
          <h1>Are you sure You want to delete This Project ?</h1>
          <div className="buttons">
            <div onClick={() => SetPicked(-1)} className="bt close">Close</div>
            <div onClick={() => deleteProject(picked)} className="bt done">Confirm</div>
          </div>
        </div>
      </div>}
    </section>

  )
}

const ProjectView = ({ httpResponse, gridView, projects, deleteProject, dispatch, selectedProject, SetShowAlert, SetEditeProject, SetProjectName }) => {
  const navigate = useNavigate();
  if (requestState.haveData === httpResponse) {
    var Selected = selectedProject === null ? '' : selectedProject.id;
    if (gridView) {
      return <div className="project-map">
        {projects.map((e, index) =>
          <div
            key={index} onDoubleClick={() => {
              dispatch(selectProject(e));
              navigate('/sentence');
            }} onClick={() => dispatch(selectProject(e))}><ProjectElement SetProjectName={SetProjectName} rename={SetEditeProject} entity={e.entity} type={e.project_type} selected={Selected === e.id ? true : false} deleteProject={deleteProject} id={projects[index].id} words={projects[index].word} key={index} name={projects[index].name} sentence={projects[index].sentence} updated_at={projects[index].updated_at} />
          </div>
        )}
        <CreateProject createProjectPressed={SetShowAlert} />
      </div>
    } else {
      return <div className='project-list'>
        {projects.map((e, index) =>
          <div key={index} onDoubleClick={() => {
            dispatch(selectProject(e));
            navigate('/sentence');
          }} onClick={() => dispatch(selectProject(e))}>
            <ProjectElementList SetProjectName={SetProjectName} rename={SetEditeProject} selected={Selected === e.id ? true : false} deleteProject={deleteProject} words={projects[index].word} id={projects[index].id} key={index} name={projects[index].name} sentence={projects[index].sentence} updated_at={projects[index].updated_at} />
          </div>
        )}
         <div onClick={()=> SetShowAlert(true)} id='list-create'>Create Project</div>
      </div>
    }
  } else {
    return <ResponseHttp response={httpResponse} />
  }

}

