import React from 'react'
import './idnetify_principle_header.css'
export default function IdentifyPrincipleHeader({ showIdent, SetShowIdent }) {
    return (
        <div id='idnetify-principle-header'>
            <h1 className='title'>Principles</h1>
            <div className="buttons">
                <div onClick={() => SetShowIdent(true)} className={`button ${showIdent ? 'active' : 'not-active'}`}>Identify Principle</div>
                <div onClick={() => SetShowIdent(false)} className={`button ${!showIdent ? 'active' : 'not-active'}`}>Derive Principle</div>
            </div>
        </div>
    )
}
