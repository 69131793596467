import React, { useState } from 'react'
import AppBarWidget from '../../widgets/app_bar/app_bar'
import './identify_entity.css';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import LoginInputWidget from '../../widgets/input/login_input';
import DriveFileRenameOutlineSharpIcon from '@mui/icons-material/DriveFileRenameOutlineSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import SaveIcon from '@mui/icons-material/Save';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateEntityAPI, DeleteEntity } from '../../api/identfy-entity';
import { showAlert } from '../../functions/show_alert';
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page';
import { AddProjectEntity, deleteEntityFromLogic } from '../../redux/project/projects';
import EntityWidgetForAllType from '../../widgets/entity_widget/entity_widget';
import DeleteIcon from '@mui/icons-material/Delete';
import { cookiesGlobal } from '../../App';
import { getSentenceString } from '../../global/utils';
export default function IdentifyEntity() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const EntityDeleted = async (id) => {
        showProgressAlert()
        let response = await DeleteEntity(id);
        HideProgressAlert();
        if (response.statusCode === 200) {
            dispatch(deleteEntityFromLogic({ projectID: projectSelected.id, id }));
            showAlert(true, 'Entity Deleted');
        } else if (response.statusCode === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        } else {
            showAlert(false, response.response);
        }

    }
    const [picked, SetPicked] = useState(-1);
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    const [createState, SetCreateState] = useState(false);
    if (projectSelected === null) {
        return <></>
    }

    var entity = projectSelected.entity;
    return (
        <>
            <AppBarWidget />
            <div id='identify-entity'>
                <div className="entity-title">Identified  Entities <AddCircleSharpIcon onClick={() => SetCreateState(!createState)} className='icon' /> </div>
                <CreateEntity projectSelected={projectSelected} id={projectSelected.id} SetCreateState={SetCreateState} createState={createState} />
                <div className="entity-group-holder">
                    {entity == null ? <></> : entity.map((e, index) => <EntityElement delte={SetPicked} projectSelected={projectSelected} key={index} entity={e} />)}
                </div>
            </div>
            {picked === -1 ? <></> : <div onClick={() => SetPicked(-1)} id="delete-item">
                <div className="alert-mesg">
                    <h1>Are you sure You want to delete This  Entity ?</h1>
                    <div className="buttons">
                        <div onClick={() => SetPicked(-1)} className="bt close">Close</div>
                        <div onClick={() => EntityDeleted(picked)} className="bt done">Confirm</div>
                    </div>
                </div>
            </div>}
        </>

    )
}


const EntityElement = ({ entity, projectSelected,delte }) => {
    return <div className="entity-box">
        <div className="entity-name"> <h1>{entity.name}</h1> <DeleteIcon className='icon' onClick={() => delte(entity.id)} /></div>
        <EntityWidgetForAllType entity={entity} width={'110px'} height={'128px'} />
    </div>
}


const CreateEntity = ({ createState, SetCreateState, id, projectSelected }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [fileType, SetFileType] = useState(["JPG", "PNG", "GIF", "JPEG"]);
    const [entityName, SetEntityName] = useState('');
    const [entityType, setEntityType] = useState('image');
    const [linkText, SetLinkText] = useState('');
    const handleChange = (event) => {
        const pickedFile = event;
        if (Array.isArray(fileType) && fileType[0] === 'JPG') {
            if (pickedFile.size > 1000000) {
                showAlert(false, 'the Image Size is More then 1 MB');
                return;
            }
        }
        if (fileType[0] === 'mp3') {
            if (pickedFile.size > 3000000) {
                showAlert(false, 'the audio Size is More then 3 MB');
                return;
            }
        }
        if (fileType[0] === 'mp4') {
            if (pickedFile.size > 5000000) {
                showAlert(false, 'the video Size is More then 5 MB');
                return;
            }
        }
        if (pickedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = btoa(reader.result);
                setFile(base64String);
            };
            reader.readAsBinaryString(pickedFile);
        }
    }
    const hundleCreatePressed = async () => {
        if (entityName === '') {
            showAlert(false, 'write a name for this entity');
            return;
        }
        if (entityType === '') {
            showAlert(false, 'type is not selected');
            return;
        } else {
            if (entityType !== 'link' && file === null) {
                showAlert(false, 'Pick a File');
                return;
            } if (linkText === '' && entityType === 'link') {
                showAlert(false, 'write a link for Your entity');
                return;
            }
        }
        if (!file && entityType === ["JPG", "PNG", "GIF", "JPEG"]) {
            showAlert(false, 'pick file to continue');
            return;
        }

        showProgressAlert();
        if (entityType === 'link') {
            let response = await CreateEntityAPI(id, entityType, linkText, entityName);
            HideProgressAlert();
            if (response.statusCode === 200) {
                dispatch(AddProjectEntity({ projectID: id, entity: response.response }));
                showAlert(true, 'Entity Created');
                SetCreateState(false);
                SetEntityName('');
                setEntityType('image')
                setFile(null);
                SetLinkText('');
                SetFileType(["JPG", "PNG", "GIF", "JPEG"]);
            } else if (response.statusCode === 401) {
                cookiesGlobal.remove('auth')
                cookiesGlobal.remove('email')
                navigate('/authentication', { replace: true });
                return;
            } else {
                showAlert(false, response.response);
            }
        } else {
            let response = await CreateEntityAPI(id, entityType, file, entityName);
            HideProgressAlert();
            if (response.statusCode === 200) {
                dispatch(AddProjectEntity({ projectID: id, entity: response.response }));
                showAlert(true, 'Entity Created');
                SetCreateState(false);
                SetEntityName('');
                setEntityType('image')
                setFile(null);
                SetLinkText('');
                SetFileType(["JPG", "PNG", "GIF", "JPEG"]);
            } else if (response.statusCode === 401) {
                cookiesGlobal.remove('auth')
                cookiesGlobal.remove('email')
                navigate('/authentication', { replace: true });
                return;
            } else {
                showAlert(false, response.response);
            }
        }

    }
    const hundleChangeType = (e) => {
        if (e.target.value === 'video') {
            SetFileType(["mp4", 'video']);
            setEntityType('video');
        }
        if (e.target.value === 'image') {
            SetFileType(["JPG", "PNG", "GIF", "JPEG"]);
            setEntityType('image');
        }
        if (e.target.value === 'link') {
            setEntityType('link');
        }
        if (e.target.value === 'audio') {
            SetFileType(['mp3', 'audio']);
            setEntityType('audio');
        }
    }
    if (createState) {
        return <div className='create-entity'>
            <input readOnly value={getSentenceString(projectSelected.sentence)} className='sentence-in-entity' type="text" />
            <div className='entity-name-type'>
                <LoginInputWidget width={'180px'} height={'50px'} widget={<DriveFileRenameOutlineSharpIcon />} maxLength={15} placeholder={'Entity Name'} value={entityName} setValue={SetEntityName} type={'text'} />
                <select value={entityType} onChange={(e) => hundleChangeType(e)} className={"entity-type"} name="entity-type">
                    <option value="image">image</option>
                    <option value="audio">audio</option>
                    <option value="video">video</option>
                    <option value="link">link</option>
                </select>
            </div>
            <div className='files-or-link'>
                {entityType === 'link' ? <>
                    <LoginInputWidget width={'100%'} height={'60px'} maxLength={400} placeholder={'Write link here'} setValue={SetLinkText} type={'text'} value={linkText} widget={<DriveFileRenameOutlineSharpIcon />} />
                    <div className="note">When adding a YouTube link to the input, please make sure to use the YouTube "Share" link. The "Share" link is the one that you get by clicking on the "Share" button below the video on YouTube, and it looks like this: "https://youtu.be/YOUR_VIDEO_ID".
                    </div>
                </>


                    : <FileUploader
                        multiple={false}
                        handleChange={(e) => handleChange(e)}
                        name="file"
                        types={fileType}

                    />}
            </div>
            <div className='flex'>
                <div className="buttons">
                    <div onClick={() => { SetCreateState(!createState); SetLinkText(''); SetEntityName(''); setFile(); }} className='close'><CloseSharpIcon /> <p>Close</p> </div>
                    <div onClick={() => hundleCreatePressed()} className='save'><SaveIcon /> Save</div>
                </div>
            </div>
        </div>
    } else {
        return <></>
    }
}
