import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";

const getVerificationsAPI = async (id) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.getVerification}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}

const CreateVerificationAPI = async (id, type, word, entity) => {
    const token = cookiesGlobal.get('auth');
    try {
        var data = {}
        if (type.includes('para')) {
            data = {
                'type': type,
                'sentence_id': word,
                'entity_id': entity
            }
        } else {
            data = {
                'type': type,
                'word_id': word.id,
                'entity_id': entity
            }
        }

        let response = await fetch(`${HttpUrls.createVerification}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
export { getVerificationsAPI, CreateVerificationAPI }