import React from 'react'
import './project_element_list.css'
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { convertDay } from '../../../functions/date_converter';
import { getSentenceString } from '../../../global/utils';
export default function ProjectElementList({ name, words, sentence, updated_at, id, deleteProject, selected, rename, SetProjectName }) {
    const sentenceText = getSentenceString(sentence);
    return (
        <div className={`main-project-list ${selected ? 'active-project' : ''}`}>
            <div className='name-time'>
                <h1 className='name-text'>{name}</h1>
                <h1 className='time-text'>{convertDay(updated_at)}</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: sentenceText }} className='sentence'>
            </div>
            <div className='delete-edite-icons'>
                <InsertDriveFileIcon onClick={() => { rename(id); SetProjectName(name) }} className='edit' />
                <DeleteIcon onClick={() => deleteProject(id)} className='delete' />
            </div>
        </div>
    )
}



