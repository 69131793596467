import { projectType } from "./enum";

const relationshipType = {
    wordWord: 'wordWord',
    wordEntity: 'wordEntity',
    entityEntity: 'entityEntity',
    principleWord: 'principleWord',
    principleEntiy: 'principleEntiy',
    sentencePrinciple: 'sentencePrinciple',
};

const relationshipTypeToTitle = (type) => {
    if (type === relationshipType.wordWord) {
        return 'Word and word relationship';
    }
    if (type === relationshipType.wordEntity) {
        return 'Word and entity relationship';
    }
    if (type === relationshipType.entityEntity) {
        return 'Entity and entity relationship';
    }
    if (type === relationshipType.principleWord) {
        return 'Word and Principle relationship';
    }
    if (type === relationshipType.principleEntiy) {
        return 'Entity and Principle relationship';
    }
    if (type === relationshipType.sentencePrinciple) {
        return 'Sentence and principle relationship';
    }
    /// Para module
    if (type === SentenceRelationShips.SentenceSentence) {
        return 'Sentence and Sentence relationship';
    }

    if (type === SentenceRelationShips.SentenceParagraph) {
        return 'Paragraph and Sentence relationship';
    }
    if (type === SentenceRelationShips.SentencePrinciple) {
        return 'Sentence and Principle relationship';
    }
    if (type === SentenceRelationShips.ParagraphPrincipl) {
        return 'Paragraph and Principle relationship';
    }
    if (type === SentenceRelationShips.EntitiesPrinciple) {
        return 'Entities and Principle relationship';
    }
    if (type === SentenceRelationShips.SentenceEntities) {
        return 'Sentence and Principle relationship';
    }
    if (type === SentenceRelationShips.ParagraphEntities) {
        return 'Paragraph and Entities relationship';
    }
    if (type === SentenceRelationShips.EntitiesEntities) {
        return 'Entities and Entities relationship';
    }
    if (type === SentenceRelationShips.wordEntites) {
        return 'Word and Entities relationship';
    }
    if (type === SentenceRelationShips.sentenceWord) {
        return 'Sentence and Word relationship';
    } else {
        return '';
    }
}
const getTitleForBox = (type, first, typeProject) => {
    if (typeProject === projectType.sentenceType) {
        if (first) {
            if (type === relationshipType.wordWord) {
                return 'Word';
            }
            if (type === relationshipType.wordEntity) {
                return 'Word';
            }
            if (type === relationshipType.entityEntity) {
                return 'Entity';
            }
            if (type === relationshipType.principleWord) {
                return 'Principle';
            }
            if (type === relationshipType.principleEntiy) {
                return 'Principle';
            }
            if (type === relationshipType.sentencePrinciple) {
                return 'Sentence';
            } else {
                return '';
            }
        } else {
            if (type === relationshipType.wordWord) {
                return 'Word';
            }
            if (type === relationshipType.wordEntity) {
                return 'Entity';
            }
            if (type === relationshipType.entityEntity) {
                return 'Entity';
            }
            if (type === relationshipType.principleWord) {
                return 'Word';
            }
            if (type === relationshipType.principleEntiy) {
                return 'Entity';
            }
            if (type === relationshipType.sentencePrinciple) {
                return 'Principle';
            } else {
                return '';
            }
        }
    } else {
        if (first) {
            if (type === SentenceRelationShips.SentenceSentence) {
                return 'Word';
            }
            if (type === SentenceRelationShips.SentenceParagraph) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.SentencePrinciple) {
                return 'Word';
            }
            if (type === SentenceRelationShips.ParagraphPrincipl) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.EntitiesPrinciple) {
                return 'Entity';
            }
            if (type === SentenceRelationShips.SentenceEntities) {
                return 'Word';
            }
            if (type === SentenceRelationShips.ParagraphEntities) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.EntitiesEntities) {
                return 'Entity';
            }
            if (type === SentenceRelationShips.sentenceWord) {
                return 'Word';
            }
            if (type === SentenceRelationShips.wordEntites) {
                return 'Word';
            } else {
                return '';
            }
        } else {
            if (type === SentenceRelationShips.SentenceSentence) {
                return 'Word';
            }
            if (type === SentenceRelationShips.SentenceParagraph) {
                return 'Word';
            }
            if (type === SentenceRelationShips.SentencePrinciple) {
                return 'Principle';
            }
            if (type === SentenceRelationShips.ParagraphPrincipl) {
                return 'Principle';
            }
            if (type === SentenceRelationShips.EntitiesPrinciple) {
                return 'Principle';
            }
            if (type === SentenceRelationShips.SentenceEntities) {
                return 'Entity';
            }
            if (type === SentenceRelationShips.ParagraphEntities) {
                return 'Entity';
            }
            if (type === SentenceRelationShips.EntitiesEntities) {
                return 'Entity';
            }
            if (type === SentenceRelationShips.sentenceWord) {
                return 'Word';
            }
            if (type === SentenceRelationShips.wordEntites) {
                return 'Entity';
            } else {
                return '';
            }
        }
    }
}
const getboxTileForBoxRelation = (type, first, typeProject) => {
    if (typeProject === projectType.sentenceType) {
        if (first) {
            if (type === relationshipType.wordWord) {
                return 'Word';
            }
            if (type === relationshipType.wordEntity) {
                return 'Word';
            }
            if (type === relationshipType.entityEntity) {
                return 'Entity';
            }
            if (type === relationshipType.principleWord) {
                return 'Principle';
            }
            if (type === relationshipType.principleEntiy) {
                return 'Principle';
            }
            if (type === relationshipType.sentencePrinciple) {
                return 'Sentence';
            } else {
                return '';
            }
        } else {
            if (type === relationshipType.wordWord) {
                return 'Word';
            }
            if (type === relationshipType.wordEntity) {
                return 'Entity';
            }
            if (type === relationshipType.entityEntity) {
                return 'Entity';
            }
            if (type === relationshipType.principleWord) {
                return 'Word';
            }
            if (type === relationshipType.principleEntiy) {
                return 'Entity';
            }
            if (type === relationshipType.sentencePrinciple) {
                return 'Principle';
            } else {
                return '';
            }
        }
    } else {
        if (first) {
            if (type === SentenceRelationShips.SentenceSentence) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.SentenceParagraph) {
                return 'Paragraph';
            }
            if (type === SentenceRelationShips.SentencePrinciple) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.ParagraphPrincipl) {
                return 'Paragraph';
            }
            if (type === SentenceRelationShips.EntitiesPrinciple) {
                return 'Entities';
            }
            if (type === SentenceRelationShips.SentenceEntities) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.ParagraphEntities) {
                return 'Paragraph';
            }
            if (type === SentenceRelationShips.EntitiesEntities) {
                return 'Entities';
            }
            if (type === SentenceRelationShips.sentenceWord) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.wordEntites) {
                return 'Word';
            } else {
                return '';
            }
        } else {
            if (type === SentenceRelationShips.SentenceSentence) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.SentenceParagraph) {
                return 'Sentence';
            }
            if (type === SentenceRelationShips.SentencePrinciple) {
                return 'Principle';
            }
            if (type === SentenceRelationShips.ParagraphPrincipl) {
                return 'Principle';
            }
            if (type === SentenceRelationShips.EntitiesPrinciple) {
                return 'Principle';
            }
            if (type === SentenceRelationShips.SentenceEntities) {
                return 'Entities';
            }
            if (type === SentenceRelationShips.ParagraphEntities) {
                return 'Entities';
            }
            if (type === SentenceRelationShips.EntitiesEntities) {
                return 'Entities';
            } if (type === SentenceRelationShips.sentenceWord) {
                return 'Word';
            }
            if (type === SentenceRelationShips.wordEntites) {
                return 'Entities';
            } else {
                return '';
            }
        }
    }
}
const SentenceRelationShips = {
    SentenceSentence: 'SentenceSentence',
    SentenceParagraph: 'SentenceParagraph',
    SentencePrinciple: 'SentencePrinciple',
    ParagraphPrincipl: 'ParagraphPrincipl',
    EntitiesPrinciple: 'EntitiesPrinciple',
    SentenceEntities: 'SentenceEntities',
    ParagraphEntities: 'ParagraphEntities',
    EntitiesEntities: 'EntitiesEntities',
    sentenceWord: 'SentenceWord',
    wordEntites: 'wordEntities',
};

const getRelaionsCountOfType = (type, relations) => {
    const count = relations.filter(obj => obj.type === type);
    return count.length
}


export { relationshipType, relationshipTypeToTitle, getTitleForBox, SentenceRelationShips, getboxTileForBoxRelation,getRelaionsCountOfType };