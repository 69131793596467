import React, { useRef, useState } from 'react'
import './sentence_write.css'
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import { projectType } from '../../../enum/enum';
import { showAlert } from '../../../functions/show_alert';
export default function SentenceWrite({ setTextAreaValue, TextAreaValue, identifyWord, SetIdentifyedWord, typeProject }) {
    const [selectedValue, SetSelectedValue] = useState();
    const quillRef = useRef(null);
    const hundleSelectionChanged = (e) => {
        if (e && e.length > 0) {
            const editor = quillRef.current.getEditor();
            const selectedText = editor.getText(e.index, e.length);
            SetSelectedValue(selectedText);  // This should print the selected text
        }
    }
    const pickThisWord = () => {
        if (!selectedValue || selectedValue.length < 3) {
            showAlert(false, `Select ${typeProject === projectType.sentenceType ? 'Word' : 'Sentence'} first`)
            return;
        }

        if (typeProject === projectType.sentenceType && selectedValue.length >= 40) {
            showAlert(false, 'the word you are selecting is too long for sentence flow type')
            return;
        } else if (typeProject === projectType.paragrapthType && selectedValue.length >= 140) {
            showAlert(false, 'the Sentence you are selecting is too long')
            return;
        } else {
            const newWord = {
                'value': selectedValue,
                'base_offset': 0,
                'extent_offset': 10,
            }
            SetIdentifyedWord(state => {
                var exist = false;
                for (var i = 0; i < state.length; i++) {
                    if (state[i].value === newWord.value && state[i].base_offset === newWord.base_offset && state[i].extent_offset === newWord.extent_offset) {
                        exist = true;
                    }
                }
                if (exist) {
                    showAlert(false, 'the Identification Exist')
                    return state;
                } else {
                    return [...state, newWord];
                }
            });
            SetSelectedValue();
        }
    }
    const deleteThisWord = (e) => {
        let data = identifyWord;
        data = data.filter(object => object.value !== e.value);
        SetIdentifyedWord(data);
    }
    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];
    return (
        <>
            <div className='sentence-write-place'>
                <div onClick={(e) => pickThisWord()} id='top-border-sentence' className="top-border-sentence">
                    <h1 className='identify-word-text-button'>{typeProject === projectType.sentenceType ? 'Identify word' : 'Identify Sentences'}</h1>
                    <AddIcon className='add-icons' />
                </div>
                <ReactQuill defaultValue={TextAreaValue} value={TextAreaValue} onChangeSelection={(e) => hundleSelectionChanged(e)} formats={formats} onChange={(e) => {
                    if (e === TextAreaValue || TextAreaValue === '') {
                        setTextAreaValue(e);
                    } else {
                        setTextAreaValue(e);
                    }
                }} ref={quillRef} id={'text-area-box'} className="sentence-text-place" theme="snow" />
                <IdentifyedWordBox deleteThisWord={deleteThisWord} typeProject={typeProject} identifyWord={identifyWord} />
            </div>

        </>

    )
}


const IdentifyedWordBox = ({ identifyWord, typeProject, deleteThisWord }) => {
    return <div className="identified-word-box">
        <h1 className='identifyed-word-title'>{typeProject === projectType.sentenceType ? 'Identified Words' : 'Sentences Identified'}</h1>
        <IdentifyedWord deleteThisWord={deleteThisWord} identifyWord={identifyWord} />
    </div>;
}

const IdentifyedWord = ({ identifyWord, deleteThisWord }) => {
    if (identifyWord) {
        return <>{
            identifyWord.map((e, index) => {
                return <div key={index} className="identifyed-words">
                    <h1 className='identifyed-word-text'>{e.value}</h1>
                    <div className='identifyed-word-divider'></div>
                    <div onClick={() => deleteThisWord(e, index)} className="remove-word">X</div>
                </div>
            })
        }

        </>
    }
    return <></>

}
