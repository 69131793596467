import React from 'react'
import './create_project.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
export default function CreateProject({ createProjectPressed }) {
    return (
        <Link className='tn'>
            <div onClick={() => { createProjectPressed(true) }} className='main-create-project'>
                <div className="create-project-title">
                    Add New Project
                </div>
                <div className='create-icon'>
                    <AddCircleIcon className='icon-create' />
                </div>
            </div>
        </Link>

    )
}
