import AppBarWidget from '../../widgets/app_bar/app_bar'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { requestState } from '../../global/http_enum';
import ResponseHttp from '../../widgets/response_http/response_http';
import InformationFlowBoxElement from './information_flow_box/information_flow_box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './infromation_flow.css'
import InfromationFlowTitle from './flow_tittle/flow_title';
import CreateFlowInformationScreen from './create_flow/create_information_flow';
import { deleteFlowInfoAPI, getInfromationFlow } from '../../api/information_flow';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../functions/show_alert';
import { cookiesGlobal } from '../../App';
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page';
export default function ImformationFlow() {
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    const [response, SetResponse] = useState(requestState.initial);
    const [Flow, SetFlow] = useState();
    const [createFlow, SetCreateFlow] = useState(false);
    const [editFlowInfo, SetEditFlowInfo] = useState(false);
    useEffect(() => {
        getInfromationFlow(projectSelected.id).then((value) => {
            if (value.statusCode === 200) {
                SetFlow(value.response);
                SetResponse(requestState.haveData);
            } else if (value.statusCode === 401) {
                SetResponse(requestState.invalidTokne);
            } else if (value.statusCode === 505) {
                SetResponse(requestState.internetConnection);
            } else {
                SetResponse(requestState.serverError);
            }
        })
    }, [projectSelected])
    const hundelEditFlow = (e) => {
        SetCreateFlow(true);
        let objectWithId3 = Flow.find(obj => obj.id === e);
        SetEditFlowInfo(objectWithId3);
    }
    const backButton = (e) => {
        SetCreateFlow(e);
        SetEditFlowInfo(false);
    }

    if (response === requestState.haveData) {
        return <>
            <AppBarWidget />
            <div id='infromation-flow-screen'>
                <InfromationFlowTitle  createFlow={createFlow} SetCreateFlow={backButton} />
                {createFlow ? <CreateFlowInformationScreen setEditFlow={SetEditFlowInfo} flows={Flow} SetFlow={SetFlow} EditFlowInfo={editFlowInfo} setCreateFlow={SetCreateFlow} projectSelected={projectSelected} /> : 
                <InfromationFlowView type={projectSelected.project_type} SetFlow={SetFlow} SetEditFlowInfo={hundelEditFlow} Flow={Flow} SetCreateFlow={SetCreateFlow} />}
            </div>
        </>
    }
    return (
        <>
            <AppBarWidget />
            <ResponseHttp response={response} />
        </>
    )
}

const InfromationFlowView = ({ Flow, SetCreateFlow, SetEditFlowInfo, SetFlow, type }) => {
    const [pickedDelete, SetPickedDelete] = useState(-1);
    const navigate = useNavigate();
    const DeleteFlow = async (index) => {
        SetPickedDelete(-1)
        showProgressAlert();
        const response = await deleteFlowInfoAPI(index);
        HideProgressAlert();
        if (response.statusCode <= 300) {
            const updatedItems = Flow.filter(item => item.id !== index);
            SetFlow(updatedItems);
            showAlert(true, 'Flow Deleted');
        } else if (response.statusCode === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        } else {
            showAlert(false, response.response);
        }
    }
    return <div className="flow-elements-holder">
        {Flow.map((e, index) => <InformationFlowBoxElement type={type} SetPickedDelete={SetPickedDelete} key={index} index={index} flow={e} SetCreateFlow={SetCreateFlow} SetPickedFlow={SetEditFlowInfo} />)}
        <div className="create-flow-element">
            <h1 className='create-flow-element-top'>Create Flow</h1>
            <div className='create-flow-element-bottom'><AddCircleIcon onClick={() => SetCreateFlow(1)} className='icon' /></div>
        </div>
        {pickedDelete === -1 ? <></> : <div onClick={() => SetPickedDelete(-1)} id="delete-item">
            <div className="alert-mesg">
                <h1>Are you sure You want to delete This information Flow ?</h1>
                <div className="buttons">
                    <div onClick={() => SetPickedDelete(-1)} className="bt close">Close</div>
                    <div onClick={() => DeleteFlow(pickedDelete)} className="bt done">Confirm</div>
                </div>
            </div>
        </div>
        }
    </div>
}
