import React, { useEffect, useState } from 'react'
import { getRelationship } from '../../api/relationship';
import { requestState } from '../../global/http_enum';
import AppBarWidget from '../../widgets/app_bar/app_bar'
import './relationship_screen.css'
import ResponseHttp from '../../widgets/response_http/response_http';
import { useSelector } from 'react-redux';
import SetRelationship from './set_relationship/set_relationship';
import { showAlertWithText } from '../../functions/show_alert_with_text'
import { showAlert } from '../../functions/show_alert';
import { Navigate } from 'react-router-dom';
export default function RelationShipScreen() {
    const selectedProject = useSelector(state => state.projectSlice.projectSelected)
    const [relationShip, SetRelationShip] = useState();
    const [relationShipresponse, SetRelationShipresponse] = useState(requestState.initial);
    useEffect(() => {
        getRelationship(selectedProject.id).then(value => {
            if (value.statusCode === 200) {
                SetRelationShip(value.response);
                SetRelationShipresponse(requestState.haveData);
            } else if (value.statusCode === 401) {
                SetRelationShipresponse(requestState.invalidTokne);
            } else if (value.statusCode === 505) {
                SetRelationShipresponse(requestState.internetConnection);
            } else {
                SetRelationShipresponse(requestState.serverError);
            }
        });
    }, [selectedProject]);
    if (selectedProject.word.length < 1) {
        showAlert(false, 'identfy words first ');
        return <Navigate to={'/sentence'} replace={true} />
    }
    if (selectedProject.entity.length < 1) {
        showAlertWithText('At least two entities and words mut be identified in order to show realtiosnhips between two entities and At least two words must be identified in order to show relationship between two words ');
        return <></>
    }
    if (relationShipresponse === requestState.haveData) {
        return <>
            <AppBarWidget />
            <div id='relationship-screen'>
                <SetRelationship typeProject={selectedProject.project_type} principle={relationShip.principle} relationShip={relationShip.relation} SetRelationShip={SetRelationShip} />
            </div>
        </>
    } else {
        return <>
            <AppBarWidget />
            <ResponseHttp response={relationShipresponse} />;
        </>

    }
}