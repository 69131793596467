import React, { useState } from 'react';
import { getboxTileForBoxRelation, getTitleForBox, relationshipTypeToTitle, relationshipType, SentenceRelationShips, getRelaionsCountOfType } from '../../../enum/relationship';
import './relation_view.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowRelation from '../../../assets/relation-mobile.png'
import CloseIcon from '@mui/icons-material/Close';
import CreationContent from '../../../widgets/content/content_hundler';
import { projectType } from '../../../enum/enum';
import Slider from "react-slick";
import YesNoQuestion from '../yes_no_question/yes_no_question';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux';
import { settingsSlider } from '../../../global/utils';
import { showAlertWithTextGlobal } from '../../../functions/show_alert_with_text_global';
export default function RelationsView({ type, relations, principle, showExplanation, SetShowExplanation, typeProject, SetCreateRelationship }) {
  const projectSelected = useSelector(state => state.projectSlice.projectSelected)
  const [showRelationShip, setRelationShip] = useState(null);
  const [showDrived, SetShowDrived] = useState();
  const hundleClickToShowDrived = (type, e, third) => {
    if (third) {
      return;
    } else {
      if (type === 'Principle') {
        const isDrived = principle.principle.some(obj => obj.type === 'derived' && obj.value === true);
        if (!isDrived) {
          return;
        }
        SetShowDrived(true);
        return;
      }
      if (type === 'Word') {
        showAlertWithTextGlobal(e.value);
        return;
      }
      if (type === 'Sentence') {
        if (typeProject === projectType.sentenceType) {
          showAlertWithTextGlobal(e);
        } else {
          showAlertWithTextGlobal(e.value);
        }
      }
      if (type === 'Paragraph') {
        showAlertWithTextGlobal(e);
        return;
      }
    }
  }
  return (
    <>
      <div id='relationship-view-web'>
        <div className="realtionship-title">
          {relationshipTypeToTitle(type)}
        </div>
        {relations.map((e, index) => {
          if (type === e.type) {
            return <RelationShipElement hundleClickToShowDrived={hundleClickToShowDrived} index={index} SetShowExplanation={SetShowExplanation} sentence={projectSelected.sentence} principle={principle} typeproject={projectSelected.project_type} key={index} inputName={index} relationData={e} />
          } else {
            return <></>
          }
        })}
      </div>
      <div id='relationship-view-mobile'>
        <RelationsMobile hundleClickToShowDrived={hundleClickToShowDrived} principle={principle} sentence={projectSelected.sentence} SetShowExplanation={SetShowExplanation} relations={relations} SetCreateRelationship={SetCreateRelationship} setRelationShip={setRelationShip} showRelationShip={showRelationShip} typeProject={typeProject} />
      </div>
      {showExplanation === -1 ? <></> : <div onClick={() => SetShowExplanation(-1)} id='show-explanation'>
        <div dangerouslySetInnerHTML={{ __html: showExplanation }} className="content-explanation"></div>
      </div>}
      {showDrived ?
        <div onClick={() => SetShowDrived(false)} id='show-explanation'>
          <div dangerouslySetInnerHTML={{ __html: principle.explanation }} className="content-explanation"></div>
        </div>
        : <></>}
    </>

  )
}
const RelationShipElement = ({ typeproject, relationData, principle, sentence, SetShowExplanation, hundleClickToShowDrived }) => {
  return <><div className="relationship-element">
    <div className="boxes">
      <div onClick={() => hundleClickToShowDrived(getboxTileForBoxRelation(relationData.type, true, typeproject), relationData.first)} className="relationship-box">
        <h1 className="title">{getboxTileForBoxRelation(relationData.type, true, typeproject)}</h1>
        <h1 className="content">
          <UiHundlerForRelation sentence={sentence} principle={principle} typeProject={typeproject} type={relationData.type} content={relationData.first} first={true} />
        </h1>
      </div>
      <div onClick={() => hundleClickToShowDrived(getboxTileForBoxRelation(relationData.type, false, typeproject), relationData.second)} className="relationship-box">
        <h1 className="title">{getboxTileForBoxRelation(relationData.type, false, typeproject)}</h1>
        <h1 onClick={() => {

        }} className="content">
          <UiHundlerForRelation sentence={sentence} principle={principle} typeProject={typeproject} type={relationData.type} content={relationData.second} first={false} />
        </h1>
      </div>
    </div>
    <div className="arrow-holder">
      <img src={ArrowRelation} alt=" " />
      {relationData.answer ? <></> : <CloseIcon className='notrelated' />}
    </div>
    {!relationData.third ? <></> :
      <div className="third-entity">
        <div className="title">Result</div>
        <div onClick={() => SetShowExplanation(relationData.explanation)} className="content">
          {relationData.third_type === 'String' ? <>{relationData.third}</> :
            <CreationContent principle={principle} content={relationData.third} sentence={sentence} type={relationData.third_type} />}
        </div>
      </div>
    }


  </div>
    <YesNoQuestion name={relationData.id} asnwer={relationData.answer} firstData={relationData.first} secondData={relationData.second} setAnswer={null} thirdData={relationData.third} thirdtype={relationData.third_type} type={relationData.type} typeProject={typeproject} principle={principle.explanation} />
  </>
}

const UiHundlerForRelation = ({ principle, first, typeProject, type, sentence, content }) => {
  if (getTitleForBox(type, first, typeProject) === 'Entity' && Array.isArray(content)) {
    if (typeProject === projectType.sentenceType) {
      return content.map((e) => <CreationContent principle={principle} content={e} sentence={sentence} type={getTitleForBox(type, first, typeProject)} />)
    } else {
      return <div className="holder">
        <Slider {...settingsSlider}>
          {content.map((e) => <CreationContent principle={principle} content={e} sentence={sentence} type={getTitleForBox(type, first, typeProject)} />)}
        </Slider>
      </div>
    }
  } else {
    return <CreationContent principle={principle} content={content} sentence={sentence} type={getTitleForBox(type, first, typeProject)} />
  }
}
const RelationsMobile = ({ showRelationShip, typeProject, setRelationShip, relations, SetCreateRelationship, SetShowExplanation, sentence, principle, hundleClickToShowDrived }) => {
  if (showRelationShip === null) {
    if (projectType.sentenceType === typeProject) {
      return <>
        <div onClick={() => setRelationShip(relationshipType.wordWord)} className="element-holder-mobile-relation"><div className="text">1  &nbsp; Word and Word Relationships ({getRelaionsCountOfType(relationshipType.wordWord, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(relationshipType.wordEntity)} className="element-holder-mobile-relation"><div className="text">2  &nbsp; Word and Entity Relationships  ({getRelaionsCountOfType(relationshipType.wordEntity, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(relationshipType.entityEntity)} className="element-holder-mobile-relation"><div className="text">3  &nbsp; Entity and Entity Relationships  ({getRelaionsCountOfType(relationshipType.entityEntity, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        {principle.length === 0 ? <></> : <>
          <div onClick={() => setRelationShip(relationshipType.principleWord)} className="element-holder-mobile-relation"><div className="text">4  &nbsp; Principle and Word Relationships  ({getRelaionsCountOfType(relationshipType.principleWord, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
          <div onClick={() => setRelationShip(relationshipType.principleEntiy)} className="element-holder-mobile-relation"><div className="text">5  &nbsp; Principle and Entity Relationships  ({getRelaionsCountOfType(relationshipType.principleEntiy, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
          <div onClick={() => setRelationShip(relationshipType.sentencePrinciple)} className="element-holder-mobile-relation"><div className="text">6  &nbsp; Sentence and Principle Relationships  ({getRelaionsCountOfType(relationshipType.sentencePrinciple, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div></>}

      </>
    } else {
      return <>
        <div onClick={() => setRelationShip(SentenceRelationShips.SentenceSentence)} className="element-holder-mobile-relation"><div className="text">1  &nbsp; Sentence and Sentence Relationships  ({getRelaionsCountOfType(SentenceRelationShips.SentenceSentence, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(SentenceRelationShips.SentenceParagraph)} className="element-holder-mobile-relation"><div className="text">2  &nbsp; Sentence and Paragraph Relationships ({getRelaionsCountOfType(SentenceRelationShips.SentenceParagraph, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(SentenceRelationShips.SentenceEntities)} className="element-holder-mobile-relation"><div className="text">3  &nbsp; Sentence and Entities Relationships ({getRelaionsCountOfType(SentenceRelationShips.SentenceEntities, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(SentenceRelationShips.ParagraphEntities)} className="element-holder-mobile-relation"><div className="text">4  &nbsp; Paragraph and Entities Relationships ({getRelaionsCountOfType(SentenceRelationShips.ParagraphEntities, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(SentenceRelationShips.EntitiesEntities)} className="element-holder-mobile-relation"><div className="text">5  &nbsp; Entities and Entities Relationships ({getRelaionsCountOfType(SentenceRelationShips.EntitiesEntities, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(SentenceRelationShips.wordEntites)} className="element-holder-mobile-relation"><div className="text">6  &nbsp; Word and Entities Relationships ({getRelaionsCountOfType(SentenceRelationShips.wordEntites, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        <div onClick={() => setRelationShip(SentenceRelationShips.sentenceWord)} className="element-holder-mobile-relation"><div className="text">7  &nbsp; Sentence and Word Relationships ({getRelaionsCountOfType(SentenceRelationShips.sentenceWord, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
        {principle.length === 0 ? <></> : <>
          <div onClick={() => setRelationShip(SentenceRelationShips.SentencePrinciple)} className="element-holder-mobile-relation"><div className="text">8  &nbsp; Sentence and Principle Relationships ({getRelaionsCountOfType(SentenceRelationShips.SentencePrinciple, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
          <div onClick={() => setRelationShip(SentenceRelationShips.ParagraphPrincipl)} className="element-holder-mobile-relation"><div className="text">9  &nbsp; Paragraph and Principle Relationships ({getRelaionsCountOfType(SentenceRelationShips.ParagraphPrincipl, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div>
          <div onClick={() => setRelationShip(SentenceRelationShips.EntitiesPrinciple)} className="element-holder-mobile-relation"><div className="text">10  &nbsp; Entities and Principle Relationships ({getRelaionsCountOfType(SentenceRelationShips.EntitiesPrinciple, relations)})</div><div className="arrow"><ArrowForwardIosIcon /></div></div></>}
      </>
    }
  }
  const filtredVerifications = relations.filter(item => item.type === showRelationShip);

  if (filtredVerifications.length === 0) {
    return <div id='mobile-verification-emty'>
      <h1 className='text'>This type of Relationship is Empty</h1>
      <div onClick={() => SetCreateRelationship(true)} className="create-now">Create</div>
      <div onClick={() => setRelationShip(null)} className="back">Back</div>
    </div>
  } else {
    return <>
      <div onClick={() => setRelationShip(null)} className="close"> Close This Type</div>
      {filtredVerifications.map((e, index) => {
        return <RelationShipElement hundleClickToShowDrived={hundleClickToShowDrived} index={index} SetShowExplanation={SetShowExplanation} sentence={sentence} principle={principle} typeproject={typeProject} key={index} inputName={index} relationData={e} />
      })}
    </>
  }
}




