import { createSlice } from '@reduxjs/toolkit'

export const routerSlice = createSlice({
    name: 'projects',
    initialState: {
        page: 0,
    },
    reducers: {
        changePage: (state, actions) => {
            state.page = actions.payload;
        }
    },
})

export const { changePage } = routerSlice.actions

export default routerSlice.reducer