import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProjectReportAPI } from '../../api/report';
import { projectType } from '../../enum/enum';
import { getTitleForBox } from '../../enum/relationship';
import { typeIsNotRelated, verificationToMapMoile } from '../../enum/verification';
import { requestState } from '../../global/http_enum';
import { getSentenceString } from '../../global/utils';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import ResponseHttp from '../../widgets/response_http/response_http';
import './report.css';
import ReportTitle from './report_title/report_title';
export default function ReportScreen() {
    const selectedProject = useSelector(state => state.projectSlice.projectSelected);
    const [response, SetResponse] = useState(requestState.initial);
    const [report, SetReport] = useState(requestState.initial);
    useEffect(() => {
        getProjectReportAPI(selectedProject.id).then((value) => {
            if (value.statusCode === 200) {
                SetReport(value.response);
                SetResponse(requestState.haveData);
            } else if (value.statusCode === 401) {
                SetResponse(requestState.invalidTokne);
            } else if (value.statusCode === 505) {
                SetResponse(requestState.internetConnection);
            } else {
                SetResponse(requestState.serverError);
            }
        })
    }, [selectedProject])
    return (
        <>
            <AppBarWidget />
            <div id='report-screen'>
                <ReportTitle />
                <ReportResponse type={selectedProject.project_type} response={response} report={report} />

            </div>
        </>

    )
}

const ReportResponse = ({ response, report, type }) => {
    if (response === requestState.haveData) {
        const sentenceText = getSentenceString(report.project.sentence,)
        return <div className="project-name">
            <h1 className='title'> Project: <span className='information'>{report.project.name}</span></h1>
            <h1 style={{ marginTop: '20px' }} className='sentence'> {type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} : <span dangerouslySetInnerHTML={{ __html: sentenceText }} className='information'></span></h1>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>  {type === projectType.sentenceType ? 'Word Identified:' : 'Sentences Identified:'}</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are {type === projectType.sentenceType ? 'Words' : 'Sentences'}  the users have identified:</h1>
                    {report.project.word.map((e, index) => <h1 key={index} className='information entity-word'>{e.value}</h1>)}
                </div>
            </div>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>Entities Identified:</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are Entities the users have identified:</h1>
                    {report.project.entity.map((e, index) => <h1 key={index} className='information entity-word'>{e.name}</h1>)}
                </div>
            </div>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>Verification:</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are Verifications the users have identified:</h1>
                    {report.verification.map((e, index) => {
                        let addLine = index + 1 === report.verification.length;
                        return <>
                            <h1 key={index} className='information'>
                                {getSentenceString(e.word)}
                                <span className={`${typeIsNotRelated(e.type) ? 'not-active' : 'active'}`}>  {' ' + verificationToMapMoile(e.type, type) + ' '} </span>
                                {e.entity.length === 1 ? ' ' + e.entity[0].name : e.entity.map((entity, index) => { if (index === e.entity.length - 1) { return entity.name } return ` ${entity.name} and ` })}
                            </h1>
                            {addLine ? <></> : <div className="line"></div>}
                        </>
                    })}

                </div>
            </div>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>Relationship:</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are Relationships the users have identified:</h1>
                    {report.relation.map((e, index) => {
                        let addLine = index + 1 === report.relation.length;
                        return <>
                            <h1 key={index} className='information'>
                                <RelationShipReport principle={report.principle.explanation} sentenceText={sentenceText} firstData={e.first} firstOne={true} secondData={e.second} thirdData={e.third} thirdtype={e.third_type} type={e.type} typeProject={type} />
                                <span className={`${e.answer !== true ? 'not-active' : 'active'}`}>{e.answer === true ? 'Related to' : 'Not Related to'}</span>
                                <RelationShipReport principle={report.principle.explanation} firstData={e.first} firstOne={false} secondData={e.second} thirdData={e.third} thirdtype={e.third_type} type={e.type} typeProject={type} />
                            </h1>
                            {addLine ? <></> : <div className="line"></div>}
                        </>
                    })}
                </div>
            </div>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>Linear Relationship:</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are Linear Relationship the users have identified:</h1>
                    {report.linear_relationship.map((e, index) => <LinearClid addLine={index + 1 === report.linear_relationship.length} flows={e} />
                    )}
                </div>
            </div>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>Flows:</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are Flows the users have identified</h1>
                    {report.flow.map((flows, index) => <FlowClid key={index} flows={flows} />)}
                </div>
            </div>
            <div style={{ marginTop: '30px' }} className="content">
                <div className="titles">
                    <h1 className='title'>Information Flows:</h1>
                </div>
                <div className="informations">
                    <h1 className='before-infromation'>Those are Information flows users have identified</h1>
                    {report.InformationFlow.map((flows, index) => <InformationFlowWidget addLine={index + 1 === report.InformationFlow.length} flow={flows} />)}
                </div>
            </div>
        </div >
    } else {
        return <ResponseHttp response={response} />
    }
}
const FlowClid = ({ flows }) => {
    let mergedList = flows.entity.flatMap((obj, index) => [obj, flows.string[index]]);
    return <h1 className='information'>
        {mergedList.map((e, index) => {
            return <>{typeof e === 'object' ? ` ${e.map((e) => e.name)} ` : <span className={`active `}>{` ${e ?? ''} `}</span>}</>
        })}
    </h1>
}
const LinearClid = ({ flows, addLine }) => {
    let mergedList = flows.word.flatMap((obj, index) => [obj, flows.relation[index]]);
    return <>
        <h1 className='information'>
            {mergedList.map((e, index) => {
                const isList = Array.isArray(e);
                if (isList) {
                    let i = 0;
                    return e.map((e, index) => {
                        i = i + 1;
                        return <>Principle {i} </>
                    });
                } else {
                    return <>{typeof e === 'object' ? ` ${e.value} ` : <span className={`flow-words ${e === 'Not Related to' ? 'not-active' : 'active'}`}>{` ${e ?? ''} `}</span>}</>
                }
            })}
        </h1>
        {addLine ? <></> : <div className="line"></div>}
    </>


}

const RelationShipReport = ({ typeProject, firstData, secondData, thirdData, type, thirdtype, firstOne, sentenceText, principle }) => {
    let first = '';
    let second = '';
    let third = '';
    if (typeProject === projectType.sentenceType) {
        if (getTitleForBox(type, true, typeProject) === 'Entity') {
            first = ' ' + firstData[0].name + ' ';
        }
        if (getTitleForBox(type, true, typeProject) === 'Principle') {
            let principles = '';
            let containsValue = firstData.some(obj => obj.type === 'derived');
            if (containsValue) {
                principles = getSentenceString(principle);
            } else {
                firstData.forEach((e, index) => {
                    if (e.type === 'identify') {
                        if (index === firstData.length - 1) {
                            principles = principles + `principles ${index + 1} `
                        } else {
                            principles = principles + `principles ${index + 1} and `
                        }
                    }
                })
            }
            first = principles;
        }
        if (getTitleForBox(type, true, typeProject) === 'Word') {
            first = firstData.value;
        }
        if (getTitleForBox(type, true, typeProject) === 'Sentence') {
            first = ` ${sentenceText} `;
        }
        if (getTitleForBox(type, false, typeProject) === 'Entity') {
            second = ' ' + secondData[0].name + ' ';
        }
        if (getTitleForBox(type, false, typeProject) === 'Principle') {
            let principles = '';
            let containsValue = secondData.some(obj => obj.type === 'derived');
            if (containsValue) {
                principles = getSentenceString(principle);
            } else {
                secondData.forEach((e, index) => {
                    if (e.type === 'identify') {
                        if (index === secondData.length - 1) {
                            principles = principles + `principles ${index + 1} `
                        } else {
                            principles = principles + `principles ${index + 1} and `
                        }
                    }

                })
            }
            second = principles;
        }
        if (getTitleForBox(type, false, typeProject) === 'Word') {
            second = ' ' + secondData.value + ' ';
        }
        if (getTitleForBox(type, false, typeProject) === 'Sentence') {
            second = ` ${sentenceText} `;
        }
        if (thirdData) {
            if (thirdtype === 'Word') {
                third = thirdData.value;
            } else if (thirdtype === 'String') {
                third = thirdData;
            } else {
                third = thirdData.name;
            }
        }
    } else {
        if (getTitleForBox(type, true, typeProject) === 'Entity') {
            let entityName = '';
            firstData.forEach((e, index) => {
                if (index === firstData.length - 1) {
                    entityName = entityName + ` ${e.name} `
                } else {
                    entityName = entityName + ` ${e.name} and`
                }
            })
            first = entityName;
        }
        if (getTitleForBox(type, true, typeProject) === 'Principle') {
            let principles = '';
            let containsValue = firstData.some(obj => obj.type === 'derived');
            if (containsValue) {
                principles = getSentenceString(principle);
            } else {
                firstData.forEach((e, index) => {
                    if (e.type === 'identify') {
                        if (index === firstData.length - 1) {
                            principles = principles + `principles ${index + 1} `
                        } else {
                            principles = principles + `principles ${index + 1} and `
                        }
                    }
                })
            }
            first = principles;
        }
        if (getTitleForBox(type, true, typeProject) === 'Word') {
            first = ` ${first = firstData.value} `;
        }
        if (getTitleForBox(type, true, typeProject) === 'Sentence') {
            first = ` ${sentenceText} `;
        }
        if (getTitleForBox(type, false, typeProject) === 'Entity') {
            var entityName = '';
            secondData.forEach((e, index) => {
                if (index === secondData.length - 1) {
                    entityName = entityName + ` ${e.name}`
                } else {
                    entityName = entityName + ` ${e.name} and`
                }

            })
            second = entityName;
        }
        if (getTitleForBox(type, false, typeProject) === 'Principle') {
            let principles = '';
            let containsValue = secondData.some(obj => obj.type === 'derived');
            if (containsValue) {
                principles = getSentenceString(principle);
            } else {
                secondData.forEach((e, index) => {
                    if (e.type === 'identify') {
                        if (index === secondData.length - 1) {
                            principles = principles + `principles ${index + 1} `
                        } else {
                            principles = principles + `principles ${index + 1} and `
                        }
                    }

                })
            }
            second = principles;

        }
        if (getTitleForBox(type, false, typeProject) === 'Word') {
            second = ` ${first = secondData.value} `;
        }
        if (getTitleForBox(type, false, typeProject) === 'Sentence') {
            second = ` ${sentenceText} `;
        }
        if (thirdData) {
            if (thirdtype === 'Word') {
                third = ` ${third = thirdData.value} `;
            } else if (thirdtype === 'String') {
                third = thirdData;
            } else if (thirdtype === 'Principle-relation') {
                third = ` ${thirdData} `
            }
            else {
                third = thirdData.name;
            }
        }
    }
    if (firstOne) {
        return ' ' + first + ' ';

    } else {
        return <> {second}{!third ? '' : <><span> by </span>  {third}</>}</>
    }
}

const InformationFlowWidget = ({ flow, type, addLine }) => {
    return <div className="main-info-flow">
        <div className="flow-content">{checkIfMap(flow.sentence) ? flow.sentence.name : truncateString(flow.sentence, 140)} <span className={`${flow.flowTo === 1 ? 'active' : 'not-active'}`} >{flow.flowTo === 1 ? 'Flow to' : 'Not Flow to'}</span> {flow.entity2.name}</div>
        <p className="yes"> <span className={`${flow.pointTo === 1 ? 'active' : 'not-active'}`}>{flow.pointTo === 1 ? 'Yes' : 'No'}, </span> all words in the {type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} <span className={`${flow.pointTo === 1 ? 'active' : 'not-active'}`}>{flow.pointTo === 1 ? '' : 'not'} point to </span>actual entities</p>
        <p className="yes"> <span className={`${flow.naturallyIdentified === 1 ? 'active' : 'not-active'}`}>{flow.naturallyIdentified === 1 ? 'Yes' : 'No'}, </span> all words in the {type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} <span className={`${flow.naturallyIdentified === 1 ? 'active' : 'not-active'}`}>{flow.naturallyIdentified === 1 ? '' : 'not'} naturally identified </span>actual entities</p>
        <p className="yes"> <span className={`${flow.definedBy === 1 ? 'active' : 'not-active'}`}>{flow.definedBy === 1 ? 'Yes' : 'No'}, </span> all words in the {type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} <span className={`${flow.definedBy === 1 ? 'active' : 'not-active'}`}>{flow.definedBy === 1 ? '' : 'not'} defined by </span> actual entities</p>
        <p className="yes last-space-before-line"> <span className={`${flow.areRelatedTo === 1 ? 'active' : 'not-active'}`}>{flow.areRelatedTo === 1 ? 'Yes' : 'No'}, </span> all words in the {type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} <span className={`${flow.areRelatedTo === 1 ? 'active' : 'not-active'}`}>{flow.areRelatedTo === 1 ? 'are' : 'not'} related to </span> actual entities</p>
        {addLine ? <></> : <><div className='line'></div></>}
    </div>
}
function checkIfMap(obj) {
    return typeof obj === 'object'
}
function truncateString(str, length) {
    if (str.length > length) {
        return str.substring(0, length) + '...';
    } else {
        return str;
    }
}
