import React from 'react'
import LinearBoxWiget from '../linear-widget/linear-widget'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './linear-view.css';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { cookiesGlobal } from '../../../App';
import { DeleteLinearRelationShip } from '../../../api/ligner-relationship';
import { useState } from 'react';
import { showAlert } from '../../../functions/show_alert';
import { useNavigate } from 'react-router-dom';
export default function LinearViewWidget({ linearData, SetCreateLinear, type, SetLinearData, SetEditLinear }) {
    const navigate = useNavigate();
    const [pickedDelete, SetPickedDelete] = useState(-1);
    const deleteLinearFunction = async () => {
        showProgressAlert();
        const response = await DeleteLinearRelationShip(pickedDelete);
        HideProgressAlert();
        if (response.statusCode <= 300) {
            const updatedItems = linearData.filter(item => item.id !== pickedDelete);
            SetLinearData(updatedItems);
            showAlert(true, 'Linear Relation Deleted');
        } else if (response.statusCode === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        } else {
            showAlert(false, response.response);
        }
    };
    return (

        <div className="flow-elements-holder">
            {linearData.map((e, index) => <LinearBoxWiget SetCreateLinear={SetCreateLinear} SetEditLinear={SetEditLinear} type={type} DeleteLinear={SetPickedDelete} key={index} linearData={e} />)}
            <div className="create-flow-element">
                <h1 className='create-flow-element-top'>Create Linear</h1>
                <div className='create-flow-element-bottom'><AddCircleIcon onClick={() => SetCreateLinear(1)} className='icon' /></div>
            </div>
            {pickedDelete === -1 ? <></> : <div onClick={() => SetPickedDelete(-1)} id="delete-item">
                <div className="alert-mesg">
                    <h1>Are you sure You want to delete This Linear Relation ?</h1>
                    <div className="buttons">
                        <div onClick={() => SetPickedDelete(-1)} className="bt close">Close</div>
                        <div onClick={() => deleteLinearFunction(pickedDelete)} className="bt done">Confirm</div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
