import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getRelationship } from '../../api/relationship';
import { requestState } from '../../global/http_enum';
import AppBarWidget from '../../widgets/app_bar/app_bar'
import ResponseHttp from '../../widgets/response_http/response_http';
import './identify_principle.css'
import IdentifyPrincipleHeader from './identify_principle_header/identfiy_principle_header';
import SetPrinciple from './set_principle/set_principle';
export default function IdenrtifyPrinciple() {
    const selectedProject = useSelector(state => state.projectSlice.projectSelected)
    const [relationShip, SetRelationShip] = useState();
    const [relationShipresponse, SetRelationShipresponse] = useState(requestState.initial);
    useEffect(() => {
        getRelationship(selectedProject.id).then(value => {
            if (value.statusCode === 200) {
                SetRelationShip(value.response);
                SetRelationShipresponse(requestState.haveData);
            } else if (value.statusCode === 401) {
                SetRelationShipresponse(requestState.invalidTokne);
            } else if (value.statusCode === 505) {
                SetRelationShipresponse(requestState.internetConnection);
            } else {
                SetRelationShipresponse(requestState.serverError);
            }
        });
    }, [selectedProject]);
    return (
        <div id='identify-principle'>
            <AppBarWidget />
            <PrincipleView selectedProject={selectedProject} relationShipresponse={relationShipresponse} relationShip={relationShip} />
        </div>
    )
}
const PrincipleView = ({ relationShipresponse, selectedProject, relationShip }) => {
    const [showIdent, SetShowIdent] = useState(true);
    if (relationShipresponse === requestState.haveData) {
        return <>
            <IdentifyPrincipleHeader showIdent={showIdent} SetShowIdent={SetShowIdent} />
            <SetPrinciple relationShip={relationShip} selectedProject={selectedProject} showIdent={showIdent} />
        </>


    } else {
        return <ResponseHttp response={relationShipresponse} />;
    }

}
