import React from 'react'
import { Link } from 'react-router-dom'
import { closeAlertWithText } from '../../functions/show_alert_with_text'
import './alert_with_text.css'
export default function AlertWithText() {
    return (
        <div id='Alert_with_text'>
            <div className="backgroud-layer"></div>
            <div className="box-text">
                <p id='text-in-alert'></p>
                <Link onClick={()=> closeAlertWithText()} id='button-alrt-1' className='button' to={'/identify-entity'}>identify entity</Link>
            </div>
        </div>
    )
}
