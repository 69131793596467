const showAlertWithText = (text) => {
    const alert = document.getElementById('Alert_with_text');
    const textPlace = document.getElementById('text-in-alert');
    alert.style.display = 'block'
    textPlace.textContent = text;
}
const closeAlertWithText = () => {
    const alert = document.getElementById('Alert_with_text');
    const textPlace = document.getElementById('text-in-alert');
    alert.style.display = 'none';
    textPlace.textContent = '';
}

export { closeAlertWithText, showAlertWithText }