const mainUrl = 'https://api.slpsolver.net/';
const HttpUrls = {
    // auth
    createAccount: `${mainUrl}register`,
    login: `${mainUrl}login`,
    sendEmailConformation: `${mainUrl}forgetPassword`,
    reResendforgetPassword: `${mainUrl}ResendforgetPassword`,
    confrimCode: `${mainUrl}checkCodeEmail`,
    newPassword: `${mainUrl}changePassword`,
    // GET for delete and post for edite
    createProject: `${mainUrl}project`,
    getProjects: `${mainUrl}projects`,
    deleteOrEditeProject: `${mainUrl}project`,
    // create entity for project / need project ID
    identifyEntity: `${mainUrl}create-entity`,
    deleteEntity: `${mainUrl}delete-entity`,
    // get relationship for project / need project ID
    getRelationships: `${mainUrl}relations`,
    setPrinciple: `${mainUrl}principles`,
    setRelationShip: `${mainUrl}relation`,
    // get linear relationship for project / need project ID
    linearRelationShipGet: `${mainUrl}linear-relationships`,
    linearRelationShipCreate: `${mainUrl}linear-relationship`,
    linearRelationShipDelete: `${mainUrl}linear-relationship-delete`,


    // get Flow API
    getFlow: `${mainUrl}flows`,
    CreateFlow: `${mainUrl}flow`,
    deleteFlowApi:`${mainUrl}flow-delete`,
    getOrCreateInformationFlow: `${mainUrl}information-flows`,
    deleteInfromationFlow: `${mainUrl}information-flows-delete`,
    // verifications API
    getVerification: `${mainUrl}verifications`,
    createVerification: `${mainUrl}verification`,
    // report API
    getProjectReports: `${mainUrl}reports`,
}

const urlForFiles = (value) => {
    return  value;
}
export { HttpUrls, mainUrl, urlForFiles };