import React from 'react'
import { closeAlertWithTextGlobal } from '../../functions/show_alert_with_text_global'
import './alert_with_text.css'
export default function AlertWithTextGlobal() {
    return (
        <div id='Alert_with_text_global'>
            <div onClick={()=> closeAlertWithTextGlobal()} className="backgroud-layer"></div>
            <div className="box-text">
                <div id='text-in-alert-global'></div>
            </div>
        </div>
    )
}
