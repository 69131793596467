import React, { useState } from 'react'
import AppBarWidget from '../../widgets/app_bar/app_bar'
import './sentence.css'
import 'react-quill/dist/quill.snow.css';
import SentenceTitle from './sentence_title/sentence_title'
import SentenceWrite from './sentence_write/sentence_write'
import { showAlert } from '../../functions/show_alert'
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page'
import { editeProjectAPI } from '../../api/project'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { editProjectByID } from '../../redux/project/projects'
import { useEffect } from 'react'
import { cookiesGlobal } from '../../App'
export default function SentenceScreen() {
    const selectedProject = useSelector(state => state.projectSlice.projectSelected);
    const [identifyWord, SetIdentifyedWord] = useState([]);
    const [TextAreaValue, setTextAreaValue] = useState('');
    const [projectName, setProjectName] = useState('');

    useEffect(() => {
        setTextAreaValue(selectedProject.sentence);
        SetIdentifyedWord(selectedProject.word);
        setProjectName(selectedProject.name);
    }, [selectedProject])
    const id = selectedProject.id;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const EditeProject = async (id) => {
        if (projectName === '') {
            showAlert(false, 'You forgot to write a name for this project');
            return;
        }
        if (TextAreaValue === '') {
            showAlert(false, 'Write You sentence First');
            return;
        }
        if (identifyWord.length === 0) {
            showAlert(false, 'Identify words for this project');
            return;
        }
        showProgressAlert();
        const response = await editeProjectAPI(projectName, TextAreaValue, Date.now(), identifyWord, id);
        HideProgressAlert();
        if (response.statusCode === 200) {
            dispatch(editProjectByID({ projectName, word: response.response.word, sentence: TextAreaValue, id: response.response.id }))
            navigate('/')
            return;
        }
        if (response === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        }
        showAlert(false, response.response);
    }
    return (
        <>
            <AppBarWidget />
            <div id='sentence'>
                <div className='save-button-title'>
                    <SentenceTitle type={selectedProject.project_type} />
                    <div onClick={() => EditeProject(id)} className="save-button">Save</div>
                </div>
                <SentenceWrite typeProject={selectedProject.project_type} SetIdentifyedWord={SetIdentifyedWord} TextAreaValue={TextAreaValue} setTextAreaValue={setTextAreaValue} identifyWord={identifyWord} />
            </div>
        </>

    )
}
