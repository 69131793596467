import React from 'react'
import './flow_box.css'
import DeleteIcon from '@mui/icons-material/Delete';
export default function FlowBoxElement({ index, flow, SetCreateFlow, SetPickedFlow, SetPickedDelete }) {
    let mergedList = flow.entity.flatMap((obj, index) => [obj, flow.string[index]]);
    return (
        <div className='flow-box-element'>
            <div className="flow-blox-header">
                <h1>Entity Flow</h1>
                <DeleteIcon onClick={() => { SetPickedDelete(flow.id) }} className="delete" />
            </div>
            <div onClick={(e) => { if (e.target) SetCreateFlow(2); SetPickedFlow(flow) }} className="flow-content">
                {mergedList.map(e => {
                 return  <>{typeof e === 'object' ? ` ${e.map((e) => e.name)} ` : <span className={`active `}>{` ${e ?? ''} `}</span>}</>
                })}
            </div>
        </div>
    )
}

