import React from 'react'
import { useSelector } from 'react-redux';
import { projectType } from '../../../enum/enum';
import { getTitleForBox } from '../../../enum/relationship'
import { getSentenceString } from '../../../global/utils';
import './yes_no_qestion.css';
export default function YesNoQuestion({ typeProject, setAnswer, asnwer, firstData, secondData, thirdData, type, thirdtype, name, principle }) {
  const paragrath = useSelector(state => state.projectSlice.projectSelected.sentence);
  const paragrathReady = getSentenceString(paragrath);
  if (!firstData || firstData === null || firstData.length === 0) {
    return <></>;
  }
  if (!secondData || secondData === null || secondData.length === 0) {
    return <></>;
  }
  let first = '';
  let second = '';
  let third = '';
  if (typeProject === projectType.sentenceType) {
    if (getTitleForBox(type, true, typeProject) === 'Entity') {
      first = firstData[0].name;
    }
    if (getTitleForBox(type, true, typeProject) === 'Principle') {
      let principles = '';
      let containsValue = firstData.some(obj => obj.type === 'derived');
      if (containsValue) {
        principles = getSentenceString(principle);
      } else {
        firstData.forEach((e, index) => {
          if (e.type === 'identify') {
            if (index === firstData.length - 1) {
              principles = principles + `principles ${index + 1} `
            } else {
              principles = principles + `principles ${index + 1} and `
            }
          }
        })
      }
      first = principles;
    }
    if (getTitleForBox(type, true, typeProject) === 'Word') {
      first = firstData.value;
    }
    if (getTitleForBox(type, true, typeProject) === 'Sentence') {
      first = paragrathReady;
    }
    if (getTitleForBox(type, false, typeProject) === 'Entity') {
      second = secondData[0].name;
    }
    if (getTitleForBox(type, false, typeProject) === 'Principle') {
      let principles = '';
      let containsValue = secondData.some(obj => obj.type === 'derived');
      if (containsValue) {
        principles = getSentenceString(principle);
      } else {
        secondData.forEach((e, index) => {
          if (e.type === 'identify') {
            if (index === secondData.length - 1) {
              principles = principles + `principles ${index + 1} `
            } else {
              principles = principles + `principles ${index + 1} and `
            }
          }

        })
      }
      second = principles;
    }
    if (getTitleForBox(type, false, typeProject) === 'Word') {
      second = secondData.value;
    }
    if (getTitleForBox(type, false, typeProject) === 'Sentence') {
      second = paragrathReady;
    }
    if (thirdData) {
      if (thirdtype === 'Word') {
        third = thirdData.value;
      } else if (thirdtype === 'String') {
        third = thirdData
      }
      else if (thirdtype === 'Principle-relation') {
        third = ` ${thirdData} `
      }
      else {
        third = thirdData.name;
      }
    }
  } else {
    if (getTitleForBox(type, true, typeProject) === 'Entity') {
      let entityName = '';
      firstData.forEach((e, index) => {
        if (index === firstData.length - 1) {
          entityName = entityName + ` ${e.name} `
        } else {
          entityName = entityName + ` ${e.name} and`
        }
      })
      first = entityName;
    }
    if (getTitleForBox(type, true, typeProject) === 'Principle') {
      let principles = '';
      let containsValue = firstData.some(obj => obj.type === 'derived');
      if (containsValue) {
        principles = getSentenceString(principle);
      } else {
        firstData.forEach((e, index) => {
          if (e.type === 'identify') {
            if (index === firstData.length - 1) {
              principles = principles + `principles ${index + 1} `
            } else {
              principles = principles + `principles ${index + 1} and `
            }
          }
        })
      }
      first = principles;
    }
    if (getTitleForBox(type, true, typeProject) === 'Word') {
      first = ` ${firstData.value} `;
    }
    if (getTitleForBox(type, true, typeProject) === 'Sentence') {
      first = paragrathReady;
    }
    if (getTitleForBox(type, false, typeProject) === 'Entity') {
      let entityName = '';
      secondData.forEach((e, index) => {
        if (index === firstData.length - 1) {
          entityName = entityName + ` ${e.name} `
        } else {
          entityName = entityName + ` ${e.name} and`
        }
      })
      second = entityName;
    }
    if (getTitleForBox(type, false, typeProject) === 'Principle') {
      let principles = '';
      let containsValue = secondData.some(obj => obj.type === 'derived');
      if (containsValue) {
        principles = getSentenceString(principle);
      } else {
        secondData.forEach((e, index) => {
          if (e.type === 'identify') {
            if (index === secondData.length - 1) {
              principles = principles + `principles ${index + 1} `
            } else {
              principles = principles + `principles ${index + 1} and `
            }
          }

        })
      }
      second = principles;
    }
    if (getTitleForBox(type, false, typeProject) === 'Word') {
      second = ` ${secondData.value} `;
    }
    if (getTitleForBox(type, false, typeProject) === 'Sentence') {
      second = paragrathReady;
    }
    if (thirdData) {
      if (thirdtype === 'Word') {
        third = ` ${thirdData.value} `;
      } else if (thirdtype === 'String') {
        third = ` ${thirdData} `
      } else if (thirdtype === 'Principle-relation') {
        third = ` ${thirdData} `
      }
      else {
        third = ` ${thirdData.name} `;
      }
    }
  }
  return (
    <div id='yes-no-question'>
      <div className='answer-box'>
        <input disabled={setAnswer == null ? true : false} checked={asnwer} defaultChecked={asnwer} onChange={(e) => { if (setAnswer !== null) { setAnswer(true) } }} value={asnwer} type="radio" name={name ? name : 'answer'} />
        <h1> <span className='green'>Yes</span>, {' '}{first} <span style={{ color: '#1BC000' }}> is related to </span> {' '}{second} {!third ? '' : <><span style={{ color: '#1BC000' }}> by </span>{third}</>}</h1>
      </div>
      <div className='answer-box'>
        <input disabled={setAnswer == null ? true : false} checked={!asnwer} defaultChecked={!asnwer} onChange={(e) => { if (setAnswer !== null) { setAnswer(false) } }} value={!asnwer} type="radio" name={name ? name : 'answer'} />
        <h1><span className='red'>No</span>, { } {first} <span style={{ color: 'red' }}> is not related to </span> {' '}{second}  {!third ? '' : <><span style={{ color: 'red' }}> by </span>{third}</>}</h1>
      </div>
    </div>
  )
}
