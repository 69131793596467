import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getVerificationsAPI } from '../../api/verifications';
import { projectType } from '../../enum/enum';
import { verificationTypeEnum } from '../../enum/verification';
import { showAlert } from '../../functions/show_alert';
import { showAlertWithText } from '../../functions/show_alert_with_text';
import { requestState } from '../../global/http_enum';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import ResponseHttp from '../../widgets/response_http/response_http';
import ShowVerificationMobile from './show_verification_mobile/show_verification_moile';
import './verifications_screen.css';
import VerificationTitle from './verifications_title/verification_title';
import VerificationCreate from './verification_create/verification_create';
import VerificationElements from './verification_element/verification_element';
export default function VerificationsScreen() {
    const selectedProject = useSelector(state => state.projectSlice.projectSelected)
    const [verificationType, SetVerificationType] = useState(verificationTypeEnum.pointto);
    const [pickedVerification, SetPickedVerification] = useState(null);
    const [createVerification, SetCreateVerifications] = useState(0);
    const [verifications, SetVerifications] = useState();
    const [response, SetResponse] = useState(requestState.initial);
    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (!e) {
                return;
            }
            if (!e.target) {
                return;
            }
            if (e.target.innerWidth > 768) {
                SetCreateVerifications(state => {
                    if (state === 2) {
                        if (e.target.innerWidth > 768) {
                            return 0
                        } else {
                            return 2
                        }
                    }
                })
                if (createVerification === 2) {
                    SetCreateVerifications(0);
                }
            }
        });
        getVerificationsAPI(selectedProject.id).then((value) => {
            if (value.statusCode === 200) {
                SetVerifications(value.response);
                SetResponse(requestState.haveData);
            } else if (value.statusCode === 401) {
                SetResponse(requestState.invalidTokne);
            } else if (value.statusCode === 505) {
                SetResponse(requestState.internetConnection);
            } else {
                SetResponse(requestState.serverError);
            }
        });
        if (selectedProject.project_type === projectType.sentenceType) {
            SetVerificationType(verificationTypeEnum.pointto)
        } else {
            SetVerificationType(verificationTypeEnum.sentencepointto)
        }
    }, [selectedProject])
    if (selectedProject.entity.length < 1) {
        showAlertWithText('Naturally, when we communicate the words that we use identify entities we are talking about.  When we identify an entity by a word, we simply perform a verification.  If no, entity is identified, there is not verification.  Please, go back and identify entities.');
        return <></>
    }
    if (selectedProject.word.length < 1) {
        showAlert(false, 'identfy words first ');
        return <Navigate to={'/sentence'} replace={true} />
    }
    if (response === requestState.haveData) {
        if (createVerification === 1) {
            return <div id='verification-screen'>
                <AppBarWidget />
                <VerificationTitle verifications={verifications} typeProject={selectedProject.project_type} SetCreateVerifications={SetCreateVerifications} verificationType={verificationType} SetVerificationType={SetVerificationType} />
                <VerificationCreate SetCreateVerifications={SetCreateVerifications} SetVerificationType={SetVerificationType} verificationType={verificationType} SetVerifications={SetVerifications} />
            </div>
        } else if (createVerification === 2) {
            return <>
                <AppBarWidget />
                <ShowVerificationMobile  type={selectedProject.project_type} verifications={verifications} pickedVerification={pickedVerification} SetCreateVerifications={SetCreateVerifications} />
            </>
        }
        else {
            return <div id='verification-screen'>
                <AppBarWidget />
                <VerificationTitle verifications={verifications} typeProject={selectedProject.project_type} SetCreateVerifications={SetCreateVerifications} verificationType={verificationType} SetVerificationType={SetVerificationType} />
                <VerificationElements  SetVerificationType={SetVerificationType} typeProject={selectedProject.project_type} SetCreateVerifications={SetCreateVerifications} SetPickedVerification={SetPickedVerification} verification={verifications} verificationType={verificationType} />
            </div>
        }
    } else {
        return <>
            <AppBarWidget />
            <ResponseHttp response={response} />
        </>
    }
}





