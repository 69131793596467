import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";

const getInfromationFlow = async (id) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.getOrCreateInformationFlow}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}

const createInformationFlowAPI = async (id, sentence, entity1, entity2, pointTo, naturallyIdentified, definedBy, areRelatedTo, flowTo, type) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.getOrCreateInformationFlow}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'sentence': sentence,
                'entity1': entity1,
                'entity2': entity2,
                'pointTo': pointTo,
                'naturallyIdentified': naturallyIdentified,
                'definedBy': definedBy,
                'areRelatedTo': areRelatedTo,
                'flowTo': flowTo,
                'type': type,
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const EditeFlowInformationAPI = async (id, flowID, sentence, entity1, entity2, pointTo, naturallyIdentified, definedBy, areRelatedTo, flowTo, type) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.getOrCreateInformationFlow}/${id}/${flowID}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'sentence': sentence,
                'entity1': entity1,
                'entity2': entity2,
                'pointTo': pointTo,
                'naturallyIdentified': naturallyIdentified,
                'definedBy': definedBy,
                'areRelatedTo': areRelatedTo,
                'flowTo': flowTo,
                'type': type,
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const deleteFlowInfoAPI = async (flowID) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.deleteInfromationFlow}/${flowID}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
export { getInfromationFlow, createInformationFlowAPI, EditeFlowInformationAPI, deleteFlowInfoAPI };