import React from 'react'
import { getFirstTypeOfVerification, typeIsNotRelated, verificationToMapMoile } from '../../../enum/verification';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import EntityWidgetForAllType from '../../../widgets/entity_widget/entity_widget';
import { getSentenceString, settingsSlider } from '../../../global/utils';
import { projectType } from '../../../enum/enum';
import { showAlertWithTextGlobal } from '../../../functions/show_alert_with_text_global';
export default function ShowVerificationMobile({ pickedVerification, SetCreateVerifications, verifications ,type}) {
    const filtredVerifications = verifications.filter(item => item.type === pickedVerification || `Not${pickedVerification}` === item.type);

    if (filtredVerifications.length === 0) {
        return <div id='mobile-verification-emty'>
            <h1 className='text'>This type of verification is Empty</h1>
            <div onClick={() => SetCreateVerifications(1)} className="create-now">Create</div>
            <div onClick={() => SetCreateVerifications(0)} className="back">Back</div>
        </div>
    } else {
        return <>
            {filtredVerifications.map(e => {
                return (
                    <div id='create-verification'>
                        <div className="create-verification-holder">
                            <div className="top-verification-create">
                                <div className="word-time">
                                    <div className="word">{type === projectType.sentenceType ? ' Word' : getFirstTypeOfVerification(e.type)}</div>
                                    <div className="time"></div>
                                </div>
                                <div className="entity">{type === projectType.sentenceType ? ' Entity' : 'Entities '}</div>
                            </div>
                            <div className="create-verification-content">
                                <div onClick={()=> showAlertWithTextGlobal(e.word)} className="word">
                                    {getSentenceString(e.word)}
                                </div>
                                <div className="type">
                                    <h1>{verificationToMapMoile(e.type,type)}</h1>
                                    <div className='line-arrow'>
                                        <div className="line"></div>
                                        <ArrowForwardIosIcon className="arrow" />
                                        {typeIsNotRelated(e.type) === true ? <CloseIcon className='not-related' /> : <></>}
                                    </div>
                                </div>
                                {e.entity.length !== 1 ? <>
                                    <div className={`${e.entity.length !== 1 ? "entity" : "one-entity"}`}>
                                        <Slider  {...settingsSlider}>
                                            {e.entity.map((e) =>
                                                <EntityWidgetForAllType entity={e} />
                                            )}
                                        </Slider>
                                    </div>
                                </> : <>
                                    <div className={"one-entity"}>
                                        <EntityWidgetForAllType entity={e.entity[0]} />
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                )
            })}
            <div onClick={() => SetCreateVerifications(0)} className="verification-sho-back">Back Button</div>

        </>

    }
}
