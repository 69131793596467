const showAlertWithTextGlobal = (text, title) => {
    const alert = document.getElementById('Alert_with_text_global');
    const textPlace = document.getElementById('text-in-alert-global');
    textPlace.innerHTML = '';
    // If title is provided, create a title element and append it
    if (title) {
        const titleElement = document.createElement('h2');
        titleElement.textContent = title;
        titleElement.style.color = '#00A14C';
        titleElement.style.marginBottom = '10px';
        textPlace.appendChild(titleElement);
    }
    alert.style.display = 'block'
    // Append the main text
    const textElement = document.createElement('p'); // Use 'p' or any other suitable tag for text
    textElement.innerHTML = text;
    textPlace.appendChild(textElement);
}
const closeAlertWithTextGlobal = () => {
    const alert = document.getElementById('Alert_with_text_global');
    const textPlace = document.getElementById('text-in-alert-global');
    alert.style.display = 'none';
    textPlace.textContent = '';

}

export { showAlertWithTextGlobal, closeAlertWithTextGlobal }