import React, { useEffect } from 'react'
import './create_relation_element.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import ArrowRelation from '../../../assets/relation-mobile.png'
import { getboxTileForBoxRelation, getTitleForBox } from '../../../enum/relationship';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../../functions/show_alert';
import { CreateRelationShipsAPI } from '../../../api/relationship';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { cookiesGlobal } from '../../../App';
import YesNoQuestion from '../yes_no_question/yes_no_question';
import AlertWithEntity from '../../../widgets/alert_with_entity/alert_with_entity';
import CreationContent from '../../../widgets/content/content_hundler';
import CloseIcon from '@mui/icons-material/Close';
import { projectType } from '../../../enum/enum';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSentenceString, settingsSlider } from '../../../global/utils';
import PrincipleAlertWidget from './widgets/principle_alert';
import { showAlertWithTextGlobal } from '../../../functions/show_alert_with_text_global';
export default function CreateRelationElement({ SetCreateRelationship, type, principle, SetRelationShip, relationShip, typeProject }) {
    const navigate = useNavigate();
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    const [answer, SetAnswer] = useState(true);
    const [firstContent, SetFirstContent] = useState([]);
    const [secondContent, SetSecondContent] = useState([]);
    const [thirdContent, SetthirdContent] = useState(null);
    const [thirdType, SetThirdType] = useState(null);
    const [showElementPicker, setShowElementPicker] = useState('none');
    const [showPricnipleList, SetShowPrincipleList] = useState(false);

    useEffect(() => {
        SetFirstContent([]);
        SetSecondContent([]);
        SetthirdContent(null);
        SetThirdType(null);
        if (getTitleForBox(type, true, typeProject) === 'Sentence') {
            SetFirstContent(projectSelected.sentence)
        } else if (getTitleForBox(type, true, typeProject) === 'Principle') {
            const activated = principle.principle.filter((obj) => obj.value === true);
            SetFirstContent(activated)
        }
        if (getTitleForBox(type, false, typeProject) === 'Sentence') {
            SetSecondContent(projectSelected.sentence)
        } else if (getTitleForBox(type, false, typeProject) === 'Principle') {
            const activated = principle.principle.filter((obj) => obj.value === true);
            SetSecondContent(activated)
        }
    }, [type, principle, projectSelected.sentence, typeProject]);

    const hundleAlertElement = (first) => {
        if (first) {
            if (getTitleForBox(type, true, typeProject) === 'Word' || getTitleForBox(type, true, typeProject) === 'Entity') {
                setShowElementPicker('first')
            }
        } else {
            if (getTitleForBox(type, false, typeProject) === 'Word' || getTitleForBox(type, false, typeProject) === 'Entity') {
                setShowElementPicker('second')
            }
        }


    }
    const SaveNewRelationShips = async () => {
        if (!firstContent || firstContent.length === 0) {
            showAlert(false, 'Complete The Relations First')
            return;
        }
        if (!secondContent || secondContent.length === 0) {
            showAlert(false, 'Complete The Relations First')
            return;
        }
        if (thirdType === 'String') {
            if (thirdContent === null || thirdContent === '') {
                showAlert(false, 'Complete Verification First')
                return;
            }
        }

        if (thirdContent) {
            navigate('/relationship-explanation', {
                state: {
                    id: projectSelected.id,
                    type,
                    firstContent,
                    secondContent,
                    thirdContent,
                    thirdType, answer,
                }
            })
            return;
        }

        showProgressAlert();
        const response = await CreateRelationShipsAPI(projectSelected.id, type, firstContent, secondContent, thirdContent, thirdType, answer, '',);
        HideProgressAlert();
        if (response.statusCode === 200) {
            SetCreateRelationship(false);
            SetRelationShip({
                'principle': principle,
                'relation': [...relationShip, {
                    'id': response.response.id,
                    'type': response.response.type,
                    'first': response.response.first,
                    'second': response.response.second,
                    'third': response.response.third,
                    'third_type': response.response.third_type,
                    'answer': response.response.answer,
                }]
            });
            showAlert(true, `Relation created successfully `);
        } else if (response.statusCode === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        } else {
            showAlert(false, response.response);
        }

    }
    const addthirdOne = (type) => {
        if (type === 'Principle-relation') {
            return;
        }
        SetThirdType(type);
        if (type !== 'String') {
            setShowElementPicker('third');
        }
    }
    const addThird = (e) => {
        SetthirdContent(e)
        setShowElementPicker('none');
    }
    const saveEntity = (e) => {

        if (showElementPicker === 'first' && getTitleForBox(type, true, typeProject) === 'Word') {
            SetFirstContent(e);
            setShowElementPicker('none');
            return;
        }
        if (showElementPicker === 'second' && getTitleForBox(type, false, typeProject) === 'Word') {
            SetSecondContent(e);
            setShowElementPicker('none');
            return;
        }
        if (showElementPicker === 'third') {
            SetthirdContent(e);
            setShowElementPicker('none');
            return;
        } else {
            if (showElementPicker === 'third') {
                SetthirdContent(e);
                setShowElementPicker('none');
                return;
            }
            if (typeProject === projectType.sentenceType) {
                if (showElementPicker === 'first') {
                    SetFirstContent([e]);
                } if (showElementPicker === 'second') {
                    SetSecondContent([e]);
                }
                setShowElementPicker('none');
                return;
            } else {
                if (showElementPicker === 'first') {
                    SetFirstContent(state => {
                        var exist = false;
                        for (var i = 0; i < state.length; i++) {
                            if (state[i].value === e.value) {
                                exist = true;
                            }
                        }
                        if (exist) {
                            const newList = state.filter((item) => item.value !== e.value);
                            return newList;
                        } else {
                            return [...state, e];
                        }
                    });
                } if (showElementPicker === 'second') {
                    SetSecondContent(state => {
                        var exist = false;
                        for (var i = 0; i < state.length; i++) {
                            if (state[i].value === e.value) {
                                exist = true;
                            }
                        }
                        if (exist) {
                            const newList = state.filter((item) => item.value !== e.value);
                            return newList;
                        } else {
                            return [...state, e];
                        }
                    });
                }
            }
        }

    }
    const hundleSelectPrincipleAsResult = () => {
        const havePrincipleDrived = principle.principle.some(element => element.type === "derived" && element.value === true);
        if (havePrincipleDrived) {
            SetthirdContent(getSentenceString(principle.explanation))
            SetThirdType('Principle-relation');
        } else {
            SetShowPrincipleList(true);
        }

    }
    const hundlePickPrinciple = (e) => {
        SetThirdType('Principle-relation');
        SetthirdContent(e)
        SetShowPrincipleList(false);
    }
    return (
        <div id='create-relation-element'>
            <h1 className='title-create'>Create Relationsips <HighlightOffIcon onClick={() => SetCreateRelationship(false)} className='close' /></h1>
            <div className="relation-box">
                <div className="box-holder">
                    <div className={`box show`}>
                        <div className="title">{getboxTileForBoxRelation(type, true, typeProject)}</div>
                        <div onClick={(e) => {
                            if (e.target.tagName.toLowerCase() !== 'button') {
                                hundleAlertElement(true)
                            }
                        }} className="content">
                            <ContentWidget principle={principle} content={firstContent} sentence={projectSelected.sentence} type={type} first={true} typeProject={typeProject} />
                        </div>
                    </div>
                    <div className={`box ${firstContent !== null && firstContent.length !== 0 ? 'show' : ''}`}>
                        <div className="title">{getboxTileForBoxRelation(type, false, typeProject)}</div>
                        <div onClick={(e) => {
                            if (e.target.tagName.toLowerCase() !== 'button') {
                                hundleAlertElement(false)
                            }
                        }} className="content">
                            <ContentWidget principle={principle} content={secondContent} sentence={projectSelected.sentence} type={type} first={false} typeProject={typeProject} />
                        </div>
                    </div>
                </div>
                <div className={`arrow ${firstContent !== null && firstContent.length !== 0 && secondContent !== null && secondContent.length !== 0 ? 'show' : ''}`}>
                    <img src={ArrowRelation} alt=" " />
                    {answer === false ? <CloseIcon className='notrelated' /> : <></>}
                </div>
                {secondContent !== null && secondContent.length !== 0 && firstContent !== null && firstContent.length !== 0 ? thirdType !== null ? <div className="third-entity">
                    <div className="title">Result</div>
                    <div onClick={() => { if (thirdType === 'Principle-relation') { hundleSelectPrincipleAsResult() } else { addthirdOne(thirdType) } }} className="content">
                        {thirdType === 'String' ?
                            <input value={thirdContent} onChange={(e) => SetthirdContent(e.target.value)} type="text" />
                            : thirdType === 'Principle-relation' ? <div onClick={() => showAlertWithTextGlobal(thirdContent)}>{thirdContent}</div>
                                : <CreationContent principle={principle} content={thirdContent} sentence={projectSelected.sentence} type={thirdType} />}
                    </div>
                </div> :
                    <div className='buttons-type'>
                        <div onClick={() => addthirdOne('Word')} className="addthird-button">{typeProject === projectType.sentenceType ? 'Add Word' : 'Add Sentence'} </div>
                        <div onClick={() => addthirdOne('Entity')} className="addthird-button">Add Entity</div>
                        <div onClick={() => addthirdOne('String')} className="addthird-button">{typeProject === projectType.sentenceType ? ' New Word' : 'New Sentence'}</div>
                        {principle.principle ? <div onClick={() => hundleSelectPrincipleAsResult()} className="addthird-button">Principle</div> : <></>}
                    </div> : <></>}
            </div>
            <YesNoQuestion asnwer={answer} firstData={firstContent} secondData={secondContent} setAnswer={SetAnswer} thirdData={thirdContent} typeProject={typeProject} thirdtype={thirdType} type={type} principle={principle.explanation} />
            <div onClick={() => SaveNewRelationShips()} className="save-relation-buttom">
                Save
            </div>
            {showPricnipleList ? <PrincipleAlertWidget onClickPrinciple={hundlePickPrinciple} /> : <></>}
            {showElementPicker === 'first' ? <AlertWithEntity type={getTitleForBox(type, true, typeProject)} closeAlert={setShowElementPicker} setData={saveEntity} EntitysSelected={firstContent} typeProject={typeProject} /> : <></>}
            {showElementPicker === 'second' ? <AlertWithEntity type={getTitleForBox(type, false, typeProject)} closeAlert={setShowElementPicker} setData={saveEntity} EntitysSelected={secondContent} typeProject={typeProject} /> : <></>}
            {showElementPicker === 'third' ? <AlertWithEntity type={thirdType} closeAlert={setShowElementPicker} setData={addThird} EntitysSelected={[]} typeProject={typeProject} /> : <></>}
        </div >
    )
}
const ContentWidget = ({ principle, content, sentence, type, first, typeProject }) => {
    if (getTitleForBox(type, first, typeProject) === 'Entity' && Array.isArray(content)) {
        if (typeProject === projectType.sentenceType) {
            return content.map((e) => <CreationContent principle={principle} content={e} sentence={sentence} type={getTitleForBox(type, first, typeProject)} />)
        } else {
            return <div className="holder">
                <Slider {...settingsSlider}>
                    {content.map((e) => <CreationContent principle={principle} content={e} sentence={sentence} type={getTitleForBox(type, first, typeProject)} />)}
                </Slider>
            </div>
        }

    } else {
        return <CreationContent principle={principle} content={content} sentence={sentence} type={getTitleForBox(type, first, typeProject)} />
    }
}


