import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { DeleteFlowAPI, getFlowAPI } from '../../api/flow';
import { requestState } from '../../global/http_enum';
import AppBarWidget from '../../widgets/app_bar/app_bar';
import ResponseHttp from '../../widgets/response_http/response_http';
import CreateFlowScreen from './create_flow/create_flow';
import './flow.css';
import FlowBoxElement from './flow_box/flow_box';
import FlowTitle from './flow_tittle/flow_title';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link, useNavigate } from 'react-router-dom';
import { showAlertWithText } from '../../functions/show_alert_with_text';
import { HideProgressAlert, showProgressAlert } from '../../functions/show_progress_page';
import { showAlert } from '../../functions/show_alert';
import { cookiesGlobal } from '../../App';
export default function FlowScreen() {
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    const [createFlow, SetCreateFlow] = useState(0);
    const [pickedFlow, SetPickedFlow] = useState();
    const [response, SetResponse] = useState(requestState.initial);
    const [Flow, SetFlow] = useState();
    useEffect(() => {
        getFlowAPI(projectSelected.id).then((value) => {
            if (value.statusCode === 200) {
                SetFlow(value.response);
                SetResponse(requestState.haveData);
            } else if (value.statusCode === 401) {
                SetResponse(requestState.invalidTokne);
            } else if (value.statusCode === 505) {
                SetResponse(requestState.internetConnection);
            } else {
                SetResponse(requestState.serverError);
            }
        });
    }, [projectSelected]);
    if (projectSelected.entity.length < 1) {
        showAlertWithText("When we communicate to each other, let’s assume that I repeat a sentence to you, the communication or the sentence flows from me to you.  In terms of entity identification, the words I used identified entities. In this case, we can think it as flow of entities.  If no entity is identified, then there is no flow of entities.  Please, identify at least two entities before showing flow of entities");
        return <></>
    }
    return (
        <>
            <AppBarWidget />
            <div id='flow-screen'>
                <FlowTitle SetCreateFlow={SetCreateFlow} createFlow={createFlow} />
                {projectSelected.entity.length < 1 ? <div className="flow-message"> When we communicate to each other, let’s assume that I repeat a sentence to you, the communication or the sentence flows from me to you.  In terms of entity identification, the words I used identified entities. In this case, we can think it as flow of entities.  If no entity is identified, then there is no flow of entities.  Please, identify entities before showing flow of entities.  <div><Link to={'/identify-entity'}>Identified Entity</Link></div></div>
                    : <FlowView pickedFlow={pickedFlow} SetPickedFlow={SetPickedFlow} createFlow={createFlow} SetFlow={SetFlow} SetCreateFlow={SetCreateFlow} response={response} Flow={Flow} />}
            </div>
        </>
    )
}

const FlowView = ({ response, createFlow, Flow, SetCreateFlow, SetFlow, SetPickedFlow, pickedFlow, picked, SetPicked }) => {
    const navigate = useNavigate();
    const [pickedDelete, SetPickedDelete] = useState(-1);
    const DeleteFlow = async (index) => {
        SetPickedDelete(-1);
        showProgressAlert();
        const response = await DeleteFlowAPI(pickedDelete);
        HideProgressAlert();
        if (response.statusCode <= 300) {
            const updatedItems = Flow.filter(item => item.id !== index);
            SetFlow(updatedItems);
            showAlert(true, 'Flow Deleted');
        } else if (response.statusCode === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        } else {
            showAlert(false, response.response);
        }

    }
    if (response === requestState.haveData) {
        if (createFlow === 1) {
            return <CreateFlowScreen SetFlow={SetFlow} SetCreateFlow={SetCreateFlow} />
        } else if (createFlow === 2) {
            return <CreateFlowScreen SetFlow={SetFlow} flowID={pickedFlow.id} SetCreateFlow={SetCreateFlow} editing={true} entity={pickedFlow.entity} string={pickedFlow.string} flows={Flow} />
        } else {
            return <div className="flow-elements-holder">
                {Flow.map((e, index) => <FlowBoxElement SetPickedDelete={SetPickedDelete} key={index} index={index} flow={e} SetCreateFlow={SetCreateFlow} SetPickedFlow={SetPickedFlow} />)}
                <div className="create-flow-element">
                    <h1 className='create-flow-element-top'>Create Flow</h1>
                    <div className='create-flow-element-bottom'><AddCircleIcon onClick={() => SetCreateFlow(1)} className='icon' /></div>
                </div>
                {pickedDelete === -1 ? <></> : <div onClick={() => SetPickedDelete(-1)} id="delete-item">
                    <div className="alert-mesg">
                        <h1>Are you sure You want to delete This Flow ?</h1>
                        <div className="buttons">
                            <div onClick={() => SetPickedDelete(-1)} className="bt close">Close</div>
                            <div onClick={() => DeleteFlow(pickedDelete)} className="bt done">Confirm</div>
                        </div>
                    </div>
                </div>
                }
            </div>
        }

    } else {
        return <ResponseHttp response={response} />
    }
}
