const showFullAlertWithMSG = (msg) => {
    const alert = document.getElementById('show-full-alert-widget');
    const text = document.getElementById('text-for-full-alert-widget');
    alert.style.display = 'flex';
    text.innerText = msg;
}

const closeFullAlertMSG = () => {
    const alert = document.getElementById('show-full-alert-widget');
    alert.style.display = 'none';
}
export { showFullAlertWithMSG, closeFullAlertMSG };