import { projectType } from "./enum";


const verificationTypeEnum = {
    pointto: 'pointto',
    naturallyidentified: 'naturallyidentified',
    visuallyidentified: 'visuallyidentified',
    identify: 'identify',
    defined: 'defined',
    none: 'none',
    notpointto: 'Notpointto',
    notnaturallyidentified: 'Notnaturallyidentified',
    notvisuallyidentified: 'Notvisuallyidentified',
    notidentify: 'Notidentify',
    notdefined: 'Notdefined',

    sentencepointto: 'sentencepointto',
    parapointto: 'parapointto',
    sentenceVI: 'sentenceVI',
    paraVI: 'paraVI',
    sentenceNI: 'sentenceNI',
    paraNI: 'paraNI',
    wordpointToentity: 'paPointTo',
    wordnotpointToentity: 'NotpaPointTo',
    notsentencepointto: 'Notsentencepointto',
    notparapointto: 'Notparapointto',
    notsentenceVI: 'NotsentenceVI',
    notparaVI: 'NotparaVI',
    notsentenceNI: 'NotsentenceNI',
    notparaNI: 'NotparaNI',
}

const typeIsNotRelated = (type) => {
    return type.includes("Not");
}

const verificationToMapMoile = (type, typeProject) => {
    if (typeProject === projectType.sentenceType) {
        if (type === verificationTypeEnum.pointto) {
            return 'Point to';
        }
        if (type === verificationTypeEnum.naturallyidentified) {
            return 'naturally identified';
        }
        if (type === verificationTypeEnum.visuallyidentified) {
            return 'visually identified';
        }
        if (type === verificationTypeEnum.identify) {
            return 'identify';
        }
        if (type === verificationTypeEnum.defined) {
            return 'defined by';
        }
        if (type === verificationTypeEnum.notpointto) {
            return 'not Point to';
        }
        if (type === verificationTypeEnum.notnaturallyidentified) {
            return 'not naturally identified';
        }
        if (type === verificationTypeEnum.notvisuallyidentified) {
            return 'not visually identified';
        }
        if (type === verificationTypeEnum.notidentify) {
            return 'not identify';
        }
        if (type === verificationTypeEnum.notdefined) {
            return 'not defined by';
        }
        else {
            return 'none';
        }
    } else {
        if (type === verificationTypeEnum.sentencepointto) {
            return 'Point to';
        }
        if (type === verificationTypeEnum.parapointto) {
            return 'Point to';
        }
        if (type === verificationTypeEnum.wordpointToentity) {
            return 'Point to';
        }
        if (type === verificationTypeEnum.wordnotpointToentity) {
            return 'Not Point to';
        }
        if (type === verificationTypeEnum.sentenceVI) {
            return 'visually identified';
        }
        if (type === verificationTypeEnum.paraVI) {
            return 'visually identified';
        }
        if (type === verificationTypeEnum.sentenceNI) {
            return 'naturally identified';
        }
        if (type === verificationTypeEnum.paraNI) {
            return 'naturally identified';
        }
        if (type === verificationTypeEnum.notsentencepointto) {
            return 'not Point to';
        }
        if (type === verificationTypeEnum.notparapointto) {
            return 'not Point to';
        }
        if (type === verificationTypeEnum.notsentenceVI) {
            return 'not visually identified';
        }
        if (type === verificationTypeEnum.notparaVI) {
            return 'not visually identified';
        }
        if (type === verificationTypeEnum.notsentenceNI) {
            return 'not naturally identified';
        }
        if (type === verificationTypeEnum.notparaNI) {
            return 'not naturally identified';
        }
        else {
            return 'none';
        }
    }

}

const AnswerTheQuestion = (type, answer) => {
    if (answer === true) {
        return type;
    }
    if (type === verificationTypeEnum.defined) {
        return verificationTypeEnum.notdefined
    }
    if (type === verificationTypeEnum.naturallyidentified) {
        return verificationTypeEnum.notnaturallyidentified
    }
    if (type === verificationTypeEnum.visuallyidentified) {
        return verificationTypeEnum.notvisuallyidentified
    }
    if (type === verificationTypeEnum.identify) {
        return verificationTypeEnum.notidentify;
    }
    if (type === verificationTypeEnum.pointto) {
        return verificationTypeEnum.notpointto;
    }
    // paragraph type
    if (type === verificationTypeEnum.sentencepointto) {
        return verificationTypeEnum.notsentencepointto
    }
    if (type === verificationTypeEnum.parapointto) {
        return verificationTypeEnum.notparapointto
    }
    if (type === verificationTypeEnum.sentenceVI) {
        return verificationTypeEnum.notsentenceVI
    }
    if (type === verificationTypeEnum.paraVI) {
        return verificationTypeEnum.notparaVI;
    }
    if (type === verificationTypeEnum.sentenceNI) {
        return verificationTypeEnum.notsentenceNI;
    }
    if (type === verificationTypeEnum.paraNI) {
        return verificationTypeEnum.notparaNI;
    }
    if (type === verificationTypeEnum.wordpointToentity) {
        return verificationTypeEnum.wordnotpointToentity;
    }

}

const VerificationtypeToText = (type, typeProject) => {
    if (typeProject === projectType.sentenceType) {
        if (type === verificationTypeEnum.naturallyidentified) {
            return 'Word naturally identified Entity';
        }
        if (type === verificationTypeEnum.visuallyidentified) {
            return 'Word visually identified Entity';
        }
        if (type === verificationTypeEnum.identify) {
            return 'Word identify Entity';
        }
        if (type === verificationTypeEnum.defined) {
            return 'Word defined by Entity';
        }
        if (type === verificationTypeEnum.pointto) {
            return 'Word point to Entity';
        } else {
            return 'none';
        }
    } else {
        if (type === verificationTypeEnum.sentencepointto) {
            return 'Sentence point to Entities';
        }
        if (type === verificationTypeEnum.parapointto) {
            return 'Paragraph point to Entities';
        }
        if (type === verificationTypeEnum.sentenceVI) {
            return 'Sentence visually identified Entities';
        }
        if (type === verificationTypeEnum.paraVI) {
            return 'Paragraph visually identified Entities';
        }
        if (type === verificationTypeEnum.sentenceNI) {
            return 'Sentence naturally identified Entities';
        }
        if (type === verificationTypeEnum.paraNI) {
            return 'Paragraph naturally identified Entities';
        } if (type === verificationTypeEnum.wordpointToentity) {
            return 'Word point to Entities';
        } else {
            return 'none';
        }
    }

}

const getFirstTypeOfVerification = (type) => {

    if (type === verificationTypeEnum.sentencepointto) {
        return 'Sentence';
    }
    if (type === verificationTypeEnum.parapointto) {
        return 'Paragraph';
    }
    if (type === verificationTypeEnum.wordpointToentity) {
        return 'Word';
    }
    if (type === verificationTypeEnum.wordnotpointToentity) {
        return 'Word';
    }
    if (type === verificationTypeEnum.sentenceVI) {
        return 'Sentence';
    }
    if (type === verificationTypeEnum.paraVI) {
        return 'Paragraph';
    }
    if (type === verificationTypeEnum.sentenceNI) {
        return 'Sentence';
    }
    if (type === verificationTypeEnum.paraNI) {
        return 'Paragraph';
    }
    if (type === verificationTypeEnum.notsentencepointto) {
        return 'Sentence';
    }
    if (type === verificationTypeEnum.notparapointto) {
        return 'Paragraph';
    }
    if (type === verificationTypeEnum.notsentenceVI) {
        return 'Sentence';
    }
    if (type === verificationTypeEnum.notparaVI) {
        return 'Paragraph';
    }
    if (type === verificationTypeEnum.notsentenceNI) {
        return 'Sentence';
    }
    if (type === verificationTypeEnum.notparaNI) {
        return 'Paragraph';
    }
    else {
        return 'none';
    }
}


const getVerificationCountOfType = (type, verifications) => {
    const count = verifications.filter(obj => obj.type === type || obj.type === 'Not' + type);
    return count.length
}



export {
    verificationTypeEnum, verificationToMapMoile, VerificationtypeToText, AnswerTheQuestion, typeIsNotRelated, getVerificationCountOfType, getFirstTypeOfVerification
};