import React from 'react';
import './verification_title.css';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { verificationTypeEnum, VerificationtypeToText,getVerificationCountOfType } from '../../../enum/verification';
import { projectType } from '../../../enum/enum';
export default function VerificationTitle({ verificationType, SetVerificationType, SetCreateVerifications, typeProject, verifications }) {
    if (typeProject === projectType.sentenceType) {
        return (
            <div id='verification-title'>
                <div className="desktop">
                    <h1>Verification <AddCircleSharpIcon onClick={() => SetCreateVerifications(1)} className='icon' /></h1>
                    <div className="buttons">
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.pointto); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.pointto ? 'verification-button-active' : ''}`}>Word Point to Entity ({getVerificationCountOfType(verificationTypeEnum.pointto,verifications)})<span>Verify a word point to an entity</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.defined); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.defined ? 'verification-button-active' : ''}`}>Word Defined by Entity ({getVerificationCountOfType(verificationTypeEnum.defined,verifications)})<span>Verify a word is defined by an entity</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.identify); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.identify ? 'verification-button-active' : ''}`}>Word identify Entity ({getVerificationCountOfType(verificationTypeEnum.identify,verifications)})<span>Verify a word identifies entity</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.visuallyidentified); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.visuallyidentified ? 'verification-button-active' : ''}`}>Word Visually Identify Entity ({getVerificationCountOfType(verificationTypeEnum.visuallyidentified,verifications)})<span>Verify a word visually identified an entity</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.naturallyidentified); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.naturallyidentified ? 'verification-button-active' : ''}`}>Word Naturally Identify Entity ({getVerificationCountOfType(verificationTypeEnum.naturallyidentified,verifications)})<span>Verify a word naturally identified an entity</span></div>
                    </div>
                    <h1>{VerificationtypeToText(verificationType, typeProject)}</h1>
                </div>
                <div className="mobile">
                    <h1>Verification</h1>
                    <h3 style={{ cursor: 'pointer' }} onClick={() => SetCreateVerifications(1)}>Create new</h3>
                </div>
            </div>
        )
    } else {
        return (

            <div id='verification-title'>
                <div className="desktop">
                    <h1>Verification <AddCircleSharpIcon onClick={() => SetCreateVerifications(1)} className='icon' /></h1>
                    <div className="buttons">
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.sentencepointto); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.sentencepointto ? 'verification-button-active' : ''}`}>Sentence Point to Entities ({getVerificationCountOfType(verificationTypeEnum.sentencepointto,verifications)}) <span>Verify a Sentence point to an Entities</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.parapointto); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.parapointto ? 'verification-button-active' : ''}`}>paragraph Point to Entities ({getVerificationCountOfType(verificationTypeEnum.parapointto,verifications)})<span>Verify a paragraph Point to an Entities</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.sentenceNI); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.sentenceNI ? 'verification-button-active' : ''}`}>Sentence Naturally identified Entities ({getVerificationCountOfType(verificationTypeEnum.sentenceNI,verifications)})<span>Verify a Sentence naturally identified an Entities</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.paraNI); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.paraNI ? 'verification-button-active' : ''}`}>paragraph Naturally identified Entities ({getVerificationCountOfType(verificationTypeEnum.paraNI,verifications)})<span>Verify a paragraph naturally identified an Entities</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.sentenceVI); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.sentenceVI ? 'verification-button-active' : ''}`}>Sentence Visually identified Entities ({getVerificationCountOfType(verificationTypeEnum.sentenceVI,verifications)})<span>Verify a Sentence Visually identified an Entities</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.paraVI); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.paraVI ? 'verification-button-active' : ''}`}>paragraph Visually identified Entities ({getVerificationCountOfType(verificationTypeEnum.paraVI,verifications)})<span>Verify a paragraph Visually identified an Entities</span></div>
                        <div onClick={() => { SetVerificationType(verificationTypeEnum.wordpointToentity); SetCreateVerifications(0) }} className={`buttons-element ${verificationType === verificationTypeEnum.wordpointToentity ? 'verification-button-active' : ''}`}>Word Point to  Entities ({getVerificationCountOfType(verificationTypeEnum.wordpointToentity,verifications)})<span>Verify a Word Point to an Entities</span></div>
                    </div>
                    <h1>{VerificationtypeToText(verificationType, typeProject)}</h1>
                </div>
                <div className="mobile">
                    <h1>Verification</h1>
                    <h3 style={{ cursor: 'pointer' }} onClick={() => SetCreateVerifications(1)}>Create new</h3>
                </div>
            </div>
        )
    }

}


