import React from 'react'
import './relationship_box_widget.css';
export default function RelationShipBoxWidget({ widget, title }) {
    return (
        <div id='relationship_box_widget'>
            <div className="title-box">
                {title}
            </div>
            <div className="content-box">
                {widget}
            </div>
        </div>
    )
}
