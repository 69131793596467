import React, { useEffect, useState } from 'react';
import { projectType } from '../../../enum/enum';
import { getSentenceString } from '../../../global/utils';
import AlertWithEntity from '../../../widgets/alert_with_entity/alert_with_entity';
import EntityWidgetForAllType from '../../../widgets/entity_widget/entity_widget';
import RelationShipBoxWidget from '../../../widgets/relationship_box/relationship_box_widget';
import './create_information_flow.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { showAlertWithTextGlobal } from '../../../functions/show_alert_with_text_global'
import { showAlert } from '../../../functions/show_alert';
import { createInformationFlowAPI, EditeFlowInformationAPI } from '../../../api/information_flow';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import { cookiesGlobal } from '../../../App';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
export default function CreateFlowInformationScreen({ projectSelected, setCreateFlow, SetFlow, EditFlowInfo, flows, setEditFlow }) {
  const navigate = useNavigate();
  const [sentence, SetSentence] = useState();
  const [firstEntitiy, SetFirstEntity] = useState('');
  const [flowTo, SetFlowTo] = useState();
  const [showQuestion, SetShowQuestion] = useState(false);
  const [secondEntity, SetSecondEntity] = useState('');
  const [pickEntity, SetPickEntity] = useState('none');
  const [pointTo, SetPointTo] = useState();
  const [naturallyIdentified, SetNaturallyIdentified] = useState();
  const [definedBy, SetDefinedby] = useState();
  const [areRelatedTo, SetAreRelatedTo] = useState();
  const [changeType, SetChangeType] = useState(false);
  const [firstBoxType, SetFirstBoxType] = useState('Word');
  useEffect(() => {
    if (EditFlowInfo) {
      SetSentence(EditFlowInfo.sentence);
      if (checkIfMap(EditFlowInfo.sentence)) {
        SetSentence(EditFlowInfo.sentence)
        SetFirstBoxType(EditFlowInfo.sentence.type);
      } else {
        SetSentence(getSentenceString(projectSelected.sentence))
      }

      SetFirstEntity(EditFlowInfo.entity1);
      SetSecondEntity(EditFlowInfo.entity2);
      if (EditFlowInfo.pointTo === 0) {
        SetPointTo(false);
      } else {
        SetPointTo(true);
      }
      if (EditFlowInfo.naturallyIdentified === 0) {
        SetNaturallyIdentified(false);
      } else {
        SetNaturallyIdentified(true);
      }
      if (EditFlowInfo.definedBy === 0) {
        SetDefinedby(false);
      } else {
        SetDefinedby(true);
      }
      if (EditFlowInfo.areRelatedTo === 0) {
        SetAreRelatedTo(false);
      } else {
        SetAreRelatedTo(true);
      }
      if (EditFlowInfo.flowTo === 0) {
        SetFlowTo(false);
      } else {
        SetFlowTo(true);
      }
    } else {
      SetSentence(getSentenceString(projectSelected.sentence))
    }
  }, [projectSelected, EditFlowInfo]);
  const SetFirstEntityHundler = (e) => {
    if (pickEntity === 1) {
      SetFirstEntity(e)
    }
    if (pickEntity === 2) {
      SetSecondEntity(e);
      SetShowQuestion(true);
    }
    if (pickEntity === 3) {
      SetSentence(e);
    }
    SetPickEntity('none');
  }
  const onThirdEntitySelected = (answer) => {
    if (answer === true) {
      SetFlowTo(true)
    } else {
      SetFlowTo(false)
    }
    SetShowQuestion(false);
  }
  const onSaveButtonPressed = async () => {
    if (EditFlowInfo) {
      EditFlowFunction();
      return;
    }
    if (firstEntitiy === '' || secondEntity === '') {
      showAlert(false, 'pick the Entites !');
      return;
    }
    if (pointTo === undefined || naturallyIdentified === undefined || definedBy === undefined || areRelatedTo === undefined || flowTo === undefined) {
      showAlert(false, 'answer the Questions before saving the Flow !');
      return;
    }
    const type = firstBoxType === 'Word' ? 'Word' : sentence.type;
    showProgressAlert()
    const response = await createInformationFlowAPI(
      projectSelected.id,
      sentence,
      firstEntitiy,
      secondEntity,
      pointTo,
      naturallyIdentified,
      definedBy,
      areRelatedTo,
      flowTo,
      type,
    );
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'Flow Created');
      SetFlow(state => [...state, response.response]);
      setCreateFlow(false);
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }
  }
  const EditFlowFunction = async () => {
    if (firstEntitiy === '' || secondEntity === '') {
      showAlert(false, 'pick the Entites !');
      return;
    }
    if (pointTo === undefined || naturallyIdentified === undefined || definedBy === undefined || areRelatedTo === undefined) {
      showAlert(false, 'answer the Questions before saving the Flow !');
      return;
    }
    showProgressAlert()
    const flowID = EditFlowInfo.id;
    const type = firstBoxType === 'Word' ? 'Word' : sentence.type;
    const response = await EditeFlowInformationAPI(
      projectSelected.id,
      flowID,
      sentence,
      firstEntitiy,
      secondEntity,
      pointTo,
      naturallyIdentified,
      definedBy,
      areRelatedTo,
      flowTo,
      type,
    );
    HideProgressAlert();
    if (response.statusCode === 200) {
      showAlert(true, 'Flow Updated');
      const newFlowList = flows;
      const index = newFlowList.findIndex((obj) => obj.id === flowID);
      if (index !== -1) {
        newFlowList.splice(index, 1);
      }
      newFlowList.push(response.response);
      SetFlow(newFlowList);
      setCreateFlow(false);
      setEditFlow();
    } else if (response.statusCode === 401) {
      cookiesGlobal.remove('auth')
      cookiesGlobal.remove('email')
      navigate('/authentication', { replace: true });
      return;
    } else {
      showAlert(false, response.response);
    }
  }
  const changeTypeOfFirstEntity = (e) => {
    if (e === 'Word') {
      SetSentence(getSentenceString(projectSelected.sentence));
      SetFirstBoxType()
    } else {
      SetSentence();
    }
    SetFirstBoxType(e);
    SetChangeType(false);
  }
  return (
    <>
      <div id='create-flow-informations'>
        <div className="first-box-information">
          <div className="header"><div>{firstEntitiy === '' ? <> Information from Actual Entity </> : <>Information from {firstEntitiy.name} </>} </div> <div className='change-type' onClick={() => SetChangeType(true)}><MenuIcon /></div> </div>
          <div className="bottom">
            <div onClick={() => { if (firstBoxType === 'Word') { showAlertWithTextGlobal(sentence, `${projectSelected.project_type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} ${firstEntitiy === '' ? '' : 'from ' + firstEntitiy.name}`); } else { SetPickEntity(3) } }} className='sentence-flow'>
              {firstBoxType === 'Word' ? <RelationShipBoxWidget title={projectSelected.project_type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} widget={sentence} /> : <RelationShipBoxWidget title={firstBoxType} widget={<EntityWidgetForAllType entity={sentence} />} />}
            </div>
            <div onClick={() => SetPickEntity(1)} className='entity-flow'><EntityWidgetForAllType entity={firstEntitiy} /></div>
          </div>
        </div>
        {secondEntity === '' || flowTo === undefined ? <div className="space"></div> : <>
          <div className="arrow">
            {flowTo ? <p>Flow to</p> : <p>not Flow To</p>}
            {flowTo ? <></> : <CloseIcon className='x-mark' />}
            <ArrowForwardIosIcon className='arrow-right' />
          </div>
        </>
        }

        <div className="first-box-information width-adjust">
          <div className="header">{secondEntity === '' ? <>Actual Entity</> : <>{secondEntity.name}</>} </div>
          <div onClick={() => SetPickEntity(2)} className="third-entity"><EntityWidgetForAllType entity={secondEntity} /></div>
        </div>
      </div>

      {secondEntity === '' ? <></> :
        <div id="questions-flow">
          <div className="title">Verification Questions</div>
          {projectSelected.project_type === projectType.sentenceType ?
            <>
              <div className="question">
                <div className='question-title'>Determine if all words in sentences point to actual entities?</div>
                <p className="yes"><input type="radio" checked={pointTo} onChange={(e) => { if (pointTo !== null) { SetPointTo(true) } }} /> <span className='color'>Yes, </span> all words in the sentence <span className='color'>point to</span>actual entities</p>
                <p className="yes no-answer"><input type="radio" checked={pointTo === false} onChange={(e) => { if (pointTo !== null) { SetPointTo(false) } }} /> <span className='red'>No,</span> all words in the sentence <span className='red'>not point to</span>actual entities</p>
              </div>
              <div className="question">
                <div className='question-title'>Determine if all words in sentences naturally identified entities?</div>
                <p className="yes"><input type="radio" checked={naturallyIdentified} onChange={(e) => { if (naturallyIdentified !== null) { SetNaturallyIdentified(true) } }} /> <span className='color'>Yes, </span> all words in the sentence <span className='color'>naturally identified</span>actual entities</p>
                <p className="yes no-answer"><input type="radio" checked={naturallyIdentified === false} onChange={(e) => { if (naturallyIdentified !== null) { SetNaturallyIdentified(false) } }} /> <span className='red'>No,</span> all words in the sentence <span className='red'>not naturally identified</span>actual entities</p>
              </div>
              <div className="question">
                <div className='question-title'>Determine if all words in sentences defined by actual entities?</div>
                <p className="yes"><input type="radio" checked={definedBy} onChange={(e) => { if (definedBy !== null) { SetDefinedby(true) } }} /> <span className='color'>Yes, </span> all words in the sentence <span className='color'>defined by</span>actual entities</p>
                <p className="yes no-answer"><input type="radio" checked={definedBy === false} onChange={(e) => { if (definedBy !== null) { SetDefinedby(false) } }} /> <span className='red'>No,</span> all words in the sentence <span className='red'>not defined by</span>actual entities</p>
              </div>
              <div className="question">
                <div className='question-title'>Determine if all words in sentences are related to each other?</div>
                <p className="yes"><input type="radio" checked={areRelatedTo} onChange={(e) => { if (areRelatedTo !== null) { SetAreRelatedTo(true) } }} /> <span className='color'>Yes, </span> all words in the sentence <span className='color'>are related to</span>each other</p>
                <p className="yes no-answer"><input type="radio" checked={areRelatedTo === false} onChange={(e) => { if (areRelatedTo !== null) { SetAreRelatedTo(false) } }} /> <span className='red'>No,</span> all words in the sentence <span className='red'>are not related to</span>each other</p>
              </div>
            </> :
            <>
              <div className="question">
                <div className='question-title'>Determine if sentences in the paragraph point to actual entities?</div>
                <p className="yes"><input type="radio" checked={pointTo} onChange={(e) => { if (pointTo !== null) { SetPointTo(true) } }} /> <span className='color'>Yes, </span> all sentences in the paragraph <span className='color'>point to</span>actual entities</p>
                <p className="yes no-answer"><input type="radio" checked={pointTo === false} onChange={(e) => { if (pointTo !== null) { SetPointTo(false) } }} /> <span className='red'>No,</span> all sentences in the paragraph <span className='red'>not point to</span>actual entities</p>
              </div>
              <div className="question">
                <div className='question-title'>Determine if sentences in the paragraph naturally identified entities?</div>
                <p className="yes"><input type="radio" checked={naturallyIdentified} onChange={(e) => { if (naturallyIdentified !== null) { SetNaturallyIdentified(true) } }} /> <span className='color'>Yes, </span> all sentences in the paragraph <span className='color'>naturally identified</span>actual entities</p>
                <p className="yes no-answer"><input type="radio" checked={naturallyIdentified === false} onChange={(e) => { if (naturallyIdentified !== null) { SetNaturallyIdentified(false) } }} /> <span className='red'>No,</span> all sentences in the paragraph <span className='red'>not naturally identified</span>actual entities</p>
              </div>
              <div className="question">
                <div className='question-title'>Determine if all words in the paragraph defined by entities?</div>
                <p className="yes"><input type="radio" checked={definedBy} onChange={(e) => { if (definedBy !== null) { SetDefinedby(true) } }} /> <span className='color'>Yes, </span> all sentences in the paragraph <span className='color'>defined by</span>actual entities</p>
                <p className="yes no-answer"><input type="radio" checked={definedBy === false} onChange={(e) => { if (definedBy !== null) { SetDefinedby(false) } }} /> <span className='red'>No,</span> all sentences in the paragraph <span className='red'>not defined by</span>actual entities</p>
              </div>
              <div className="question">
                <div className='question-title'>Determine if all sentences in the paragraph are related to each other?</div>
                <p className="yes"><input type="radio" checked={areRelatedTo} onChange={(e) => { if (areRelatedTo !== null) { SetAreRelatedTo(true) } }} /> <span className='color'>Yes, </span> all sentences in the paragraph <span className='color'>are related to</span>each other</p>
                <p className="yes no-answer"><input type="radio" checked={areRelatedTo === false} onChange={(e) => { if (areRelatedTo !== null) { SetAreRelatedTo(false) } }} /> <span className='red'>No,</span> all sentences in the paragraph <span className='red'>are not related to</span>each other</p>
              </div>
            </>
          }
          {
            pointTo !== undefined && naturallyIdentified !== undefined && definedBy !== undefined && areRelatedTo !== undefined ? <>
              <div className="line-last-question"></div>
              <div className="question no-margin">
                <p className="yes"><input type="radio" disabled defaultChecked checked={pointTo === true && naturallyIdentified === true && definedBy === true && areRelatedTo === true} onChange={() => { }} /> <span className='color'>Yes, </span> {getSentenceString(projectSelected.sentence)} <span className='color'>flow to</span>{secondEntity.name}</p>
                <p className="yes last-question-no"><input type="radio" disabled defaultChecked checked={pointTo === false || naturallyIdentified === false || definedBy === false || areRelatedTo === false} onChange={() => { }} /> <span className='red'>No,</span> {getSentenceString(projectSelected.sentence)} <span className='red'>Not flow to</span>{secondEntity.name}</p>
              </div>
            </> : <>
            </>

          }
        </div>
      }
      {(pointTo === false || naturallyIdentified === false || definedBy === false || areRelatedTo === false) && flowTo === true ?
        <div className="answer-title">
          You answer information from {firstEntitiy.name} <span className='color'>flow to</span>  {secondEntity.name}, but you verify that information flow {firstEntitiy.name} <span className='red'>not flow to </span>  {secondEntity.name}
        </div> : <></>
      }
      {pickEntity === 'none' ? <></> : <AlertWithEntity type={'Entity'} EntitysSelected={[]} closeAlert={SetPickEntity} setData={SetFirstEntityHundler} typeProject={projectSelected.type} filter={pickEntity === 3 ? firstBoxType : null} />}
      <div onClick={() => onSaveButtonPressed()} className="save-button">Save Information Flow</div>
      {showQuestion === true
        ?
        <div onClick={() => SetShowQuestion(false)} id="delete-item">
          <div className="alert-mesg">
            <h1>Does the {projectSelected.project_type === projectType.sentenceType ? 'Sentence' : 'Paragraph'} flow from  {firstEntitiy.name} to {secondEntity.name} </h1>
            <div className="buttons">
              <div onClick={() => onThirdEntitySelected(false)} className="bt close">NO</div>
              <div onClick={() => onThirdEntitySelected(true)} className="bt done">YES</div>
            </div>
          </div>
        </div>
        :
        <></>
      }
      {changeType === true ?
        <div onClick={() => SetChangeType(false)} className="change-type-main">
          <div className="data-holder">
            <div onClick={() => changeTypeOfFirstEntity('audio')} className="button">Change to audio</div>
            <div onClick={() => changeTypeOfFirstEntity('video')} className="button">Change to video</div>
            <div onClick={() => changeTypeOfFirstEntity('Word')} className="button">Change to {projectSelected.project_type === projectType.sentenceType ? 'Sentence' : 'Paragraph'}</div>
          </div>
        </div> :
        <></>
      }
    </>

  )
}





function checkIfMap(obj) {
  return typeof obj === 'object'
}