import React from 'react'
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { closeFullAlertMSG } from '../../functions/show_full_alert';
import './show_full_alert.css';
export default function ShowFullAlertWidget() {
    return (
        <div id='show-full-alert-widget'>
            <div className="box-content">
                <div id='text-for-full-alert-widget'></div>
                <CancelSharpIcon onClick={() => closeFullAlertMSG()} className='icon' />
            </div>
        </div>
    )
}
