import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";

const getRelationship = async (id) => {
    const token = cookiesGlobal.get('auth')
    try {
        let response = await fetch(`${HttpUrls.getRelationships}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const setPrincipleAPI = async (id, principle, explanation) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.setPrinciple}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'principle': principle,
                'explanation': explanation
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
const CreateRelationShipsAPI = async (id, type, first, second, third, third_type, answer, explanation) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.setRelationShip}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'relationShipType': type,
                'first': first,
                'second': second,
                'third': third,
                'third_type': third_type,
                'answer': answer,
                'explanation': explanation,
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }
    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}
export { getRelationship, setPrincipleAPI, CreateRelationShipsAPI };