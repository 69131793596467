import React from 'react'
import './information_flow_box.css'
import DeleteIcon from '@mui/icons-material/Delete';
import { projectType } from '../../../enum/enum';
function truncateString(str, length) {
    if (str.length > length) {
        return str.substring(0, length) + '...';
    } else {
        return str;
    }
}
export default function InformationFlowBoxElement({ flow, SetCreateFlow, SetPickedFlow, SetPickedDelete, type }) {
    return (
        <div className='flow-box-element'>
            <div className="flow-blox-header">
                <h1>{type === projectType.sentenceType ? 'Sentence' : 'Paragraph'}</h1>
                <DeleteIcon onClick={() => { SetPickedDelete(flow.id) }} className="delete" />
            </div>
            <div onClick={(e) => { if (e.target) SetCreateFlow(2); SetPickedFlow(flow.id) }} className="flow-content">{checkIfMap(flow.sentence) ? flow.sentence.name : truncateString(flow.sentence, 140)} <span className={`${flow.flowTo ? 'active' : 'red'}`} >{flow.flowTo ? 'Flow to' : 'Not Flow to'}</span> {flow.entity2.name}</div>
        </div>
    )
}
function checkIfMap(obj) {
    return typeof obj === 'object'
}
