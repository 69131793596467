import React from 'react'
import { useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation, useNavigate } from 'react-router-dom'
import { CreateRelationShipsAPI } from '../../../api/relationship';
import { showAlert } from '../../../functions/show_alert';
import { HideProgressAlert, showProgressAlert } from '../../../functions/show_progress_page';
import AppBarWidget from '../../../widgets/app_bar/app_bar'
import './relationship_explanation.css'
import { cookiesGlobal } from '../../../App';
export default function RelationShipExplanation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [explanation, SetExplanation] = useState('');
    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];
    const SaveNewRelationShips = async () => {
        const data = location.state;
        if (explanation === '') {
            showAlert(false, 'Write an explanation of The relations ')
        }
        showProgressAlert();
        const response = await CreateRelationShipsAPI(data.id, data.type, data.firstContent, data.secondContent, data.thirdContent, data.thirdType, data.answer, explanation,);
        HideProgressAlert();
        if (response.statusCode === 200) {
            navigate('/relationship', { state: { 'type': data.type } })
            showAlert(true, `Relation created successfully `);
        } else if (response.statusCode === 401) {
            cookiesGlobal.remove('auth')
            cookiesGlobal.remove('email')
            navigate('/authentication', { replace: true });
            return;
        } else {
            showAlert(false, response.response);
        }

    }
    return (
        <>
            <AppBarWidget />
            <div id='relationship-explanation'>
                <h1 className='page-title'>Relationship Entity Explanation</h1>
                <ReactQuill formats={formats} onChange={(e) => SetExplanation(e)} value={explanation} id={'text-area-box'} className="sentence-text-place explanation-relation" theme="snow" />
                <div onClick={() => SaveNewRelationShips()} className="save-button-relation-explanation">
                    Save Relation
                </div>
            </div>
        </>

    )
}
