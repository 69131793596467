
const activeElement = {
    wordword: 'Word & Word',
    wordEntity: 'Word & Entity',
    entityEntity: 'Entity & Entity',
    wordPrinciple: 'Word & Principle',
    entityPrinciple: 'Entity & Principle',
    sentencePrinciple: 'Sentence & Principle',
}
const projectType = {
    sentenceType: 'Sentence flow',
    paragrapthType: 'Paragraph flow',
};


export { activeElement, projectType };