import React from 'react'
import './linear-title.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function LinearTitle({ SetCreateLinear, createLinear,SetEditLinear }) {
  return (
    <div className='main-flow-title'>
      {createLinear ?
        <h1 className='create-new'><ArrowBackIcon onClick={() => { SetCreateLinear(false); SetEditLinear(null) }} className='back' /> <p>Create New </p></h1>
        :
        <>
          <h1 className='flow-title-text'>Linear Relationship </h1>
          <h1 onClick={() => SetCreateLinear(true)} className='flow-create-new' >Create new</h1>
        </>}
    </div>
  )
} 
