import { cookiesGlobal } from "../App";
import { HttpUrls } from "../global/http_url";
const CreateEntityAPI = async (id, type, value, name) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.identifyEntity}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'POST',
            body: JSON.stringify({
                'type': type,
                'value': value,
                'name': name,
            })
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }

    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}

const DeleteEntity = async (id) => {
    const token = cookiesGlobal.get('auth');
    try {
        let response = await fetch(`${HttpUrls.deleteEntity}/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": token,
            },
            method: 'GET',
         
        });
        const responseData = await response.json();
        if (response.status === 200) {
            return {
                statusCode: response.status,
                response: responseData['data'],
            }
        } else {
            return {
                statusCode: response.status,
                response: responseData['message'],
            }
        }

    } catch (e) {
        return {
            statusCode: 505,
            response: 'Intertnet Connection',
        }
    }
}


export { CreateEntityAPI, DeleteEntity };