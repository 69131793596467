import React from 'react'
import './flow_title.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function FlowTitle({ SetCreateFlow, createFlow }) {
  return (
    <div className='main-flow-title'>
      {createFlow ?
        <h1 className='create-new'><ArrowBackIcon onClick={() => SetCreateFlow(0)}  className='back' /> <p>Create New </p></h1>
        :
        <>
          <h1 className='flow-title-text'>Flow </h1>
          <h1 onClick={() => SetCreateFlow(1)} className='flow-create-new' >Create new</h1>
        </>}

    </div>
  )
}
