import React from 'react'
import { getFirstTypeOfVerification, typeIsNotRelated, verificationToMapMoile } from '../../../enum/verification';
import './verification_box.css';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { projectType } from '../../../enum/enum';
import CloseIcon from '@mui/icons-material/Close';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EntityWidgetForAllType from '../../../widgets/entity_widget/entity_widget';
import { getSentenceString, settingsSlider } from '../../../global/utils';
import { showAlertWithTextGlobal } from '../../../functions/show_alert_with_text_global';
export default function VerificationBox({ verification, width, height, typeProject }) {
  return (
    <div style={{ width, height }} className='main-verification-box'>
      <div className="top-verification">
        <h1>{typeProject === projectType.sentenceType ? ' Word' : getFirstTypeOfVerification(verification.type)}</h1>
        <h1>{typeProject === projectType.sentenceType ? ' Entity' : 'Entities '}</h1>
      </div>
      <div className="box-content">
        <div className='time'></div>
        <div className="word-type-entity-holder">
          <div onClick={() => {showAlertWithTextGlobal(verification.word)}} className="word">{getSentenceString(verification.word)}</div>
          <div className="type">
            <h1>{verificationToMapMoile(verification.type, typeProject)}</h1>
            <div className='line-arrow'>
              <div className="line"></div>
              <ArrowForwardIosSharpIcon className="arrow" />
              {typeIsNotRelated(verification.type) === true ? <CloseIcon className='not-related' /> : <></>}
            </div>
          </div>
          {verification.entity.length !== 1 ? <>
            <div className={`${verification.entity.length !== 1 ? "entity" : "one-entity"}`}>
              <Slider  {...settingsSlider}>
                {verification.entity.map((e, index) =>
                  <EntityWidgetForAllType height={'197px'} width={'180px'} entity={e} />
                )}
              </Slider>
            </div>
          </> : <>
            <div className={"one-entity"}>
              <EntityWidgetForAllType height={'197px'} width={'180px'} entity={verification.entity[0]} />
            </div>
          </>}
        </div>
      </div>
    </div>
  )
}

