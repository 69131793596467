import React from 'react'
import './app_bar.css';
import Logo from '../../assets/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { cookiesGlobal } from '../../App';
import { useSelector } from 'react-redux';
import { projectType } from '../../enum/enum';
import HelpIcon from '@mui/icons-material/Help';
export default function AppBarWidget() {
    const projectSelected = useSelector(state => state.projectSlice.projectSelected);
    var sentence = '';
    if (projectSelected == null) {
        sentence = 'Sentence';
    } else {
        if (projectSelected.project_type === projectType.sentenceType) {
            sentence = 'Sentence';
        } else {
            sentence = 'Paragraph';
        }
    }
    const [drawer, SetDrawer] = useState(false)
    return (
        <div className='app_bar_holder'>
            <div id='desktop_appbar' className="app_bar_holder">
                <div className='main_app_bar'>
                    <div className="header_logo">
                        <img src={Logo} alt="_" />
                    </div>
                    <div className="header_links">
                        <Link className='inter-14' to="/" ><h1 className='link-header inter-14' >Project <span>create a project</span></h1></Link>
                        <Link className='inter-14' to="/sentence" ><h1 className='link-header inter-14' > {sentence} <span>add a sentence then identify words in sentence</span></h1></Link>
                        <Link className='inter-14' to="/identify-entity" ><h1 className='link-header inter-14' >Entity<span>identify entities</span> </h1></Link>
                        <Link className='inter-14' to="/verifications" ><h1 className='link-header inter-14' >Verification <span>create a verification</span></h1></Link>
                        <div className='inter-14 relationship' >
                            <Link to={'/relationship'} className='link-header inter-14'>Relationship<span>create a relationship</span></Link>
                            <div className="drop-down">
                                <Link style={{ color: '#FFF' }} className='inter-14' to={'/relationship'}>Relationship</Link>
                                <Link style={{ color: '#FFF' }} className='inter-14' to='/identify-principles'>Identify principles</Link>
                                <Link style={{ color: '#FFF' }} className='inter-14' to='/linear-relationship'>Linear Relationship</Link>
                            </div>
                        </div>
                        <div className='inter-14 relationship' >
                            <Link to={'/relationship'} className='link-header inter-14'>Flow<span>show flow of entities</span></Link>
                            <div className="drop-down">
                                <Link style={{ color: '#FFF' }} className='inter-14' to={'/flow'}>Flow</Link>
                                <Link style={{ color: '#FFF' }} className='inter-14' to='/information-flow'>Information Flow</Link>
                            </div>
                        </div>
                        <Link className='inter-14' to="/report" ><h1 className='link-header inter-14'>Report<span>create a report</span></h1></Link>
                        <Link className='inter-14' onClick={() => { cookiesGlobal.remove('auth'); }} to="/authentication" ><h1 className='link-header inter-14'>logout</h1></Link>
                        <HelpIcon className='help-icon' />
                    </div>
                </div>
                <div className="app_bar_divider"></div>
            </div>
            <div id='mobile_appbar' className="app_bar_holder">
                <div className='main_app_bar'>
                    <div className='menu-icons'>
                        <MenuIcon onClick={() => SetDrawer(true)} />
                    </div>
                    <div className="header_logo">
                        <img src={Logo} alt="_" />
                    </div>
                </div>
                <div className={`menu-mobile ${!drawer ? 'menu-mobile-hide' : ''}`}>
                    <div className="user-name-section">
                        <CloseIcon onClick={() => SetDrawer(false)} className='close-button' />
                        <PersonPinIcon className='person-icon' />
                        <h1 className='username-drawer'>{cookiesGlobal.get('email').split('@')[0].replace('.', ' ') ?? ' user'}</h1>
                    </div>
                    <div className="links-app-bar">
                        <Link className='inter-14' to="/" ><h1 className='link-header inter-14' >Project</h1></Link>
                        <Link className='inter-14' to="/sentence" ><h1 className='link-header inter-14' >{sentence}</h1></Link>
                        <Link className='inter-14' to="/identify-entity" ><h1 className='link-header inter-14' >Entity</h1></Link>
                        <Link className='inter-14' to="/verifications" ><h1 className='link-header inter-14' >Verification</h1></Link>
                        <Link className='inter-14' to="/relationship" ><h1 className='link-header inter-14' >Relationship </h1></Link>
                        <Link className='inter-14' to="/linear-relationship" ><h1 className='link-header inter-14' >Linear Relationship</h1></Link>
                        <Link className='inter-14' to="/identify-principles" ><h1 className='link-header inter-14' >Identify principles </h1></Link>
                        <Link className='inter-14' to="/flow" ><h1 className='link-header inter-14'>Flow</h1></Link>
                        <Link className='inter-14' to="/information-flow" ><h1 className='link-header inter-14'>Information Flow</h1></Link>
                        <Link className='inter-14' to="/report" ><h1 className='link-header inter-14'>Report</h1></Link>
                        <Link onClick={() => { cookiesGlobal.remove('auth'); cookiesGlobal.remove('email') }} className='inter-14' to="/authentication" ><h1 className='link-header inter-14'>logout</h1></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
