import React from 'react'
import { useDispatch } from 'react-redux';
import { changeGridView } from '../../redux/project/projects';
import './switch_button.css';
export default function SwitchButton({ value }) {
    const dispatch = useDispatch();
    return (
        <div className='swicth-main-holder'>
            <label className="switch">
                <input onChange={(val) => {
                    dispatch(changeGridView())


                }} type="checkbox" value={value} />
                <span className="slider round"></span>
            </label>
        </div>
    )
}
