import React from 'react';
import './relationship_header.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
export default function RelationshipHeader({ title, CreateButton, SetShowExplanation }) {
    return (
        <div className='relationship-header'>
            <div className="title-holder">
                <div className="title-icon">
                    <h1 className="title">{title}</h1>
                    <AddCircleIcon onClick={() => { CreateButton(true); SetShowExplanation(-1) }} className='icon' />
                    <h1 onClick={() => { CreateButton(true); SetShowExplanation(-1) }} className='create-new'> Create new</h1>
                </div>
            </div>
        </div>
    )
}
