import React from 'react'
import { projectType } from '../../../enum/enum'
import { getRelaionsCountOfType, relationshipType, SentenceRelationShips } from '../../../enum/relationship'
import './button_relation.css'
export default function RelationButtons({ type, SetType, createRelationship, principle, typeProject, relationShip, SetCreateRelationship }) {
    if (typeProject === projectType.sentenceType) {
        return (
            <div id='create-relation-buttons'>
                <div className="flex-buttons-web">
                    <div onClick={() => { SetCreateRelationship(false); SetType(relationshipType.wordWord); }} className={`button ${type === relationshipType.wordWord ? 'active' : ''}`}>Word & Word ({getRelaionsCountOfType(relationshipType.wordWord, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(relationshipType.wordEntity); }} className={`button ${type === relationshipType.wordEntity ? 'active' : ''}`}>Word & Entity ({getRelaionsCountOfType(relationshipType.wordEntity, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(relationshipType.entityEntity); }} className={`button ${type === relationshipType.entityEntity ? 'active' : ''}`}>Entity & Entity ({getRelaionsCountOfType(relationshipType.entityEntity, relationShip)})</div>
                    {principle.length === 0 ? <></> :
                        <>
                            <div onClick={() => { SetCreateRelationship(false); SetType(relationshipType.principleWord); }} className={`button ${type === relationshipType.principleWord ? 'active' : ''}`}>Word & Principle ({getRelaionsCountOfType(relationshipType.principleWord, relationShip)})</div>
                            <div onClick={() => { SetCreateRelationship(false); SetType(relationshipType.principleEntiy); }} className={`button ${type === relationshipType.principleEntiy ? 'active' : ''}`}>Entity & Principle  ({getRelaionsCountOfType(relationshipType.principleEntiy, relationShip)})</div>
                            <div onClick={() => { SetCreateRelationship(false); SetType(relationshipType.sentencePrinciple); }} className={`button ${type === relationshipType.sentencePrinciple ? 'active' : ''}`}>Sentence & Principle ({getRelaionsCountOfType(relationshipType.sentencePrinciple, relationShip)})</div>
                        </>
                    }
                </div>
                {createRelationship ? <div className="flex-buttons-mobile">
                    <select defaultValue={type} onChange={(e) => SetType(e.target.value)} name="types" id="">
                        <option value={relationshipType.wordWord}>Word & Word</option>
                        <option value={relationshipType.wordEntity}>Word & Entity</option>
                        <option value={relationshipType.entityEntity}>Entity & Entity</option>
                        {principle.length === 0 ? <></> :
                            <>
                                <option value={relationshipType.principleWord}>Word & Principle</option>
                                <option value={relationshipType.principleEntiy}>Entity & Principle</option>
                                <option value={relationshipType.sentencePrinciple}>Sentence & Principle</option>
                            </>
                        }

                    </select>
                </div> : <></>}
            </div>
        )
    } else {
        return (
            <div id='create-relation-buttons'>
                <div className="flex-buttons-web">
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.SentenceSentence); }} className={`button ${type === SentenceRelationShips.SentenceSentence ? 'active' : ''}`}>Sentence & Sentence ({getRelaionsCountOfType(SentenceRelationShips.SentenceSentence, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.SentenceParagraph); }} className={`button ${type === SentenceRelationShips.SentenceParagraph ? 'active' : ''}`}>Sentence & Paragraph ({getRelaionsCountOfType(SentenceRelationShips.SentenceParagraph, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.SentenceEntities); }} className={`button ${type === SentenceRelationShips.SentenceEntities ? 'active' : ''}`}>Sentence & Entities ({getRelaionsCountOfType(SentenceRelationShips.SentenceEntities, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.ParagraphEntities); }} className={`button ${type === SentenceRelationShips.ParagraphEntities ? 'active' : ''}`}>Paragraph & Entities ({getRelaionsCountOfType(SentenceRelationShips.ParagraphEntities, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.EntitiesEntities); }} className={`button ${type === SentenceRelationShips.EntitiesEntities ? 'active' : ''}`}>Entities & Entities ({getRelaionsCountOfType(SentenceRelationShips.EntitiesEntities, relationShip)})</div>
                    {principle.length === 0 ? <></> :
                        <>
                            <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.SentencePrinciple); }} className={`button ${type === SentenceRelationShips.SentencePrinciple ? 'active' : ''}`}>Sentence & Principle ({getRelaionsCountOfType(SentenceRelationShips.SentencePrinciple, relationShip)})</div>
                            <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.ParagraphPrincipl); }} className={`button ${type === SentenceRelationShips.ParagraphPrincipl ? 'active' : ''}`}>Paragraph & Principle ({getRelaionsCountOfType(SentenceRelationShips.ParagraphPrincipl, relationShip)})</div>
                            <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.EntitiesPrinciple); }} className={`button ${type === SentenceRelationShips.EntitiesPrinciple ? 'active' : ''}`}>Entities & Principle ({getRelaionsCountOfType(SentenceRelationShips.EntitiesPrinciple, relationShip)})</div>
                        </>
                    }
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.sentenceWord); }} className={`button ${type === SentenceRelationShips.sentenceWord ? 'active' : ''}`}>Sentence & Word ({getRelaionsCountOfType(SentenceRelationShips.sentenceWord, relationShip)})</div>
                    <div onClick={() => { SetCreateRelationship(false); SetType(SentenceRelationShips.wordEntites); }} className={`button ${type === SentenceRelationShips.wordEntites ? 'active' : ''}`}>Word & Entities ({getRelaionsCountOfType(SentenceRelationShips.wordEntites, relationShip)})</div>
                </div>
                {createRelationship ? <div className="flex-buttons-mobile">
                    <select defaultValue={type} onChange={(e) => SetType(e.target.value)} name="types" id="">
                        <option value={SentenceRelationShips.SentenceSentence} >Sentence & Sentence</option>
                        <option value={SentenceRelationShips.SentenceParagraph} >Sentence & Paragraph</option>
                        <option value={SentenceRelationShips.SentenceEntities} >Sentence & Entities</option>
                        <option value={SentenceRelationShips.ParagraphEntities} >Paragraph & Entities</option>
                        <option value={SentenceRelationShips.EntitiesEntities} >Entities & Entities</option>
                        {principle.length === 0 ? <></> :
                            <>
                                <option value={SentenceRelationShips.SentencePrinciple} >Sentence & Principle</option>
                                <option value={SentenceRelationShips.ParagraphPrincipl} >Paragraph & Principle</option>
                                <option value={SentenceRelationShips.EntitiesPrinciple} >Entities & Principle</option>
                            </>
                        }
                        <option value={SentenceRelationShips.sentenceWord} >Sentence & Word</option>
                        <option value={SentenceRelationShips.wordEntites} >Word & Entities</option>
                    </select>
                </div> : <></>}
            </div>
        )
    }
}
