import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLinearRelationShip } from '../../api/ligner-relationship';
import { requestState } from '../../global/http_enum';
import AppBarWidget from '../../widgets/app_bar/app_bar'
import ResponseHttp from '../../widgets/response_http/response_http';
import CreateLinear from './create-linear/create-linear';
import LinearTitle from './flow_tittle/linear-title';
import LinearViewWidget from './linear-view/linear-view';
import './linear-relationship.css';
import { getRelationship } from '../../api/relationship';
export default function LinearRelationshipScreen() {
    const selectedProject = useSelector(state => state.projectSlice.projectSelected);
    const [createLinear, SetCreateLinear] = useState(false)
    const [response, SetResponse] = useState(requestState.initial);
    const [linearData, SetLinearData] = useState();
    const [prnciples, SetPrinciples] = useState();
    const [editLinear, SetEditLinear] = useState(null);
    useEffect(() => {
        if (selectedProject.word.length < 1) {
            return;
        } else {
            getLinearRelationShip(selectedProject.id).then(async (value) => {
                const response = await getRelationship(selectedProject.id)
                if (value.statusCode === 200 && response.statusCode === 200) {
                    SetPrinciples(response.response)
                    SetLinearData(value.response);
                    SetResponse(requestState.haveData);
                } else if (value.statusCode === 401 && response.statusCode === 401) {
                    SetResponse(requestState.invalidTokne);
                } else if (value.statusCode === 505 && response.statusCode === 505) {
                    SetResponse(requestState.internetConnection);
                } else {
                    SetResponse(requestState.serverError);
                }
            });
        }

    }, [selectedProject])
    if (selectedProject.word.length < 2) {
        return <><AppBarWidget />
            <div className='error-text-linear'>
                A linear relationship is simply established between 2 or more words in a sentence.  Since the number of words identified is simply 0, there is no linear relationship of identified words.  Please, identify 2 or more words to form a linear relationship.
            </div>
        </>
    }
    if (response === requestState.haveData) {
        return (
            <>
                <AppBarWidget />
                <div id="main-linear">
                    <LinearTitle SetEditLinear={SetEditLinear} SetCreateLinear={SetCreateLinear} createLinear={createLinear} />
                    {createLinear || editLinear != null ? <CreateLinear principle={prnciples.principle} SetEditLinear={SetEditLinear} pickedLinear={editLinear} type={selectedProject.project_type} projectId={selectedProject.id} SetLinearData={SetLinearData} linearData={linearData} SetCreateLinear={SetCreateLinear} /> :
                        <LinearViewWidget SetEditLinear={SetEditLinear} SetLinearData={SetLinearData} type={selectedProject.type} linearData={linearData} SetCreateLinear={SetCreateLinear} />}
                </div>
            </>
        )
    } else {
        return <>
            <AppBarWidget />
            <ResponseHttp response={response} />
        </>
    }

}
