import './css/main_css.css'
import ProjectScreen from "./screens/project_screen/project_screen";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SentenceScreen from './screens/sentence/sentence';
import AuthGuarder from './auth_garder/auth_guard';
import AlertWidget from './widgets/alert/alert';
import PageCircleProgress from './widgets/page_circle_progress/page_circle_progress';
import ShowFullAlertWidget from './widgets/show_full_alert/show_full_alert';
import LogicGuard from './auth_garder/logic_guard';
import VerificationsScreen from './screens/verification/verifications_screen';
import IdentifyEntity from './screens/identify_entity/identify_entity';
import FlowScreen from './screens/flow/flow';
import RelationShipScreen from './screens/relationship/relationship_screen';
import ReportScreen from './screens/reports/report';
import AlertWithText from './widgets/alert_with_text/alert_with_text';
import { Cookies } from 'react-cookie';
import NoPage from './screens/no_page/no_page';
import IdenrtifyPrinciple from './screens/identify_principle/identify_principle';
import AuthenticationPage from './screens/auth/authentication';
import RelationShipExplanation from './screens/relationship/relationship_explanation/relationship_explanation';
import AlertWithTextGlobal from './widgets/alert_with_text_global/alert_with_text';
import LinearRelationshipScreen from './screens/linear-relationship/linear-relationship';
import ImformationFlow from './screens/information_flow/information_flow';
const cookiesGlobal = new Cookies();

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/authentication" element={<AuthenticationPage />}></Route>
          <Route element={<AuthGuarder />}>
            <Route path="/" element={<ProjectScreen />}></Route>
            <Route element={<LogicGuard />}>
              <Route path="/sentence" element={<SentenceScreen />}></Route>
              <Route path="/verifications" element={<VerificationsScreen />}></Route>
              <Route path="/identify-entity" element={<IdentifyEntity />}></Route>
              <Route path="/flow" element={<FlowScreen />}></Route>
              <Route path="/relationship" element={<RelationShipScreen />}></Route>
              <Route path="/identify-principles" element={<IdenrtifyPrinciple />}></Route>
              <Route path="/report" element={<ReportScreen />}></Route>
              <Route path="/relationship-explanation" element={<RelationShipExplanation />} />
              <Route path="/linear-relationship" element={<LinearRelationshipScreen />} />
              <Route path="/information-flow" element={<ImformationFlow />} />
            </Route>
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
        <AlertWidget />
        <PageCircleProgress />
        <ShowFullAlertWidget />
        <AlertWithTextGlobal />
        <AlertWithText />
      </BrowserRouter>
    </div>
  );
}
export default App;
export { cookiesGlobal };
