import React from 'react'
import './principle_alert.css'
export default function PrincipleAlertWidget({ onClickPrinciple, }) {
    const pricniple = [
        'The Given Set of Communication',
        'The Given Set of Information',
        'The Given Set of Instrumentation',
        'The Given Set of Marketing',
        'The Given Set of Exchange',
        'The Given Set of Gaming',
        'The Given Set of Work',
    ]
    return (
        <div id='principle-alert'>
            <div className='backgroud-layer'>
                <div className="box-principle">
                    {pricniple.map((e) => <div onClick={() => onClickPrinciple(e)} className="principle-element">{e}</div>)}
                </div>
            </div>
        </div>
    )
}
