import React from 'react'
import './flow_title.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function InfromationFlowTitle({ SetCreateFlow, createFlow }) {
  return (
    <div className='main-flow-title'>
      {createFlow ?
        <h1 className='create-new'><ArrowBackIcon onClick={() => SetCreateFlow(false)} className='back' /> <p>Back</p></h1>
        : <h1 className='flow-title-text'>Information Flow</h1>
      }
    </div>
  )
}
